.mat-horizontal-stepper-header-container{
    width: 40%;
}
.mat-step-header .mat-step-icon-selected, .mat-step-header .mat-step-icon-state-done, .mat-step-header .mat-step-icon-state-edit {
    background-color: #173a64;
    color: #fff;
}
.inputGroupnew {
    background-color: #e8e7e7;
    display: block;
    margin: 10px 0;
    position: relative;

    label {
      padding: 12px 12px;
      width: 100%;
      display: block;
      text-align: left;
      color: #3C454C;
      cursor: pointer;
      position: relative;
      z-index: 2;
      transition: color 200ms ease-in;
      overflow: hidden;

      &:before {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        content: '';
        background-color: $primary-clr;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%) scale3d(1, 1, 1);
        transition: all 300ms cubic-bezier(0.4, 0.0, 0.2, 1);
        opacity: 0;
        z-index: -1;
      }

      &:after {
        width: 32px;
        height: 32px;
        content: '';
        border: 2px solid #D1D7DC;
        background-color: #fff;
        background-image: url("data:image/svg+xml,%3Csvg width='32' height='32' viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.414 11L4 12.414l5.414 5.414L20.828 6.414 19.414 5l-10 10z' fill='%23fff' fill-rule='nonzero'/%3E%3C/svg%3E ");
        background-repeat: no-repeat;
        background-position: 2px 3px;
        border-radius: 50%;
        z-index: 2;
        position: absolute;
        right: 7px;
        top: 50%;
        transform: translateY(-50%);
        cursor: pointer;
        transition: all 200ms ease-in;
      }
    }

    input:checked ~ label {
      color: #fff;

      &:before {
        transform: translate(-50%, -50%) scale3d(56, 56, 1);
        opacity: 1;
      }

      &:after {
        background-color: #54E0C7;
        border-color: #54E0C7;
      }
    }

    input {
      width: 32px;
      height: 32px;
      order: 1;
      z-index: 2;
      position: absolute;
      right: 7px;
      top: 50%;
      transform: translateY(-50%);
      cursor: pointer;
      visibility: hidden;
    }
  }
  .question-editor .ql-container {
      height:60px;
  }
  .form-configuration .mat-horizontal-stepper-header {
    height: 52px !important;
}
.custom-radio-text{
    display: inline-block;
    border-radius: 5px;
    z-index: 5;
    margin-right: 10px;
    margin-bottom: 10px;
    position: relative;
  }
  .tb {
    border-bottom: 2px solid #ccc;
    background: #fff;
    opacity: 1;
    z-index: 10;
    outline: none;
    border-top: none;
    border-left: none;
    border-right: none;
}
.rd {
  margin-right: 5px;
}
.more-choice-btn {
    padding-right: 22px;
    text-decoration: underline;
    font-size: 13px;
    display: inline-block;
    margin-bottom: 8px;
    font-weight: 500;
}
.label-others {
    font-size: 14px;
    width: 100%;
    border-radius: 5px;
    border: 2px solid #c7c7c7;
    padding: 2px;
}
.configure-right-section.active {
  background: #fff;
  border-bottom: none;
  // border-left: 4px solid #1b87e6;
  // box-shadow: 0 -1px 1px 0 rgb(0 0 0 / 20%), 0 3px 5px 0 rgb(0 0 0 / 30%);
  z-index: 2;
  padding: 15px;
}
.configure-right-section:hover {
  background: #fff;
  border-bottom: none;
  // border-left: 4px solid #1b87e6;
  // box-shadow: 0 -1px 1px 0 rgb(0 0 0 / 20%), 0 3px 5px 0 rgb(0 0 0 / 30%);
  z-index: 2;
  padding: 15px;
}
.configure-right-section.completed:hover {
  background: #fff;
  border-bottom: none;
  // border-left: 4px solid #19a938;
  // box-shadow: 0 -1px 1px 0 rgb(0 0 0 / 20%), 0 3px 5px 0 rgb(0 0 0 / 30%);
  z-index: 2;
  padding: 15px;
}
.configure-right-section{
  padding: 15px;
}
.add-btn-plus {
  color: #767676;
  font-size: 20px;
  padding-left: 10px;
}
.configure-right-section {
  margin-bottom: 17px;
}
.question-text {
  border-top-left-radius: 4px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 4px;
}
.quest-selct {
  border-top-left-radius: 0;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 0;
}
.question-number {
  font-weight: bold;
  padding-right: 15px;
  color: #173a64;
}
.configure-right-section input[type=radio] {
  width: 19px;
  height: 19px;
  vertical-align: middle;
}
.configure-right-section input[type=checkbox] {
  width: 19px;
  height: 19px;
  vertical-align: middle;
}
.label-radio {
  vertical-align: middle;
  padding-left: 2px;
  font-weight: 500;
  color: #555;
  margin-top: 3px;
}
.tb:focus {
  border-bottom: 2px solid $primary-clr;
}
.key label {
  font-size: 15px;
  color: $primary-clr;
  font-weight: 500;
  padding-right: 10px;
}
.key span {
  font-size: 15px;
  font-weight: 500;
  color: #1e8926;
}
.question-label {
  font-weight: 500;
}
.key {
  width: auto;
}
.additional {
  color: $primary-clr;
  font-size: 15px;
  font-weight: 500;
}
.form-configuration .form-check-label {
  font-size: 15px;
  font-weight: 500;
  color:#555;
}
.btn.btn-Success {
  padding: 4px 12px;
  font-size: 13px;
  line-height: 1.6;
  background: #089649;
  color: #fff;
  font-weight: 500;
    border-radius: 2px;
}
.btn.btn-duplicate {
  padding: 4px 12px;
  font-size: 13px;
  line-height: 1.6;
  background: #6B787F;
  color: #fff;
  font-weight: 500;
    border-radius: 2px;
}
.btn.btn-deleted {
  padding: 4px 12px;
  font-size: 13px;
  line-height: 1.6;
  background: #c52525;
  color: #fff;
  font-weight: 500;
    border-radius: 2px;
}
.btn-cntrl {
  position: absolute;
  right: 0;
  top: -7px;
}
.btn-cntrl {
display: none;
}
.configure-right-section:hover .btn-cntrl {
  display: block;
  }
  .question-card .dropdown-menu .dropdown-item {
    padding: 0.3rem 1rem;
    font-size: 0.775rem;
}
.question {
  font-size: 14px;
  font-weight: 500;
}
.question span {
  color: $primary-clr;
  font-weight: 700;
}
.rating .checked{
    color: orange;
}

.comment-section .comment-txt {
  font-size: 13px;
  font-weight: 500;
  color: #b2b2b2;
  margin: 0;
  font-style: italic;
}
.comment-txt {
  font-size: 13px;
  font-weight: 400;
  border: 2px solid #b2b2b2;
  padding: 5px;
  border-radius: 5px;
  min-height: 50px;
}
.question-card .card {
  height: 100%;
}
// .yesNO-option span {
//   margin-right: 7px;
//   font-size: 15px;
//   font-weight: 500;
//   border: 1px solid;
//   padding: 0px 10px;
//   margin-top: 10px;
//   display: inline-block;
//   border-radius: 2px;
//   margin-bottom: 10px;
// }
// .yesNO-option {
//   padding-left: 25px;
//   text-align: right;
// }
.answer-green {
  color: #19a938 !important;
}
.question-type {
  font-size: 13px;
  font-weight: 600;
}
.question-type label {
  padding-right: 7px;
  color: #585858;
}
.question-type span {
  color: #5695f4;
}
.explain-type {
  font-size: 13px;
  font-weight: 600;
}
.explain-type label {
  padding-right: 7px;
  color: #585858;
}
.explain-type span {
  color: #5695f4;
}
.Multiple-option {
  font-size: 15px;
}
.Multiple-option span {
  margin-right: 12px;
  font-weight: 500;
  margin-top: 5px;
  display: inline-block;
}
.rating {
  margin-top: 5%;
  border: 1px #999 solid;
  display: flex;
  font-size:0;
  justify-content: center;
  align-items: center;
  flex-direction: row-reverse;
  // background: linear-gradient(to right, #f00 , #ff0, #0f0);
  width: 50%;
    margin: 0 auto;
}
.rating input {
  display: none
}
.rating label {
  display: flex;
  width: 100px;
  height: 30px;
  font-size: 14px;
  justify-content: center;
  align-items: center;
  background: #fff;
  color: #000;
cursor: pointer;
pointer-events: none;
margin-bottom: 0;
}
.rating label {
  border-left: 1px #999 solid;
  transition: .3s;
}
.rating input[type='radio']:hover~label,
input[type='radio']:checked~label{
  background: none;
  color: #fff;
}
.multiplechoice-single .form-check-inline{
    width: 40%;
}
.description-box .comment-txt{
  height:100px;
}
.question-card {
  background: #fcfcfc;
}
.question-list {
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 10px;
  font-weight: 600;
}
#btn-back-to-top {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #173a64;
  border-color: #173a64;
  box-shadow: none;
  display: block;
  font-size: 13px;
  padding: 6px 9px;
  background: #173a64;
  color: #fff;
}
.delete-node {
  position: absolute;
  right: 20px;
  color: #767676;
  display:none;
  top:0;
}
.empty-upload {
  color: #767684;
  position: relative;
  top: 4px;
  left: 4px;
}
.custom-radio-text:hover .delete-node {
  display:block;
}
.q-type span {
  color: #173a64;
  font-weight: 600;
  font-size: 13px;
}
.q-type label {
  padding-right: 7px;
  color: #4f4f4f;
  padding-left: 12px;
  padding-top: 5px;
}
.q-type {
  font-size: 12px;
  font-weight: 500;
}
.form-details-head {
  color: #5f7581;
  font-weight: 500;
  border-bottom: 2px solid #eee;
  padding-bottom: 5px;
  margin-bottom: 5px;
}
.form-detail-label {
  font-size: 16px;
  font-weight: 400;
  padding-right: 10px;
  color: #535353;
}
.form-detail-Value {
  font-size: 16px;
  font-weight: 600;
  color: #173a64;
  width: 333px;
}
.descipr-intake {
  text-align: center;
  width: 100%;
  font-weight: 500;
  color: #747474;
  font-size: 14px;
}
.intake-value-dis {
  display: inline-block;
  margin-left: 5px;
}
.intake-value-dis span {
  color:#343333;
}
.descipr-intake span {
  font-weight: 600;
  color: #343333;
}
.form-detail-label-created {
  font-size: 13px;
  font-weight: 400;
  padding-right: 10px;
  color: #535353;
}
.form-detail-Value-created {
  font-size: 13px;
  font-weight: 600;
  color: #173a64;
}
.listing-questions li {
  margin-bottom: 10px;
}
.listing-questions ul {
  list-style-type: none;
  padding: 0;
}
.formed-type label {
  width: 100%;
  font-size: 11px;
  color: #878686;
  font-weight: 500;
  margin: 0;
  position: relative;
    top: -8px;
}
.listing-questions .val-list {
  font-weight: normal;
  font-size: 12px;
  color: #676767;
}
.formed-type span {
  font-weight: 500;
  color: #000;
}
.formed-type2 label {
  font-size: 12px;
    color: #173a64;
  font-weight: 500;
  margin: 0;
  text-align: left;
}
.formed-type2 span {
  font-weight: 500;
  color: #000;
  text-align: right;
  font-size: 14px;
}
.total-ques label{
  padding-right: 9px;
  padding-top: 2px;
  color: #000;
}
.total-ques span {
  background: #173a64;
  border-radius: 50%;
  height: 30px;
  color: #f6ca21;
  width: 30px;
  text-align: center;
  vertical-align: middle;
  padding-top: 5px;
  margin-top: -4px;
}
.set-quesion mat-label {
  color: #173a64;
  font-weight: 500;
}
.total-ques{
  // border: 1px solid #2a7d1b;
  float: right;
  margin-bottom: 10px;
  padding: 0px 6px;
}
.bulk-add .modal-dialog {
  max-width: 700px;
  }
  .set-quesion {
    margin-bottom: 10px;
}
.list-question {
  overflow-y: auto;
  max-height: 337px;
  overflow-x: hidden;
}
      .list-question .mat-input-element {
        padding-right: 28px;
    }
    .thin-scroll {
      scrollbar-width: thin;
      scrollbar-color: #3da5f4 #ffffff;
    }
    
    /* Works on Chrome, Edge, and Safari */
    .thin-scroll::-webkit-scrollbar {
      width: 12px;
    }
    
    .thin-scroll::-webkit-scrollbar-track {
      background: #ffffff;
    }
    
    .thin-scroll::-webkit-scrollbar-thumb {
      background-color: #3da5f4;
      border-radius: 20px;
      border: 3px solid #ffffff;
    }

    .thin-scroll-trans {
      scrollbar-width: thin;
      scrollbar-color: #3da5f4 #ffffff;
    }
    
    /* Works on Chrome, Edge, and Safari */
    .thin-scroll-trans::-webkit-scrollbar {
      width: 12px;
    }
    
    .thin-scroll-trans::-webkit-scrollbar-track {
      background: #f1f1f1;
    }
    
    .thin-scroll-trans::-webkit-scrollbar-thumb {
      background-color: #3da5f4;
      border-radius: 20px;
      border: 3px solid #ffffff;
    }
    .preview-questions-checklist{
      .switch {
        position: relative;
        display: table;
        width: 65px;
        height: 30px;
        margin: 0 auto;
    }

    }
    .preview-head-check {
      background: #efefef;
  }
  .helping-txt {
    font-size: 12px;
    color: #0810f5;
}
.drag-effects:hover .card{
  transform: translate(-10px, -20px);
    border-color: #5bc0eb;
    z-index: 1;
}
.drag-effects:hover .card-border {
  z-index: 1;
}
.card-border{
  position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    border: 2px dashed grey;
    z-index: -1;
}
.cursor-move {
  cursor: move;
}
.no-image-text {
  font-size: 13px;
  text-align: center;
  margin-top: 46px !important;
  color: #212529;
}
.no-image-text-nutrition {
font-size: 13px;
    text-align: center;
    color: #212529;
    padding: 0px 15px;
}
.hint-label{
    color: #bcbcbc;
    font-size: 13px;
    position: relative;
    top: -6px;
}
.emoji-popup .modal-body {
  background: #f7f7f7;
  height: 400px;
  overflow: auto;
}
.emoji-popup .nav-pills-custom .nav-link {
  
  height: 36px;
}
.emoji-popup .nav-pills-custom .nav-link.active {
  border-top: 2px solid;
}
.two-error {
  top: 116px;
}
.question-text{
  height: 38px !important;
}
.h-30 {
  height: 30px !important;
}
.assign-heading {
  padding: 15px;
  font-weight: 500;
}
.multiple-gt .formed-type span {
  font-weight: 500;
  color: #424141;
}
.date-desined {
  font-weight: 500;
  font-size: 15px;
  color: #020202;
  background: #d5dcff;
  padding: 3px 4px;
  text-align: center;
}
.date-bottom span {
  font-size: 13px;
  font-weight: 500;
  color: #101c54;
}

.date-picked-assign p {
  float: left;
  width: 100%;
  padding-left: 16px;
  font-size: 13px;
  margin: 0;
  color: #173a64;
  font-weight: 500;
}
.date-picked-assign {
  font-size: 14px;
  margin-top: 10px;
  background: #f5fff6;
  padding-top: 10px;
}
.assigned-date-prop {
  font-size: 12px !important;
  font-weight: 500;
  margin-top: 10px;
  color: #000 !important;
  text-align: center;
  background: #fff;
  padding: 10px;
}
.emoji-popup1 .modal-dialog {
  max-width: 500px;
}
.comment-popup1 .modal-dialog {
  width: 370px;
  padding: 5px;
  height: auto;
}
.cal-week-view .cal-current-time-marker {
  z-index: 1;
}