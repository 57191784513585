.grid-view-section{
	.hot-leads{
		color: $DoveGray;
		text-transform: capitalize;
		span{
			i{
			color:$hotlead;
		     }
		}
	}
	.warm-leads{
		color: $DoveGray;
		text-transform: capitalize;
		span{
			i{
			color:$warmlead;
		     }
		}
	}
	.cold-leads{
		color: $DoveGray;
		text-transform: capitalize;
		span{
			i{
			color:$coldlead;
		     }
		}
	}
	.label-color {
		color: $DoveGray;
		text-transform: capitalize;
	}
	.page-nation {
		li {
			a {
				background: $white;
				padding: 5px 8px;
				margin-right: 10px;
				color: $DoveGray;
				&:hover {
					background: $primary-clr;
					padding: 5px 8px;
					margin-right: 10px;
					color: $secondary-clr;
					text-decoration: none;
				}
			}
		}
		li.active {
			a {
				background: $primary-clr;
				padding: 5px 8px;
				margin-right: 10px;
				color: $secondary-clr;
			}
		}
	}
	.grid-main-section {
		h4 {
			font-size: 16px;
			text-transform: uppercase;
			color: $primary-clr;
		}
	}

}
.grid-card .dropdown {
    position: absolute;
    right: -14px;
    top: -16px;
}
.grid-card .dropdown {
    position: absolute;
    right: 0px;
    top: 5px;
}
.grid-card .btn {
    padding:0;
}
.grid-card .dropdown-menu .dropdown-item {
    padding: .5rem 1rem;
    font-size: .800rem;
}
.grid-card .card-title {
    font-size: 15px;
	color: $primary-clr;
	font-weight: 600;
}
.grid-card .card-details p {
    font-size: 13px;
    color: $DoveGray;
    font-weight: 600;
    margin-bottom: 7px;
}
.grid-card .icons-section span,.grid-card .icons-section a {
    font-size: 26px;
	color: $DoveGray;
}
.grid-card .circle {
    position: absolute;
    top: -10px;
    left: -6px;
    font-size: 22px;
}
.grid-card {
    overflow: hidden;
}
.hot .circle i{
	color:$hotlead;
}
.warm .circle i{
	color:$warmlead;
}
.cold .circle i{
	color:$coldlead;
}
.toggle-switch a {
color: $Manatee;
text-decoration: none;
}
.toggle-switch a:hover,.toggle-switch a.active{
	color: $secondary-clr;
	text-decoration: none;
	} 
.toggle-switch{
	background: $Scorpion;
	padding: 10px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    position: relative;
    top: -13px;
}
.grid-card .dropdown-menu{
	font-size: 0.65rem;
}
/*list-view*/
.list-grid{
	overflow: hidden;
}
.list-grid .card-title {
    font-size: 15px;
	color: $primary-clr;
	font-weight: 600;
}
.list-grid .card-details p {
    font-size: 13px;
    color: $DoveGray;
    font-weight: 600;
    margin-bottom: 7px;
}
.list-grid .icons-section span,.list-grid .icons-section a {
    font-size: 26px;
	color: $DoveGray;
}
.list-grid .circle {
    position: absolute;
    top: -10px;
    left: -6px;
    font-size: 22px;
}
.list-options {
    position: absolute;
    right: -42px;
    top: -2px;
}
.list-grid .dropdown-menu{
	font-size: 0.65rem;
}
@media only screen and (max-width: 600px) {
.card-body {
    text-align: center;
}
.primary-btn-l {
    width: 100%;
}
.toggle-switch {
    display: none;
}
}
@media only screen and (max-width: 968px) {
.toggle-switch {
    position: relative;
    top: -11px;
    border-radius: 5px;
}
}

.page-nation .mat-paginator-container{
	border: none;
	margin-top: -20px;
	color: $primary-clr;
    font-weight: 500;
}
.page-nation .mat-paginator {
    background: $Alabaster;
}
.page-nation .mat-paginator-icon {
    width: 28px;
    fill: $secondary-clr !important;
    background: $primary-clr;
}

.rounded-number {
	border-radius: 50%;
    padding: 3px 10px;
    text-align: center;
    margin-right: 15px;
	background: $primary-clr;
	color: $white;
  }
// .breadcrumb-arrow .breadcrumb-custom a {
// 		padding-left: 20px;
// 		display: inline-block;
// 		padding: 0 38px 0 38px;
// 		margin: 0 10px 10px 0;
// 		height: 40px;
// 		line-height: 40px;
// 		position: relative;
// 		font-size: 16px;
//         text-decoration: none !important;
		
// }
.breadcrumb-arrow .breadcrumb-custom a {
    display: inline-block;
    padding: 0 15px 0 25px;
    margin: 0 10px 10px 0;
    height: 40px;
    line-height: 40px;
    position: relative;
    font-size: 11px;
    text-decoration: none !important;
}
.breadcrumb-arrow-active{
    background: $primary-clr;
	color: $secondary-clr !important;
}
.breadcrumb-arrow .breadcrumb-custom .breadcrumb-arrow-active:after{
    border-color: transparent transparent transparent $primary-clr;
}
.breadcrumb-arrow-completed{
    background: $Sushi;
	color: $white !important;
}
.breadcrumb-arrow-completed2{
    background: $ChateauGreen;
	color: $white !important;
}
.breadcrumb-arrow .breadcrumb-custom .breadcrumb-arrow-completed:after{
    border-color: transparent transparent transparent $Sushi;
}
.breadcrumb-arrow .breadcrumb-custom .breadcrumb-arrow-completed2:after{
    border-color: transparent transparent transparent $ChateauGreen;
}
.breadcrumb-arrow .breadcrumb-custom a:before {
    position: absolute;
    top: 0;
    content: '';
    left: 0;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 20px 0 20px 20px;
    border-color: transparent transparent transparent $Alabaster;
    z-index: 1;
}
.breadcrumb-arrow .breadcrumb-custom a:after {
    position: absolute;
    top: -8px;
    content: '';
    left: 100%;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 27px 0px 30px 27px;
    border-color: transparent transparent transparent $primary-clr;
    z-index: 2;
}
.breadcrumb-arrow .breadcrumb-custom .breadcrumb-arrow-second {
    background: $FrenchGray;
    color: $white !important;
}
.breadcrumb-arrow .breadcrumb-custom .breadcrumb-arrow-second:after {
    position: absolute;
    top: -8px;
    content: '';
    left: 100%;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 27px 26px 30px 27px;
    border-color: transparent transparent transparent $FrenchGray;
    z-index: 2;
}
.breadcrumb-arrow .breadcrumb-custom .breadcrumb-arrow-third {
    background: $Aluminium;
    color: $white !important;
}
.breadcrumb-arrow .breadcrumb-custom .breadcrumb-arrow-third:after {
    position: absolute;
    top: -8px;
    content: '';
    left: 100%;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 27px 26px 30px 27px;
    border-color: transparent transparent transparent $Aluminium;
    z-index: 2;
}
.check-custom .mat-checkbox-label {
    color: $primary-clr;
    text-transform: capitalize;
    font-size: 12px;
    font-weight: 500;
    white-space: break-spaces;
    line-height: 15px;
}
.ins-active  .mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
    background-color: lightblue !important;
}
.ins-active  .mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
    background-color: blue !important;
}
.ins-active .mat-slide-toggle.mat-checked .mat-ripple-element {
    background-color: lightblue !important;
}
.add-btn {
    color: $primary-clr;
    font-size: 14px;
    font-weight: 600;
}
.grid-main-section .card{
    min-height: 230px;
}
.cdk-overlay-container {
    position: fixed;
    z-index: 1000;
    z-index: 9999999;
}
.mat-simple-snackbar {
    font-size: 1.2em;
    color: white;
}
      
.snackbar-error {
    background-color: $red;
 }
      
.snackbar-success {
    background-color: $green;
}
      
.snackbar-info {
    background-color: $blue;
}
.mat-simple-snackbar-action {
    color: $white;
}
.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
    border-color: $primary-clr;
}
.mat-radio-button.mat-accent {
	.mat-radio-inner-circle {
		background: $primary-clr;
	}
	.mat-radio-ripple {
		.mat-ripple-element {
			&:not(.mat-radio-persistent-ripple) {
				background: $primary-clr;
			}
		}
	}
	&:active {
		.mat-radio-persistent-ripple {
			background: $primary-clr;
		}
	}
}
.mat-radio-button.mat-accent.mat-radio-checked {
	.mat-radio-persistent-ripple {
		background: $primary-clr;
	}
}

.insurance-sub {
    font-size: 13px;
    font-weight: 500;
    color:$primary-clr;
}
.insurance-imgsize {
    font-size: 12px;
    font-weight: 500;
    color:red;
    margin-top:5px;
}
.custom-upload input{
    opacity: 0;
    position: absolute;
    height: 250px;
    cursor: pointer;
    width: 250px;
}
.custom-upload .file-upload{ 
    background: $AthensGray; 
    width: 250px;
    text-align: center;
    padding: 19px 24px;
    height: 250px;
    color: #59595B;
    padding-top: 60px;
    cursor: pointer;
}
.action-icon .fa-download {
    color: $primary-clr;
    font-size: 18px;
    vertical-align: middle;
}
.action-icon{
    position: absolute;
    bottom: 0;
    background: $AthensGray;
    width: 100%;
    left: 0;
    text-align: right;
    padding: 10px;
}
.action-icon-ins .fa-download {
    color: $primary-clr;
    font-size: 18px;
    vertical-align: middle;
}
.action-icon-ins{
    bottom: 0;
    background: $AthensGray;
    width: 67%;
    left: 0;
    text-align: right;
    padding: 10px;
}
.preview-bg {
    background: $AthensGray;
    height: 250px;
    margin-top: 5px;
    width: 250px;
}
.grid-main-section .col {
    width: 267px;
  }
  .content-card {
    min-height: 120px !important;
  }
.cursor-pointer{
    cursor: pointer;
}
.action-icon button {
    border: none;
}
.custom-textarea{
    width: 100%;
}
.custom-textarea .mat-form-field-underline{
    height: 0 !important;
}
.custom-textarea .mat-form-field-flex{
    padding:10px;
    border: 1px solid $SilverChalice;
    border-radius: 10px;
}
.custom-options{
    width: 100%;
    margin-top: 30px;
}
.history-section label{
    color: $primary-clr;
    
}
.odd-bg {
    padding: 15px;
    background: $BlackSqueeze;
}
.btn-arrow {
    vertical-align: middle;
}
.btn-arrow .fas {
    color: $cancel-bg;
    font-size: 30px;
}
.btn-arrow .fas:hover,.btn-arrow.active .fas {
    color: $primary-clr;
    font-size: 30px;
}
.btn-verified {
    text-transform: uppercase;
    border: 2px solid $ChateauGreen;
    color: $gray-600;
    font-size: 14px;
    vertical-align: middle;
}
.btn-verified img{
    vertical-align: sub;
    margin-right: 5px;
}
.btn-return {
    text-transform: uppercase;
    border: 2px solid $Monza;
    color: $gray-600;
    font-size: 14px;
    vertical-align: middle;
}
.btn-return img{
    vertical-align: sub;
    margin-right: 5px;
}
.icon-color{
    color: $primary-clr;
}
.icon-close{
    color: $Monza  !important;
}
.icon-close .material-icons {
    border: 1px solid;
    border-radius: 50%;
}
.icon-color .material-icons {
    border: 1px solid;
    border-radius: 50%;
}
.info-icon {
    font-size: 13px;
    top: -5px;
    position: relative;
    right: -1px;
}
.col-btn-dis{
    background: none;
    border: none;
    display: inline-block;
    width: auto;
    font-size: 20px;
}
.returned {
    background: #cc2330 !important;
    border-color: #cc2330 !important;
}
.verified {
    background: #287d3c !important;
    border-color: #287d3c !important;
}
.review {
    background: #FF8800  !important;
    border-color: #FF8800  !important;
}
.resend-mail {
    background: #2e5aac !important;
    border-color: #2e5aac !important;
}
.pointofcontact {
    color: #1685c5 !important;
}
.benefits {
    color: #ad8f22 !important;
}
.label-small {
    padding-right: 10px;
    color: #73B3AB !important;
    font-size: 12px !important;
    font-weight: 600 !important;
    float: left;
    width: 93px;
}
p.name-client {
    text-transform: uppercase;
    color: #2b2b2b !important;
    font-weight: 700 !important;
}
.value-small{
    float: left;
    width:185px; 
}
.disabled {
    pointer-events: none;
    opacity: 1;
  }
  .reason-btn {
    font-size: 12px;
    background: #606060;
    padding: 4px 9px;
    color: #ffffff !important;
    text-transform: capitalize;
    text-decoration: none !important;
    margin-right: 10px;
    border-radius: 3px;
    font-weight: 300;
}
.reason-btn:hover {
    background: #4d4a4a;
}
.plain-message {
    color: #746a6a !important;
    box-shadow: 0 0 7px #8d7878;
    padding: 4px;
    span {
        color: #CC222B;
        font-size: 12px;
    }
}
