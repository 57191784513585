

.counter {
    color: #00cfdd;
    text-align: center;
    height: 155px;
    width: 100%;
    padding: 10px;
    margin: 0 auto;
    border: 8px solid #00cfdd;
    border-radius: 20px 20px;
    position: relative;
    z-index: 1;
    background: #fff;
}

.counter1 {
    color: #f27f21;
    text-align: center;
    height: 155px;
    width: 100%;
    padding: 10px;
    margin: 0 auto;
    border: 8px solid #f27f21;
    border-radius: 20px 20px;
    position: relative;
    z-index: 1;
    background: #fff;
}

.counter2{
    color: #39DA8A;
    text-align: center;
    height: 155px;
    width: 100%;
    padding: 10px;
    margin: 0 auto;
    border: 8px solid #39DA8A;
    border-radius: 20px 20px;
    position: relative;
    z-index: 1;
    background: #fff;
}

.counter3{
    color: #5A8DEE;
    text-align: center;
    height: 155px;
    width: 100%;
    padding: 10px;
    margin: 0 auto;
    border: 8px solid #5A8DEE;
    border-radius: 20px 20px;
    position: relative;
    z-index: 1;
    background: #fff;
}


.count-counter {
    position: absolute;
    padding: 5px;
    border-radius: 14px;
    padding: 2px 8px;
    font-size: 16px;
    position: absolute;
    top: 2px;
    right: 17px;
    transform: translateX(-50%);
    background-color: #069ba5;
    color: #fff;
    box-shadow: 0px 0px 3px 0px #fff;
    border: 3px solid #fff;
    width: 57px;
    height: 32px;
}
.line-ellipsis {
    font-weight: 500;
    font-size: 16px;
    margin-top: 10px;
}
.physician-Dash .card-body {
    padding: 5px;
}

.flexible-calendar .btn-group .btn {
    background: #fff !important;
    color: #ccc;
    border-color: #fff !important;
}
.flexible-calendar .btn-group .btn-outline-secondary {
    color: #000;
    font-weight: 600;
}
.flexible-calendar .cal-header .cal-cell {
    font-weight: 500;
    color: #b6b6b6;
    font-size: 12px;
}
.flexible-calendar .cal-month-view .cal-day-cell:not(:last-child) {
    border: none;
}
.flexible-calendar .cal-month-view .cal-days .cal-cell-row {
    border: none;
}
.flexible-calendar .cal-month-view .cal-days {
    border: none;
}
.flexible-calendar .cal-month-view {
    background-color: #fff;
    font-size: 11px;
}
.flexible-calendar .cal-month-view .cal-day-cell {
    min-height: 20px !important;
}
.flexible-calendar .cal-month-view .cal-cell-top {
    min-height: 20px;
}
.flexible-calendar .cal-month-view .cal-day-cell.cal-today .cal-day-number {
    font-size: 1.2em;
    opacity: 1;
    color: rgb(255, 255, 255);
    font-weight: 500;
}
.flexible-calendar .cal-month-view .cal-day-cell.cal-today {
    // background-color: #a4cdff;
//     background-image: linear-gradient( 
// -222deg
//  , #3675c2, #acd0fd);
background-image: linear-gradient(
-222deg
, #33b5e6, #4a91ab);
    box-shadow: 0px 0px 52px -18px rgb(0 0 0 / 75%);
    color: #000;
    border-radius: 5px;
}
.flexible-calendar .task-value-section{
    display: none;
}
.flexible-calendar .selection-btns{
    border: 2px solid #33b5e6;
    width: 300px;
    border-radius: 5px;
    color: #33b5e6 !important;
    margin-bottom: 15px;
}
.flexible-calendar .selection-btns .btn-primary{
    color: #33b5e6 !important;
    border-color:#33b5e6 !important;
}
.flexible-calendar .selection-btns .btn-primary.active{
background-color: #33b5e6 !important;
color:#fff !important;
}

.flexible-calendar .cal-week-view{
    overflow: auto;
    height: 60vh;
    scrollbar-width: thin;
      scrollbar-color: #3da5f4 #ffffff;
}

    /* Works on Chrome, Edge, and Safari */
    .flexible-calendar .cal-week-view::-webkit-scrollbar {
        width: 12px;
      }
      
      .flexible-calendar .cal-week-view::-webkit-scrollbar-track {
        background: #ffffff;
      }
      
      .flexible-calendar .cal-week-view::-webkit-scrollbar-thumb {
        background-color: #3da5f4;
        border-radius: 20px;
        border: 3px solid #ffffff;
      }
  
      .flexible-calendar .cal-week-view-trans {
        scrollbar-width: thin;
        scrollbar-color: #3da5f4 #ffffff;
      }
      
      /* Works on Chrome, Edge, and Safari */
      .flexible-calendar .cal-week-view-trans::-webkit-scrollbar {
        width: 12px;
      }
      
      .flexible-calendar .cal-week-view-trans::-webkit-scrollbar-track {
        background: #f1f1f1;
      }
      
      .flexible-calendar .cal-week-view-trans::-webkit-scrollbar-thumb {
        background-color: #3da5f4;
        border-radius: 20px;
        border: 3px solid #ffffff;
      }
.not-selection-view{
    border: 2px solid #33b5e6;
}
.not-selection-view i {
    color: #33b5e6;
}
.legendary {
    position: relative;
    bottom: 0;
    width: 100%;
    height: 30px;
    background: #fff;
    line-height: 30px;
    border-top: 1px solid #efefef;
}
.entry {
    position: relative;
    padding: 0 0 0 25px;
    font-size: 13px;
    display: inline-block;
    line-height: 30px;
    background: transparent;
    .orange {
        background: rgba(247, 167, 0, 1);
    }
    .blue {
        background: rgba(156, 202, 235, 1);
    }
    .yellow {
        background: rgba(249, 233, 0, 1);
    }
    .green {
        background: rgba(153, 198, 109, 1);
    }
}
.entry:after {
    position: absolute;
    content: '';
    height: 5px;
    width: 5px;
    top: 12px;
    left: 14px;
}
.entry.yellow:after {
    background: rgba(249, 233, 0, 1);
}
.entry.green:after {
    background: rgba(153, 198, 109, 1);
}
.entry.orange:after {
    background: rgba(247, 167, 0, 1);
}
.entry.blue:after {
    background: rgba(156, 202, 235, 1);
}
.calendar-event {
    width: 370px;
    // box-shadow: 0px 0px 35px -16px rgba(0, 0, 0, 0.75);
    padding: 20px 30px;
    color: #363b41;
    display: inline-block;
    background: #fff;
  }
  
  .calendar_header {
    border-bottom: 2px solid rgba(0, 0, 0, 0.08);
  }
  
  .header_copy {
    color: #A39D9E;
    font-size: 20px;
  }
  
  .calendar_plan {
    margin: 20px 0 40px;
  }
  
  .cl_plan {
    width: 100%;
    height: 140px;
    background-image: linear-gradient(-222deg, #FF8494, #ffa9b7);
    box-shadow: 0px 0px 52px -18px rgba(0, 0, 0, 0.75);
    padding: 30px;
    color: #fff;
  }
  
  .cl_copy {
    font-size: 20px;
    margin: 20px 0;
    display: inline-block;
  }
  
  .cl_add {
    display: inline-block;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #fff;
    cursor: pointer;
    margin: 0 0 0 65px;
    color: #c2c2c2;
    padding: 11px 13px;
  }
  
  .calendar_events {
    color: #A39D9E;
  }
  
  .ce_title {
    font-size: 14px;
  }
  
  .event_item {
    margin: 18px 0;
    padding: 5px;
    cursor: pointer;
  }
  .event_item:hover {
    background-image: linear-gradient(-222deg, #6badff, #8cbcf7);
    box-shadow: 0px 0px 52px -18px rgba(0, 0, 0, 0.75);
  }
  .event_item:hover .ei_Dot {
    background-color: #fff;
  }
  .event_item:hover .ei_Copy, .event_item:hover .ei_Title {
    color: #fff;
  }
  
  .ei_Dot, .ei_Title {
    display: inline-block;
  }
  
  .ei_Dot {
    border-radius: 50%;
    width: 10px;
    height: 10px;
    background-color: #A39D9E;
    box-shadow: 0px 0px 52px -18px rgba(0, 0, 0, 0.75);
  }
  
  .dot_active {
    background-color: #5a8dee;
  }
  
  .ei_Title {
    margin-left: 10px;
    color: #363b41;
  }
  
  .ei_Copy {
    font-size: 12px;
    margin-left: 27px;
  }
  
  .dark {
    background-image: linear-gradient(-222deg, #646464, #454545);
    color: #fff;
  }
  .dark .header_title, .dark .ei_Title, .dark .ce_title {
    color: #fff;
  }
  .appointments .appointment-list {
    background-color: #fff;
    border: 1px solid #f0f0f0;
    border-radius: 4px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-bottom: 20px;
    padding: 20px;
  }
  .appointment-list .profile-info-widget {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-right: auto;
    text-align: left;
  }
  .appointments .appointment-action {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
  }
  .profile-info-widget .booking-doc-img {
    margin-right: 15px;
  }
  .profile-det-info {
    overflow: hidden;
  }
  .profile-det-info h3 {
    font-size: 17px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  .patient-details h5 {
    color: #2a2a2a;
    font-size: 12px;
    font-weight: 500;
    margin-bottom: 8px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.status-section {
  width: 100%;
  text-align: left;
  font-size: 12px;
  vertical-align: middle;
}
.status-section .status {
  font-size: 40px;
  margin: -4px 2px 0 0;
  display: inline-block;
  vertical-align: middle;
  line-height: 10px;
}
.gp-color{
  color: #957111 !important;
}
.idth-color{
  color:#207c97 !important;
}
.intakclr{
  color: #84a72d !important;
}
  .appointment-list .profile-info-widget .booking-doc-img img {
    border-radius: 4px;
    height: 120px;
    object-fit: cover;
    width: 120px;
  }
  .bg-success-light {
    background-color: rgba(15, 183, 107,0.12) !important;
    color: #26af48 !important;
}
.bg-danger-light {
  background-color: rgba(242, 17, 54,0.12) !important;
  color: #e63c3c !important;
}
.bg-info-light {
  background-color: rgba(2, 182, 179,0.12) !important;
  color: #1db9aa !important;
}
.appointment-action a {
  font-size: 13px;
}
/*new design*/
// .sh-side-options-container {
//   position: fixed;
//   top: 190px;
//   left: 0;
//   width: 60px;
//   background-color: white;
//   border-top-left-radius: 0;
//   margin-left: 0px;
//   box-shadow: -10px 0px 20px 2px rgba(0, 0, 0, 0.06);
//   z-index: 999;
//   border-top-right-radius: 5px;
//   border-bottom-left-radius: 0;
//   border-bottom-right-radius: 5px;
// }
.sh-side-options-item {
  display: block;
  text-align: center;
  margin: 0px;
  transition: 0.3s all ease-in-out;
  position: relative;
  padding: 7px;
  cursor: pointer;
}
.sh-side-options-item:not(:last-child) {
  border-bottom: 1px solid #f1f3fc;
}
.sh-side-options-item:not(:hover):not(:focus) {
  color: #9396a5!important;
}
.sh-side-options-item-container {
  border-radius: 4px;
  padding: 5px 0;
}
.sh-side-options-item:hover .sh-side-options-item-container{
  background-color: #f3f5fd;
}
.sh-side-options-item i {
  color: #9396a5;
  font-size: 20px;
}
.sh-side-options-hover {
  position: absolute;
  background-color: #ffffff;
  color: #32343d;
  padding: 20px 26px;
  transform: translateX(70%);
  right: 0px;
  top: 0;
  bottom: 0;
  opacity: 0;
  transition: 0.2s all ease-in-out;
  z-index: -100;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  font-size: 13px;
  box-shadow: 0px 0px 20px 2px rgba(0, 0, 0, 0.08);
  visibility: hidden;
  min-width: 240px;
  font-weight: 500;
}
.sh-side-options-item:hover .sh-side-options-hover {
  opacity: 1;
  transform: translateX(97%);
  visibility: visible;
  line-height: 4px;
}
a.sh-side-options-item.active {
  border-right: 3px solid $primary-clr;
}
// .new {
//   border-radius: 25px;
//   font-size: 12px;
//   margin-bottom: 8px;
// }
.date-time {
  font-size: 12px;
}
.custom-radio-grp .btn {
  background: transparent !important;
  color: $primary-clr !important;
  font-size: 14px;
  padding: 5px 25px;
  outline: none !important;
  box-shadow: none !important;
  border: none !important;
}
.custom-radio-grp .btn.active {
  background: $primary-clr !important;
  border-radius: 25px !important;
  color: #fff !important;
  border-color: #fff !important;
  border: 1px solid $primary-clr !important;
}
.color-c1{
  color: #4e55fd !important;
}
.color-c2{
  color: #ce3737 !important;
}
.color-c3{
  color: #e79b2b !important;
}
.color-c4{
  color: #a63bad !important;
}
.color-c5{
  color: #259f55 !important;
}
.color-c6{
  color: #efb508 !important;
}
.color-c7{
  color: #36aaaf !important;
}
.mytask{
  ul{
    padding: 0;
    list-style-type: none;
  }
.list-item {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word
}

.list-item.block .media {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0
}

.list-item.block .list-content {
  padding: 1rem
}

.w-40 {
  width: 80px !important;
  height: 80px !important
}

.avatar {
  position: relative;
  line-height: 1;
  border-radius: 500px;
  white-space: nowrap;
  font-weight: 700;
  border-radius: 100%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  border-radius: 500px;
  box-shadow: 0 5px 10px 0 rgba(50, 50, 50, .15)
}

.avatar img {
  border-radius: inherit;
  width: 100%
}

.gd-primary {
  color: #fff;
  border: none;
  background: #448bff linear-gradient(45deg, #448bff, #44e9ff);
  font-size: 23px;
}
.dropdown-menu.dropdown-menu-right.bg-black {
  font-size: 12px;
}
.flex {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto
}

.text-color {
  color: #225491;
  font-size: 14px;
  font-weight: 500;
}
.sub-except {
  font-size: 11px !important;
  font-weight: 400;
}

.text-sm {
  font-size: .825rem
}

.h-1x {
  height: 1.25rem;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical
}

.no-wrap {
  white-space: nowrap
}

.list-row .list-item {
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-align: center;
  align-items: center;
  padding: .75rem .625rem
}

.list-item {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word
}

.list-row .list-item>* {
  padding-left: .625rem;
  padding-right: .625rem
}

.dropdown {
  position: relative
}
}

.sh-side-options {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 12345678902;
  transition: 0.3s all ease;
  transition: 0.2s all;
  padding: 0 0;
  width: 420px;
  transform: translateX(-420px);
}
.sh-side-options-container {
  position: absolute;
  top: 216px;
  right: -75px;
  width: 60px;
    background-color: rgba(255,255,2555,1);
    border-radius: 5px;
    margin-right: 15px;
    box-shadow: -10px 0px 20px 2px rgb(0 0 0 / 6%);
}
.sh-side-demos-container {
  top: 0;
  left: 0;
  right: 0px;
  bottom: 0;
  position: absolute;
  // overflow-y: scroll;
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: right top;
  padding: 22px;
}
.sh-side-demos-container-close {
  position: absolute;
  top: 22px;
  right: 22px;
  cursor: pointer;
}
.sh-side-demos-intro-descr {
  max-width: 260px;
  margin: 0 auto;
  display: table;
  color: #838794;
}
.sh-side-demos-loop-container {
  position: relative;
  margin: 0 -10px;
  margin-top: 28px;
}
.sh-side-options.open {
  transform: translateX(0px);
  box-shadow: 0 0px 39px 10px rgba(0,0,0,0.2);
}
.tiles {
  background: #daebff;
  padding: 10px !important;
  height: 39px;
  font-size: 13px;
  border: 2px solid #173a64;
  margin-bottom: 10px;
  display: block;
  width: 100%;
  color: #173a64 !important;
  font-weight: 500;
  line-height: 15px;
}
.current-day {
  font-weight: 600;
  font-size: 14px;
  border: 1px solid;
  padding: 5px 15px;
  border-radius: 5px;
  color: #515151;
  text-decoration: none !important;
  margin-right: 10px;
}

.current-date {
  font-weight: 500;
  color: #565151;
  font-size: 16px !important;
}
.slider-arr a {
  
  font-size: 14px;
}
.status-filter {
  width: 135px;
  margin-right: 10px;
}
.action-accept-btn {
  border: 1px solid #31b12f;
  color: #31b12f !important;
  font-weight: 500;
}
.action-accept-btn:hover {
  border: 1px solid #31b12f;
  background-color: #31b12f !important;
  color:#fff !important;
}
.action-Reject-btn {
  border: 1px solid #b12f2f;
  color: #b12f2f !important;
  font-weight: 500;
}
.action-Reject-btn:hover {
  border: 1px solid #b12f2f;
  background-color: #b12f2f !important;
  color:#fff !important;
}
// .action-btn{
//   background: #e6f1ff;
//   border: 1px solid #173a64;
//   color: #173a64;
// }
.action-btn {
  background: #e6f1ff;
  border: 1px solid #173a64;
  color: #010101;
  width: 170px;
  text-align: left;
}
.appointment-action {
  width: 93%;
}
.popup-mytask .sub-except {
  font-size: 11px !important;
  font-weight: 400;
  width: 111px;
  float: left;
}
.task-month .cal-events {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-block !important;
  width: 160px;
}

.tab-card {
  border:1px solid #eee;
}

.tab-card-header {
  background:none;
}
/* Default mode */
.tab-card-header > .nav-tabs {
  border: none;
  margin: 0px;
}
.tab-card-header > .nav-tabs > li {
  margin-right: 1px;
}
.tab-card-header > .nav-tabs{
 border-bottom: 1px solid #f0f0f0;
}
.tab-card-header > .nav-tabs > li > a {
  border: 0;
  border-bottom:2px solid transparent;
  margin-right: 0;
  color: #737373;
  padding: 20px 7px;
  font-weight: 500;
}

.tab-card-header > .nav-tabs > li > a.show {
    border-bottom:4px solid #007bff;
    color: #007bff;
}
.tab-card-header > .nav-tabs > li > a:hover {
    color: #007bff;
}

.tab-card-header > .tab-content {
  padding-bottom: 0;
}
.btn-section-list {
  margin-bottom: 5px;
}
.col-event-mytask {
  background-color: #c1dfff;
  border-color: #0062cc;
  color: #000;
  padding: 0 5px;
  // margin-left: 2px;
  // margin-right: 2px;
  height: 22px;
  line-height: 22px;
  border-radius: 5px;
  font-size: 12px;
  margin-bottom: 5px;
  cursor: pointer !important;
  overflow: hidden;
  width: 173px;
  text-overflow: ellipsis;
  display: block;
  white-space: nowrap;
}
.mt--14{
  margin-top: -14px;
}
.modal-closing a {
  position: absolute;
  z-index: 99;
  background: #fff;
  padding: 3px 7px;
  border-radius: 50%;
  border: 1px solid #ccc;
  font-size: 11px;
  right: -13px;
  top: -12px;
}
.emoji-popup .modal-dialog {
  width: 500px;
}
.msg-nomeet {
  font-weight: 500;
  text-transform: capitalize;
}
.schedular .col-event-mytask {
  background-color: #fff;
  border: 1px solid #cdcdcd !important;
  padding-left: 0px !important;
  }
.intake-clr {
border-color: #bb2ed5;
}
.schedular  .cal-event {
  background-color: #fff;
  border: 1px solid #cdcdcd !important;
  padding-left: 0px !important;
}
.cal-container{
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-left: 5px;
}
.intake-clr {
color: #bb2ed5 !important;
border-left:3px solid #bb2ed5;
}

// .grp-thrphy-clr {
//   border: 1px solid #3ad0df !important;
//   }
  
  .grp-thrphy-clr  {
  color: #3ad0df !important;
  border-left:3px solid #3ad0df;
  }
//   .indivial-clr {
//     border: 1px solid #d99b3f !important;
// }
.indivial-clr  {
  color: #d99b3f !important;
  border-left:3px solid #d99b3f;
}
// .tour-clr{
//   border: 1px solid #7d8510 !important;
// }
.tour-clr {
  color: #7d8510 !important;
  border-left:3px solid #7d8510;
}
// .family-clr{
//   border: 1px solid #631fe0 !important;
// }
.family-clr {
  color: #631fe0 !important;
  border-left:3px solid #631fe0;
}
.btn-nutrition-common{
  width: 203px !important;
}
.cal-availablity {
  flex-grow: 1;
}
// .cal-week-view .cal-time-events .cal-event{
//   display: table;
// }
.cal-therapyname {
  color: #333;
  font-weight: 800;
  // border-bottom: 1px solid #ccc;
  margin-bottom: 5px;
}
.link-in-calendar {
  padding: 1px;
  background: #173a64;
  color: #fff !important;
  text-align: center;
  border-radius: 5px;
  margin-bottom: 5px;
}
.cal-assignedname {
  color: #555;
  line-height: 112%;
  white-space: normal;
  height: 59px;
}
.cal-assignedname-clini {
  color: #555;
 
  white-space: normal;
  
}
.cal-tooltip-inner {
  color: #000 !important;
  background: #fff;
  border: 1px solid #000;
  padding: 0px;

}
.cal-tooltip-inner .cal-container{
  border: none !important;
}
.Psycatric-clr {
  color: #25e01f !important;
  border-left:3px solid #3fe01f;
}

.PsycatricEva-clr {
  color: #1f99e0 !important;
  border-left:3px solid #1f73e0;
}
.PsycatricMed-clr {
  color: #e01f76 !important;
  border-left:3px solid #e01f73;
}

.Phonecall-clr {
  color:  #112bba !important;
  border-left:3px solid #1835db;
}

.School-clr {
  color: #22083b !important;
  border-left:3px solid #22083b;
}
.Speech-clr {
  color: #accf39 !important;
  border-left:3px solid #9db322;
}
.Nutrition-clr {
  color: #1a5a72 !important;
  border-left:3px solid #2d7fae;
}
.clr-red{
  background-color: #ff4000 !important;
}
.clr-limegreen{
  background-color: #bfff00 !important;
}
.clr-green{
  background-color: #00ff40 !important;
}
.clr-skyblue{
  background-color: #00ffff !important;
}
.clr-blue{
  background-color: #0040ff !important;
}
.clr-purple{
  background-color: #bf00ff !important;
}
.clr-pink{
  background-color: #ff00ff !important;
}
.fs-14 {
  font-size: 14px !important;
}
.client-search {
  margin: 0 !important;
  border-bottom: 1px solid #a5a4a4;
  position: relative;
  .material-icons {
    position: absolute;
    left: 0;
    top: 0;
    font-size: 22px;
    margin-top: 5px !important;
  }
  .mat-form-field-label-wrapper {
    left: 30px;
    font-size: 15px;
  }
  .mat-form-field-suffix {
    display: flex;
  }
  .mat-form-field-flex {
    display: flex;
    align-items: center;
  }
  .mat-input-element {
    padding-left: 30px;
  }
  .mat-form-field-underline {
    display: none;
  }
  .mat-form-field-wrapper {
    padding-bottom: 0 !important;
  }
  .mat-form-field-infix {
    border-top: none;
  }
}