

.avatar-task {
  display: inline-block;
  margin-bottom: 0;
  height: 2.7rem;
  width: 2.7rem;
  border-radius: 50%;
}
.avatar-task>img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  display: inherit;
}
.lef-tak-cont{
  padding: 8px 12px;
  -webkit-box-shadow: 0 2px 4px rgb(126 142 177 / 12%);
    box-shadow: 0 2px 4px rgb(126 142 177 / 12%);
    background-color: #fff;
}
.lef-tak-cont .flex-row li a {
  width: 240px;
}
.lef-tak-cont  ul li {
  // margin: 3px 0;
}
.lef-tak-cont ul li a.active {
  color: #173a64;
  font-weight: 600;
  font-size: 16px;
  border-left:4px solid ;
}
.project-column-heading__options_small {
  border-radius: 5px;
  background: #173a64;
  font-size: 10px;
  padding: 1px 6px;
  border: 1px solid #555;
  font-weight: 600;
  color: #fff;
  border-radius: 50%;
}
.lef-tak-cont ul li a {
  color: #888;
  padding: 8px 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: .25rem;
  text-decoration: none;
  font-weight: 500;
}
.lef-tak-cont .badge.bg-warning {
  background-color: rgba(250,174,66,.2)!important;
  color: #faae42;
}
.lef-tak-cont .badge.bg-primary {
  background-color: rgba(109,127,255,.2)!important;
  color: #6d7fff;
}
.task-item:first-child {
  -webkit-animation-delay: .1s;
  animation-delay: .1s;
}
.task-item {
  background-color: #ffffff;
  border-radius: 0;
  padding: 11px;
  color: #000;
  // display: flex;
  align-items: center;
  box-shadow: 1px 3px 8px rgb(156 156 156 / 80%);
  position: relative;
  transition: box-shadow 0.3s;
  animation-duration: 0.5s;
}
// .task-item {
//   background-color: #fff;
//   border-radius: .25rem;
//   padding: 18px 20px;
//   color: #000;
//   display: -webkit-box;
//   display: -ms-flexbox;
//   display: flex;
//   -webkit-box-align: center;
//   -ms-flex-align: center;
//   align-items: center;
//   -webkit-box-shadow: 0 2px 4px rgba(126,142,177,.12);
//   box-shadow: 0 2px 4px rgba(126,142,177,.12);
//   position: relative;
//   -webkit-transition: -webkit-box-shadow .3s;
//   transition: -webkit-box-shadow .3s;
//   transition: box-shadow .3s;
//   transition: box-shadow .3s,-webkit-box-shadow .3s;
//   -webkit-animation-duration: .5s;
//   animation-duration: .5s;
// }
.task-item .form-check-input {
  -ms-flex-negative: 0;
  flex-shrink: 0;
  margin-top: .1em;
  margin-right: 15px;
}
.task-item .form-check-input:checked[type=checkbox] {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3E%3Cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3 6-6'/%3E%3C/svg%3E");
}

.task-item  .form-check-input {
  border-radius: 100px!important;
}
.task-item  .form-check-input-lg {
  width: 1.7em!important;
  height: 1.7em!important;
  margin-left: 0;
}
.task-item  .form-check-input {
  width: 1em;
  height: 1em;
  margin-top: .25em;
  vertical-align: top;
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: contain;
  border: 1px solid rgba(0,0,0,.25);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -webkit-print-color-adjust: exact;
  color-adjust: exact;
  position: relative;
}
.task-item-completed .form-check-input {
  background-color: #05b171;
  border-color: #05b171;
}
.task-item-body {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  font-size: .79rem;
  font-weight: 400;
}
.task-item-info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 20px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-left: 15px;
  color: #6c757d!important;
  font-size: 0.75rem;
  font-weight: 400;
}

.task-item .progress,.task-item .progress-bar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  font-size: .65625rem;
}
.task-item .progress {
  height: .1rem;
  position: absolute;
  right: 0;
  left: 0;
  bottom: -1px;
  border-radius: 0;
  background: none;
}
.task-item-action {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  padding: 0 5px 0;
  display: none;
  background: rgb(239 239 239 / 90%);
  border: 1px solid #ccc;
  height: 20px;
  width: 101px;
}
.task-item-action i {
  font-size: 13px;
}
.task-item:hover .task-item-action {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.task-item:hover .task-item-info {
  opacity: 1;
}
.task-item-action>a {
  padding: 0 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  font-size: 17px;
  color: $primary-clr;
    text-decoration: none;
}
.task-items {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  gap: 15px;
  position: relative;
}
// .status-section-task {
//   width: 110px;
// }

// .project-tasks {
    //   display: grid;
    //   grid-template-columns: repeat(4, 1fr);
    //   width: 100%;
    //   grid-column-gap: 1.5rem;
    // }
    .project-column-heading {
      margin-bottom: 10px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 2px solid #173a64;
      padding-bottom: 5px;
  }
  .task__stats.new svg {
    color: #bcbcbc;
}
h2.project-column-heading__title .fa-file {
  color: #6d7fff;
}
h2.project-column-heading__title .fa-clock {
  color: #e1852b;
}
h2.project-column-heading__title .fa-check-circle {
  color: green;
}
    .project-column-heading__title {
      font-size: 20px;
    }
    .project-column-heading__options {
      background: transparent;
      color: var(--light-grey);
      font-size: 18px;
      border: 0;
      cursor: pointer;
    }

    .task {
      background-color: var(--white);
      padding: 1rem;
      border-radius: 8px;
      width: 100%;
      box-shadow: rgba(99, 99, 99, 0.1) 0px 2px 8px 0px;
      margin-bottom: 1rem;
      border: 3px dashed transparent;
      cursor: move;
    }
    .task:hover {
      box-shadow: rgba(99, 99, 99, 0.3) 0px 2px 8px 0px;
      border-color: rgba(162, 179, 207, 0.2) !important;
    }
    .task p {
      font-size: 13px;
      margin: 1.2rem 0;
    }
    .task__tag {
      border-radius: 100px;
      padding: 2px 13px;
      font-size: 12px;
    }
    .task__tag--copyright {
      color: #323130;
      background-color: #f1e364;
    }
    .task__tag--overdue {
      color: #c62323;
      background-color: #ffbcb0;
    }
    .task__tag--design {
      color: #2d86ba;
      background-color:#ceecfd;
    }
    .task__tag--illustration {
      color: #13854e;
      background-color: #d6ede2;
    }
    .task__tags {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .task__options {
      background: transparent;
      border: 0;
      color: var(--light-grey);
      font-size: 17px;
    }
    .task__stats {
      position: relative;
      width: 100%;

      font-size: 12px;
      display: flex;
    }
    .task__stats span:not(:last-of-type) {
      margin-right: 1rem;
    }
    .task__stats svg {
      margin-right: 5px;
    }
    .task__stats.complete svg {
      color: green;
  }
  .task__stats.overdued svg {
    color: red;
}
.task__stats.inprog svg {
  color: orange;
}
.task__stats.newed svg {
  color: rgb(101, 176, 238);
}
.task__stats.duein svg {
  color: rgba(196, 196, 14, 0.747);
}
.task-color {
  margin-bottom: 10px;
  background: #fff;
  padding: 4px 14px !important;
}
    .task__owner {
      width: 25px;
      height: 25px;
      border-radius: 100rem;
      background: var(--purple);
      position: absolute;
      display: inline-block;
      right: 0;
      bottom: 0;
    }

    .task-hover {
      border: 3px dashed var(--light-grey) !important;
    }

    .task-details {
      width: 24%;
      border-left: 1px solid #d9e0e9;
      display: inline-block;
      height: 100%;
      vertical-align: top;
      padding: 3rem 2rem;
    }
    .svg-inline--fa.fa-w-16 {
      width: 1em;
      height: 1em;
    }
    .svg-inline--fa.fa-w-14 {
      width: .875em;
    }
    .project-column-heading__options {
      border-radius: 5px;
      background: #173a64;
      font-size: 13px;
      padding:3px 9px;
      border: 1px solid #555;
      font-weight: 600;
      color: #fff;
      border-radius: 50%;
  }
  .kanban-task .dropdown-menu {
    font-size: 12px;
}
.task-create-section .ql-editor {
    white-space: pre;
}
.task-create-section .ql-container {
  height: 69%;
}
.assigned-mails span {
  font-size: 10px;
  margin-right: 5px;
  background: aliceblue;
  display: inline-block;
  padding: 5px;
  border-radius: 3px;
  border: 1px solid #2d3967;
  margin-bottom: 5px;
}
.assigned-mails {
  white-space: normal;
  word-break: break-word;
  width: 100%;
  float: left;
  margin-top: 5px !important;
  margin-bottom: 5px !important;
}
.create-tasked {
  background: #265da1;
  color: #fff !important;
  padding: 8px 20px;
    display: flex;
    align-items: center;
    border-radius: 0.25rem;
    text-decoration: none;
    font-weight: 500;
    font-size: 0.8125rem;
  // width: 57%;
  // margin: 0 auto;
}
.additional-steps .form-control-pf-value {
  background: #fff;
  width: 100%;
  text-align: left;
}
.additional-steps .col-form-label {
  font-size: 14px;
  color: #494747;
  font-weight: 500;
}
.attach-btn {
  background: #fff;
  border: none;
  margin-top: 8px;
  font-weight: 500;
  font-size: 14px !important;
}
.histor-content .card{
  background-color: #fff;

}
.histor-content .card-header {
  padding: 10px;
  border: none;
  background: #265da1;
  color: #fff;
}
.histor-content .card-header h4{
  font-size: 14px;
  margin-bottom: 0;
  font-weight: 500;
}
.histor-content .media-body{
  font-size: 14px;
}
.upload-btn-wrapperring input[type=file] {
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  width: 100%;
  height: 100%;
}
.upload-btn-wrapperring {
  box-shadow: 0 1px 7px #ccc;
  border-radius: 5px;
  background: #f1f1f1;
}
.custom-selct-task {
  font-size: 13px;
  float: right;
  width: 107px;
  border-radius: 0;
  border: none;
  border-bottom: 1px solid #ccc;
  padding-left: 0;
  vertical-align: middle;
  cursor: pointer;
}
.uploaded--one {
  width: 100%;
  margin: 10px;
  background-color: #e6f5e9;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.uploaded--one .file {
  display: flex;
  flex-direction: column;
  width: 84%;
}
.uploaded--one .fa-file-pdf {
  padding: 15px;
  font-size: 40px;
  color: #0c3214;
}
.uploaded--one .file__name {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
  width: 100%;
  line-height: 0;
  color: #0c3214;
  font-size: 18px;
  letter-spacing: 1.5px;
}
.task-create-section {
  width: 100%;
  overflow: auto;
  max-height: 533px;
}
.text-xs{
  font-size:13px;
}
.text-sm{
  font-size:14px;
}
.text-md{
  font-size:16px;
}
.value-task {
  color: #173a64;
  font-weight: 600;
}
.task-create-modal{
  background: rgb(0 0 0 / 80%);
}
.filter-group {
  margin-right: 10px;
  border-radius: 5px;
}
.filter-group .btn {
  border: 1px solid #ccc;
  padding: 5px 10px;
}
.filter-group ul.dropdown-menu.show {
  width: 202px;
}
.filter-group ul.dropdown-menu li a{
  cursor: pointer;
}
.schedule-calendar-view {

}