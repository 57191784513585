.create-appointment {
    background: #fff;
}
.location{
    background: #fff;
}
.font-weight-500{
    font-weight: 500;
}
.nav-pills-custom .nav-link {
    color: #aaa;
    background: #fff;
    position: relative;
    font-size: 15px;
}

.nav-pills-custom .nav-link.active {
    color: $primary-clr;
    background: #fff;
}
.label-settings {
    font-weight: 600;
    font-size: 13px;
}
@media (min-width: 992px) {
    .nav-pills-custom .nav-link::before {
        content: '';
        display: block;
        border-top: 8px solid transparent;
        border-left: 10px solid #fff;
        border-bottom: 8px solid transparent;
        position: absolute;
        top: 50%;
        right: -10px;
        transform: translateY(-50%);
        opacity: 0;
    }
}

.nav-pills-custom .nav-link.active::before {
    opacity: 1;
}

.note-has-grid .single-note-item {
    border-radius: 10px;
}
.single-note-item .card {
    background: #f8f8f8;
}
.note-has-grid .single-note-item .side-stick {
    position: absolute;
    width: 3px;
    height: 35px;
    left: 0;
    background-color: $primary-clr;
}
.note-has-grid .time-label {
    font-weight: 400;
    padding-right: 10px;
    color: #6c6c6c;
}
.note-has-grid .single-note-item.all-category .point {
    color: rgba(79,84,103,.5);
    font-size: .625rem!important;
}
.time-val {
    color: #173a64;
    font-weight: 600;
}
.time-val-slot {
    font-size: 19px;
    font-weight: 500;
    color: #173a64;
}
.end-length{
    padding-right: 14px !important;
}
.inactive-stick{
    background-color:#ff0000 !important;
}
#v-pills-tab .nav-link {
    padding: 10px !important;
}
.file {
    position: relative;
    display: inline-block;
    cursor: pointer;
    height: 2.5rem;
}
.file-custom {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    z-index: 5;
    height: 2.5rem;
    padding: .5rem 1rem;
    line-height: 1.5;
    color: #555;
    background-color: #fff;
    border: .075rem solid #ddd;
    border-radius: .25rem;
    box-shadow: inset 0 .2rem .4rem rgba(0,0,0,.05);
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
.file-custom:before {
    position: absolute;
    top: -.075rem;
    right: -.075rem;
    bottom: -.075rem;
    z-index: 6;
    display: block;
    content: "Browse";
    height: 2.5rem;
    padding: .5rem 1rem;
    line-height: 1.5;
    color: #555;
    background-color: #eee;
    border: .075rem solid #ddd;
    border-radius: 0 .25rem .25rem 0;
}
.file-custom:after {
    content: "Choose file...";
}
.file input {
    min-width: 14rem;
    margin: 0;
    filter: alpha(opacity=0);
    opacity: 0;
}
.uploaded {
    
    img {
        display: block;
    max-width: 100%;
    width:100%;
    height: auto;
      
    }
   
}

.upload-btn-custom:hover:after {
    opacity: 1;
}
.upload-btn-custom:hover .options-container {
    transform: translate(-50%, -50%);
    transition-timing-function: ease;
}
.upload-btn-custom .options-container {
    position: absolute;
    z-index: 2;
    top: 50%;
    left: 50%;
    transform: translate(-2000px, -50%);
    color: #fff;
    transition: .8s;
    transition-timing-function: ease-in;
}
.upload-btn-custom:after {
    content: '';
    position: absolute;
    z-index: 1;
    height: 99%;
    width: 100%;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    opacity: 0;
    -webkit-transition: all 0.4s ease-in-out 0s;
    -moz-transition: all 0.4s ease-in-out 0s;
    transition: all 0.4s ease-in-out 0s;
    background: rgba(0, 0, 0, 0.7);
}
// .content-overlay {
//     background: rgba(0, 0, 0, 0.7);
//     position: absolute;
//     height: 99%;
//     width: 100%;
//     left: 0;
//     top: 0;
//     bottom: 0;
//     right: 0;
//     opacity: 0;
//     -webkit-transition: all 0.4s ease-in-out 0s;
//     -moz-transition: all 0.4s ease-in-out 0s;
//     transition: all 0.4s ease-in-out 0s;
// }
// .upload-btn-custom:hover .content-overlay {
//     opacity: 1;
// }
.upload-btn-custom {
    position: relative;
    overflow: hidden;
    cursor: pointer;
    margin: 0 15px;
    box-shadow: 0 0 25px 1px rgb(0 0 0 / 30%);
    transition: .5s;
    background-color: #555;
    float: left;
    width: 100%;
}
.usertable .mat-column-email {
    word-wrap: break-word !important;
    white-space: unset !important;
    flex: 0 0 20% !important;
    width: 20% !important;
    overflow-wrap: break-word;
    word-wrap: break-word;
    word-break: break-word;
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;
  }
  .usertable .mat-column-rolename {
    word-wrap: break-word !important;
    white-space: unset !important;
    flex: 0 0 10% !important;
    width: 10% !important;
    overflow-wrap: break-word;
    word-wrap: break-word;
    word-break: break-word;
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;
  }
  .usertable .mat-column-locationName{
    word-wrap: break-word !important;
    white-space: unset !important;
    flex: 0 0 20% !important;
    width: 20% !important;
    overflow-wrap: break-word;
    word-wrap: break-word;
    word-break: break-word;
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;
  }
  .usertable .mat-column-contactNumber{
    word-wrap: break-word !important;
    white-space: unset !important;
    flex: 0 0 15% !important;
    width: 15% !important;
    overflow-wrap: break-word;
    word-wrap: break-word;
    word-break: break-word;
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;
  }
  .module-list-sec {
    display: inline-block;
    margin-right: 10px;
    width: 155px;
    margin-bottom: 5px;
}
.module-list {
    background: #efefef;
    padding: 15px;
    border-radius: 5px;
    margin-bottom: 10px;
}
.module-content-name {
    font-weight: 500;
    font-size: 15px;
    color: #24acb2;
}
// .module-perm-list {
//     padding: 10px;
//     border-bottom: 2px solid #ccc;
//     margin-bottom: 10px;
// }
.module-perm-list {
    padding: 10px;
    border-bottom: 2px solid #ccc;
    margin-bottom: 10px;
    background: #efefef;
    margin-top: 10px;
}
.module-list-sec-perm {
    display: inline-block;
    margin-right: 10px;
    width: 180px;
}
//   .roles-page{
//     .btn-light:hover {
//         background: #173a64;
//         color: #fff;
//     }
//     .btn-white:hover {
//         color: #173a64;
//         background: #f1faff;
//     }
//    .card {
//         position: relative;
//         display: flex;
//         flex-direction: column;
//         min-width: 0;
//         word-wrap: break-word;
//         background-color: #ffffff;
//         background-clip: border-box;
//         border-radius: 0.25rem;
//         box-shadow: 9px 9px 20px 9px #d7d7d7;
//         border: none;
//         margin-bottom: 10px;
//     }
//     .card-footer {
//         border: 1px solid #efefef;
//         background: #ffffff;
//         padding-top: 10px !important;
//     }
//     .btn-light {
//         color: #7e8299;
//         border-color: #f5f8fa;
//         background-color: #f5f8fa;
//         font-weight: 400;
//         font-size: 14px;
//         padding: 7px 25px;
//     }
//     .btn-white {
//         font-weight: 400;
//         color: #7e8299;
//         font-size: 14px;
//         padding: 7px 25px;
//     }
// .card-header {
//     display: flex;
//     justify-content: space-between;
//     align-items: stretch;
//     flex-wrap: wrap;
//     min-height: 70px;
//     background-color: transparent;
//     border-bottom: 0;
// }
// .card-title {
//     font-weight: 500;
//     font-size: 1.275rem;
//     color: #181c32;
//     display: flex;
//     align-items: center;
//     margin: .5rem;
//     margin-left: 0;
// }
// .card-title h2 {
//     font-size: 16px;
//     font-weight: 600;
// }
// .text-gray-600 {
//     color: #7e8299!important;
// }
//  .fw-bolder {
//     font-weight: 600 !important;
//     font-size: 13px;
// }
// .bullet {
//     display: inline-block;
//     background-color: #00a3ff;
//     border-radius: 6px;
//     width: 8px;
//     height: 4px;
//     flex-shrink: 0;
//     margin-right: 10px;
// }
// .sub-list {
//     font-size: 14px;
//     font-weight: 400;
//     color: #000000 !important;
// }
//   }

.table-roles .mat-column-sno {
	word-wrap: break-word !important;
	white-space: unset !important;
	flex: 0 0 10% !important;
	width: 10% !important;
	overflow-wrap: break-word;
	word-wrap: break-word;
	word-break: break-word;
	-ms-hyphens: auto;
	-moz-hyphens: auto;
	-webkit-hyphens: auto;
	hyphens: auto;
}
.table-roles .mat-column-rolename {
	word-wrap: break-word !important;
	white-space: unset !important;
	flex: 0 0 50% !important;
	width: 50% !important;
	overflow-wrap: break-word;
	word-wrap: break-word;
	word-break: break-word;
	-ms-hyphens: auto;
	-moz-hyphens: auto;
	-webkit-hyphens: auto;
	hyphens: auto;
}
.table-roles .mat-column-actions {
	word-wrap: break-word !important;
	white-space: unset !important;
	flex: 0 0 20% !important;
	width: 20% !important;
	overflow-wrap: break-word;
	word-wrap: break-word;
	word-break: break-word;
	-ms-hyphens: auto;
	-moz-hyphens: auto;
	-webkit-hyphens: auto;
	hyphens: auto;
}

.table-roles .fa-toogle {
    font-size: 1.5em;
}
.zoom {
    transition: transform .2s; /* Animation */
  }
  .zoom:hover {
    transform: scale(1.5); 
  }
  .new-design-import {
    background: #f9f9f9;
    padding: 30px;
    float: left;
    width: 100%;
    margin-bottom: 20px;
}
.new-design-import i.fas.fa-file-download {
    font-size: 30px;
    margin-bottom: 11px;
    color:#458bd6;
}
.new-design-import h6 {
    color: #000;
    font-size: 16px;
    text-transform: capitalize;
}
.new-design-import .upload-btn-wrapper {
    position: relative;
    overflow: hidden;
    display: inline-block;
  }
  
  .new-design-import .btn {
    border: 2px solid #3bbd9b;
    color: #fff;
    background-color: #3bbd9b;
    padding: 4px 14px;
    border-radius: 8px;
    font-size: 16px;
    font-weight: 400;
    cursor: pointer;
  }
  
  .new-design-import .upload-btn-wrapper input[type=file] {
    font-size: 100px;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    width: 100%;
    height: 100%;
  }
  .sub-text-import {
    color: #c3c3c3;
}
.sample-btn {
    float: left;
    width: 100%;
    font-weight: 500;
}
.webix_scroll_cont {
    width: 100%;
    height: auto;
    clear: both;
    position: relative;
    background: #fff;
    padding: 10px;
    margin-top: 10px;
    display: inline-block;
}
.webix_list_item {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    padding: 2px 12px;
    line-height: 31px;
    cursor: pointer;
    text-align: left;
    width: 270px;
    height: 36px;
    overflow: hidden;
    border: 1px solid #efefef;
    margin-right: 5px;
    background: #efefef;
    float: left;
    margin-bottom: 5px;
}
.webix_remove_upload {
    float: right;
}
.block-type-design {
    margin-top: -7px;
}
.head-setting {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 10px;
    color: #173a64;
}
.module-perm-list .mat-checkbox-label {
    font-size: 14px;
    font-weight: 400;
}
.mat-datepicker-content .mat-calendar {
   
    height: auto !important;
}
.lead-heading {
    text-align: center;
    font-size: 15px;
    color: #173a64;
    font-weight: 600;
    text-transform: uppercase;
    margin-bottom: 7px;
}
.permis-heading {
    padding: 8px;
    border-bottom: 1px solid #ccc;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 5px;
    color: #173a64;
    background: #efefef;
}
.expand-table {
    display: none;
    transition: opacity 200ms linear, max-height 200ms linear;
    will-change: opacity, max-height;
}
.permis-heading i {
    float: right;
    margin-right: 12px;
    margin-top: 5px;
}
.permission-container.active .permis-heading i {
    float: right;
    margin-right: 12px;
    margin-top: 5px;
    transform: rotate(180deg);
}
.permission-container.active .expand-table {
    display: block;
    transition: all 200ms linear;
    will-change: opacity, max-height;
}
/*profile-page*/
.profile-page h4.card-title {
    position: relative;
    font-weight: 400;
    margin-bottom: 0.75rem;
    line-height: 22px;
    font-size: 18px;
}
.profile-page h6.card-subtitle {
    font-weight: 300;
    margin-bottom: 10px;
    color: #90a4ae;
}
.custom-files {
    border: 1px solid #173a64;
    width: 155px;
    color: #173a64;
    border-radius: 5px;
    height: 33px;
    font-size: 11px;
    line-height: 30px;
    font-weight: 600;
    cursor: pointer;
}
.custom-files:hover {
    background-color: #173a64;
    color:#fff;
}
.doc_table .mat-header-cell {
    font-size: 13px;
    font-weight: 600;
    color: #000;
    font-family: 'Poppins', sans-serif;
}
.doc_table .mat-cell{
    font-size: 13px;
    font-family: 'Poppins', sans-serif;
}
.doc_table .mat-cell a {
    color: #007bff !important;
    cursor: pointer;
}
.doc_patient .mat-tab-label-content {
    font-size: 11px;
}
.doc_patient .mat-tab-label {
    padding: 0 14px !important;
}