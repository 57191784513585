.main-content {
	background: $Alabaster;
	padding: 20px;
	padding-top: 10px;
}
.head-section {
	text-transform: uppercase;
	font-size: 18px;
	margin-top: 20px;
	color: $primary-clr;
	font-weight: 700;
}
.insurace-btn{
	background: #173a64;
    padding: 6px 10px;
	margin: 0px;
}
.insurance-prmy{
	border: 1px solid #f3f7fa !important;
}
.insurance-text{
	text-align: center;
    color: white;
    top: 7px;
}
.insurance-btn-back{
	float: right;
}
.auth-head{
	margin-top: 2%;
}
.add-auth{
	z-index: 2;
}
.lead-box {
	padding: 20px 10px;
	background: $white;
	border-radius: 5px;
	height: auto;
	margin-bottom: 10px;
	.mat-form-field-appearance-legacy {
		.mat-form-field-label {
			color: $Manatee;
			font-weight: 400;
			font-size: 15px;
			line-height: 20px;
		}
	}
	.mat-checkbox-label {
		color: $Manatee;
		font-weight: 400;
		font-size: 15px;
	}
}
.box-lead-head {
	color: $DoveGray;
	text-transform: uppercase;
	font-weight: 500;
	// border-bottom: 1px solid $SilverChalice;
	padding-bottom: 10px;
}
.insurance-head{
	color: #101010;
    text-transform: uppercase;
    font-weight: 600;
    padding-bottom: 10px;
    font-size: 20px;
}
.check-custom {
	.mat-checkbox-checked {
		.mat-checkbox-checkmark {
			opacity: 0;
		}
	}
	.mat-checkbox-indeterminate.mat-accent {
		.mat-checkbox-background {
			background-color: $primary-clr;
			border: 1px solid $white;
			outline: 1px groove;
		}
	}
	.mat-checkbox-checked.mat-accent {
		.mat-checkbox-background {
			background-color: $primary-clr;
			border: 1px solid $white;
			outline: 1px groove;
		}
	}
}
.mat-checkbox-checked {
	&:not(.mat-checkbox-disabled).mat-accent {
		.mat-ripple-element {
			background: $primary-clr;
		}
	}
}
.mat-checkbox {
	&:active {
		&:not(.mat-checkbox-disabled).mat-accent {
			.mat-ripple-element {
				background: $primary-clr;
			}
		}
	}
}
.table-expired{
	.mat-cell {
		color: red($color: #000000) !important;
	}
}
.table-custom {
	.mat-column-position {
		word-wrap: break-word !important;
		white-space: unset !important;
		flex: 0 0 8% !important;
		width: 70px !important;
		overflow-wrap: break-word;
		word-wrap: break-word;
		word-break: break-word;
		-ms-hyphens: auto;
		-moz-hyphens: auto;
		-webkit-hyphens: auto;
		hyphens: auto;
	}
	.mat-column-actions {
		word-wrap: break-word !important;
		white-space: unset !important;
		flex: 0 0 15% !important;
		width: 160px  !important;
		overflow-wrap: break-word;
		word-wrap: break-word;
		word-break: break-word;
		-ms-hyphens: auto;
		-moz-hyphens: auto;
		-webkit-hyphens: auto;
		hyphens: auto;
	}
	.mat-header-cell {
		color: $white;
		background: $Manatee;
		font-weight: 400;
    	font-size: 12px;
    	padding: 10px;
	}
	.mat-cell {
		color: $DoveGray;
		font-size: 14px;
		font-weight: 400;
		padding: 10px;
	}
	.mat-row {
		border: none !important;
	}
	.mat-table {
		min-width: 800px;
	}
}
.mat-paginator-container {
	border-top: 1px solid $Gallery;
}

.top-btn-section{
    display: flex;
}
.mat-form-field.mat-focused .mat-form-field-ripple{
	background: $BreakerBay;
}
@media only screen and (max-width: 600px) {
    .top-btn-section {
        display: unset;
        text-align: center;
    }
  }
  .leadinfo {
    white-space: pre-line;
  }
  .status-msg{
	  color: $Tradewind;
	  font-size: 12px;
  }
  .status-msg span{
	color: $Cardinal;
	font-size: 12px;
}
.email-compose h4 {
    font-size: 14px;
}
.email-compose .close {
    color: $white;
    opacity: 1;
}
.email-compose .modal-header {
    background: $primary-clr;
    color: $white;
	padding: 10px;
	box-shadow: 0px 0px 4px #807d7d;
}
.email-compose .fa-angle-down{
    font-weight: 600;
    font-size: 20px;
    padding-right: 10px;
    vertical-align: middle;
}
.email-compose .fa-times {
    font-size: 16px;
}
.gmail-txt{
	padding-bottom: 8px;
    padding-top: 8px;
    border: none;
    -webkit-box-shadow: inset 0 -1px 0 0 rgb(100 121 143 / 12%);
    box-shadow: inset 0 -1px 0 0 rgb(100 121 143 / 12%);
    line-height: 20px;
    min-height: auto;
    padding-left: 0;
    padding-right: 0;
	border-radius: 0;
	font-weight: normal;
    font-size: 13px;
    color: #212529;
    margin-top: 4px;
}
.to{
	padding-left: 25px;
}
.to-label{
	position: absolute;
    top: 9px;
    font-size: 13px;
}
.subject{
	padding-left: 175px;
	padding-top:2px !important ;
}
.subject-without {
    padding-left: 54px !important;
	padding-top:5px !important ;
}
.from{
	padding-left: 40px;
}
quill-editor{
	display: inline-block;
    // height: 150px;
    width: 100%;
}
.mail-modal .modal-dialog {
	max-width: 450px;
	margin: unset;
	right: 0;
	bottom: 0;
	position: absolute;
}
.fileinput-button {
    float: left;
    margin-right: 4px;
    overflow: hidden;
    position: relative;
}
.fileinput-button {
    background: none repeat scroll 0 0 #eeeeee;
    border: 1px solid #e6e6e6;
	color: $primary-clr;
	height: 40px;
    padding: 0 15px;
    border-radius: 0;
    line-height: 35px;
}
.fileinput-button input {
    cursor: pointer;
    direction: ltr;
    font-size: 23px;
    margin: 0;
    opacity: 0;
    position: absolute;
    right: 0;
    top: 0;
    transform: translate(-300px, 0px) scale(4);
}
.btn-send, .btn-send:hover {
    background: $primary-clr;
    color: $white;
	border-radius: 0;
    padding: 0 15px;
    height: 40px;
}
/* Chrome, Safari, Edge, Opera */
.custom-input input::-webkit-outer-spin-button,
.custom-input input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
 
/* Firefox */
.custom-input input[type=number] {
  -moz-appearance: textfield;
}

.custom-input.mat-form-field.mat-focused .mat-form-field-label {
    top: 48px !important;
    color: rgba(0,0,0,.6);
    font-size: 20px;
}
.custom-input.mat-form-field-should-float .mat-form-field-label {
    top: 48px !important;
    color: rgba(0,0,0,.6);
    font-size: 20px;
}
.custom-input .mat-chip-list-wrapper input.mat-input-element:first-child{
    padding-left: 25px;
}
mat-chip.mat-chip.mat-focus-indicator.mat-primary.mat-standard-chip.mat-chip-selected.mat-chip-with-trailing-icon {
    
    background: $AquaSqueeze;
    border: 1px solid $Nevada;
    color: $Nevada;
    border-radius: 0;
    font-size: 10px;
    padding: 5px;
	height: 24px;
    min-height: 24px;
}
mat-chip.mat-chip.mat-focus-indicator.mat-primary.mat-standard-chip.mat-chip-selected.mat-chip-with-trailing-icon:first-child{
	margin-left: 30px;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-chip-remove {
    color: $Nevada;
    opacity: .4;
}
.to-subject {
    position: absolute;
    left: 74px;
    top: 7px;
    font-size: 14px;
    font-weight: 600;
    color: #000;
}
.attachment-sec {
    display: inline-block;
    width: 100%;
    margin-bottom: 30px !important;
}
.attachment-sec mat-chip.mat-chip.mat-focus-indicator.mat-primary.mat-standard-chip.mat-chip-selected.mat-chip-with-trailing-icon:first-child {
    margin-left: 5px;
	
}
.attachname {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: pre;
    width: 95px;
	color: $Nevada;
}
.date-custom.widthadjust--buttonalign {
    width: 26%;
}
.attachmentsection--update .attachment-sec {
	margin-bottom: 10px !important;
}
.attachment--update.attachment-sec {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
    float: left;
    width: auto;
    margin-right: 5px;
}
a.disabled {
    color: gray;
    cursor: not-allowed;
    text-decoration: underline;
	pointer-events: none;
}
.btn-disable {
    border-color: #efefef !important;
    color: #cec8c8 !important;
    cursor: default !important;
}
.upload-table {
    font-size: 12px;
	thead th {
		border-top: none !important;
	}
}
.upload-btn-wrapper {
	position: relative;
	overflow: hidden;
	display: inline-block;
  }
  .upload-btn-wrapper input[type=file] {
	font-size: 100px;
	position: absolute;
	left: 0;
	top: 0;
	opacity: 0;
  }
  .text-control {
    white-space: pre-line;
    word-break: break-all;
}
.summary-count label {
    margin-right: 10px;
    color: $primary-clr;
}
.task-status {
    font-size: 12px;
    padding: 5px;
    border: 1px solid transparent;
    border-radius: 5px;
    color: $white;
    width: auto;
    text-align: center;
}
.overdue{
	background-color: $Cardinal;
}
.due{
	background-color: #f0ad4e;
}
.today{
	background-color: $ChateauGreen;
}
.mat-select-panel .mat-option{
height: 2em !important;
}
.mat-paginator-page-size-select {
    width: 47px !important;
}
.mat-paginator-page-size .mat-select-trigger {
    text-align: center;
}
app-esign-dialog canvas {
    border: 1px solid #ccc;
	width: 100%;
	height: 150px;
}
app-esign-dialog .esign-clear {
    border: 1px solid #102744;
    color: #102744;
}
app-esign-dialog .esign-clear:hover {
    background-color: #102744;
    color: #ffffff;
}
.e-sign_tabs {
    width: 600px;
}
.e-sign_tabs .mat-tab-labels {
    background: #f5f5f5;
}
.e-sign_tabs .mat-tab-label-active {
    background: transparent;
    color: $primary-clr;
    margin-right: 5px;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    opacity: 1 !important;
}
.e-sign_tabs .mat-ink-bar {
    height: 4px !important;
    background: #173a64 !important;
}
.e-sign_tabs .mat-tab-body-content {
    border: 1px solid #efefef;

}
.signature-padnew .mat-form-field-infix {
    background: #ecf5ff;
}
.e-sign_tabs .radio-label {
    display: block;
    margin: 10px 0;
    border: 1px solid #efefef;
	background: #f7f7f7;
    padding: 10px 0;
}
.e-sign_tabs .radio-label input {
    opacity: 0;
    position: absolute;
}
.e-sign_tabs .radio-label .inner-label {
    position: relative;
    display: inline-block;
    padding-left: 30px;
}
.e-sign_tabs .radio-label .inner-label:before {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 30px;
    transition: border-bottom 0.5s ease;
}
.e-sign_tabs .radio-label input:checked ~ .inner-label:after {
    content: "✓";
    color: rgb(29, 221, 157);
    position: absolute;
    font-size: 18px;
    left: 12px;
    top: -3px;
}
.e-sign_tabs{
	.files input {
		outline: 2px dashed #92b0b3;
		outline-offset: -10px;
		-webkit-transition: outline-offset .15s ease-in-out, background-color .15s linear;
		transition: outline-offset .15s ease-in-out, background-color .15s linear;
		padding: 120px 0px 85px 35%;
		text-align: center !important;
		margin: 0;
		width: 100% !important;
	}
	.files input:focus{     outline: 2px dashed #92b0b3;  outline-offset: -10px;
		-webkit-transition: outline-offset .15s ease-in-out, background-color .15s linear;
		transition: outline-offset .15s ease-in-out, background-color .15s linear; border:1px solid #92b0b3;
	 }
	.files{ position:relative}
	.files:after {  pointer-events: none;
		position: absolute;
		top: 60px;
		left: 0;
		width: 80px;
		right: 0;
		height: 86px;
		content: "";
		background-image: url(../../../assets/images/upload.png);
		display: block;
		margin: 0 auto;
		background-size: 100% 100%;
		background-repeat: no-repeat;
	}
	.color input{ background-color:#f1f1f1;}
	.files:before {
		position: absolute;
		bottom: -14px;
		left: 0;  pointer-events: none;
		width: 100%;
		right: 0;
		height: 57px;
		content: " or drag it here. ";
		display: block;
		margin: 0 auto;
		color: #2ea591;
		font-weight: 600;
		text-transform: capitalize;
		text-align: center;
	}
}
.clear-btn {
    background: #4d96e2 !important;
    color: #fff !important;
    border: 2px solid #4d96e2 !important;
}
.tablemaster .fa-toogle {
    font-size: 1.5em;
}
.insurance-holder {
    font-size: 14px;
    margin-bottom: 10px;
    font-weight: 500;
    color: #66666b;
}
.task-comment-container{
    width: 920px;
}
.task-comment {
    max-height: 300px;
    position: relative;
    padding: 10px;
    overflow-x: hidden;
    overflow-y: auto;
}
.libraryImages-container .close {
    outline: none;
}
a.icon-note {
    color: #555;
}
.error{
	color:#f44336 !important;
}
.attached-btn {
    background: #fff;
    border: 2px solid;
    border-radius: 3px;
    font-weight: 500;
    font-size: 12px !important;
    padding: 8px;
    height: 30px;
    line-height: 6px;
}
.mat-chip-attach .attachname {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: pre;
    width: 175px;
    color: #686F71;
}
.btn-resubmit {
    text-transform: uppercase;
    border: 2px solid #173a64;
    color: #173a64;
    font-size: 14px;
    vertical-align: middle;
}
.edit-contact-city {
    padding-left: 4px;
    color: #555;
    font-size: 13px;
}