.custom-head h2::after {
    content: "";
    position: relative;
    display: block;
    width: 30px;
    height: 3px;
    background: #173a64;
    margin-top: 5px;
    left: 1px;
}
.custom-head h2 {
    color: #173a64;
    font-size: 15px;
}
.discharge-list .search {
    background-color: #f7f7f7;
}
.discharge-list label {
    font-size: 12px;
    font-weight: 600;
}
.discharge-list .status-filter {
    width: 100px !important;
    font-size: 12px;
    margin-right: 5px;
}
a.discharge-btn {
    color: #555;
}
.dateicon-hide .mat-button-wrapper {
    display: none;
}

