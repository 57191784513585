.app-list {
  align-items: center;
  border-radius: 10px;
  vertical-align: middle;
  padding: 10px;
  margin-bottom: 10px;
  background: #f9f9f9;
}
.app-list img {
  margin: 0px 10px;
}
.app-list .set-flex {
  flex: 1;
}
.btn-tbl-confirm {
  background-color: #3fa3f3;
  height: 30px !important;
  width: 30px !important;
  margin: 2px !important;
  line-height: 24px !important;
  color: #fff;
}
.btn-tbl-reject {
  background-color: #f96333;
  height: 30px !important;
  width: 30px !important;
  margin: 2px !important;
  line-height: 24px !important;
  color: #fff;
}
.btn-tbl-reject .material-icons {
  font-size: 16px !important;
}
.btn-tbl-confirm .material-icons {
  font-size: 16px !important;
}
.appointment-list-upcome .tab-card-header > .nav-tabs > li > a {
  padding: 10px 7px;
}
.plan_left {
  margin-right: 10px;
  padding: 5px 10px;
  border: 1px solid #ededed;
  border-radius: 5px;
}
.plan_left .thumb {
  width: 45px;
  height: 45px;
  border-radius: 0.42rem;
  margin-right: 10px;
  padding: 12px;
  background: #d3e1f7;
  line-height: 1;
}
.plan_left .thumb i {
  font-size: 21px;
  color: #173a64;
}
.plan_left h5 {
  font-size: 16px;
  font-weight: 700;
  color: #474D58;
  margin-bottom: 0;
}
.plan_left span {
  font-size: 12px !important;
  color: #173a64 !important;
  font-weight: 600;
  line-height: 12px;
  margin-top: 5px;
}
.appointment-list-upcome .list-unstyled {
  max-height: 390px;
  overflow-x: hidden;
  margin-bottom: 5px;
  height: 390px;
  overflow-y: auto;
}
.medic-list .table-responsive {
  max-height: 328px;
  overflow: auto;
  margin-bottom: 5px;
  height: 328px;
}
.app-list .set-flex {
  margin-right: 5px;
  font-size: 13px;
  flex: 1;
}
.user-info-p {
  display: flex;
  align-items: center;
}
.user-info__img img {
  margin-right: 15px;
  height: 55px;
  width: 55px;
  border-radius: 45px;
  border: 3px solid #fff;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}
.profile-history-log .profile-image img {
  border-radius: 50%;
  width: 80px;
  // border: 1px solid #ccc;
  // box-shadow: 0 3px 6px rgb(0 0 0 / 16%), 0 3px 6px rgb(0 0 0 / 23%);
}
.profile-history-log h4 strong {
  font-size: 15px;
  color: #173a64;
  font-weight: 600;
  text-transform: uppercase;
  margin-bottom:7px;
}
.profile-history-log .sub-text-grey-small {
  font-size: 13px;
}
li.activity-list {
  border-left: 2px solid #17a2b8;
  padding: 10px;
  background: #eefafd;
  margin-bottom: 3px;
}
.profile-history-log {
  border-bottom: 1px solid #ebebeb;
}
/**/
.vertical-timeline {
  width: 100%;
  position: relative;
  padding: 1.5rem 0 1rem
}

.vertical-timeline::before {
  content: '';
  position: absolute;
  top: 0;
  left: 67px;
  height: 100%;
  width: 4px;
  background: #e9ecef;
  border-radius: .25rem
}

.vertical-timeline-element {
  position: relative;
  margin: 0 0 1rem
}

.vertical-timeline--animate .vertical-timeline-element-icon.bounce-in {
  visibility: visible;
  animation: cd-bounce-1 .8s
}

.vertical-timeline-element-icon {
  position: absolute;
  top: 0;
  left: 60px
}

.vertical-timeline-element-icon .badge-dot-xl {
  box-shadow: 0 0 0 5px #fff
}

.badge-dot-xl {
  width: 18px;
  height: 18px;
  position: relative
}

.badge:empty {
  display: none
}

.badge-dot-xl::before {
  content: '';
  width: 10px;
  height: 10px;
  border-radius: .25rem;
  position: absolute;
  left: 50%;
  top: 50%;
  margin: -5px 0 0 -5px;
  background: #fff
}

.vertical-timeline-element-content {
  position: relative;
  margin-left: 90px;
  font-size: .8rem
}

.vertical-timeline-element-content .timeline-title {
  font-size: .8rem;
  text-transform: uppercase;
  margin: 0 0 .5rem;
  padding: 2px 0 0;
  font-weight: bold
}

.vertical-timeline-element-content .vertical-timeline-element-date {
  display: block;
  position: absolute;
  left: -90px;
  top: 0;
  padding-right: 10px;
  text-align: right;
  color: #adb5bd;
  font-size: .7619rem;
  white-space: nowrap
}

.vertical-timeline-element-content:after {
  content: "";
  display: table;
  clear: both
}
.date-schedule-current {
  font-size: 13px;
  text-align: center;
  background: #efefef;
  padding: 4px 28px;
  margin: 0 auto;
  display: table;
  border-radius: 15px;
}
.single-timeline-content {
  position: relative;
  z-index: 1;
  padding: 8px 8px 8px;
  border-radius: 6px;
  // margin-bottom: 15px;
  -webkit-box-shadow: 0 0.25rem 1rem 0 rgb(47 91 234 / 13%);
  box-shadow: 0 0.25rem 1rem 0 rgb(47 91 234 / 13%);
  border: 1px solid #ebebeb;
}
.main-card {
  max-height: 401px;
  overflow: auto;
  overflow-x: hidden;
}
a.timeline-btn {
  color: #1d4f8b;
  font-weight: 500;
  font-size: 12px;
  background: #fff;
  border: 1px solid;
  padding: 1px 7px;
  border-radius: 2px;
}
.timeline-text{
  width:100%;
}
/*slider*/ 
.cd-horizontal-timeline {
  opacity: 0;
  margin: 2em auto;
  -webkit-transition: opacity 0.2s;
  -moz-transition: opacity 0.2s;
  transition: opacity 0.2s;
}
.cd-horizontal-timeline::before {
  /* never visible - this is used in jQuery to check the current MQ */
  content: 'mobile';
  display: none;
}
.cd-horizontal-timeline.loaded {
  /* show the timeline after events position has been set (using JavaScript) */
  opacity: 1;
}
.cd-horizontal-timeline .timeline {
  position: relative;
  height: 100px;
  width: 90%;
  max-width: 800px;
  margin: 0 auto;
}
.cd-horizontal-timeline .events-wrapper {
  position: relative;
  height: 100%;
  margin: 0 40px;
  overflow: hidden;
}
.cd-horizontal-timeline .events-wrapper::after, .cd-horizontal-timeline .events-wrapper::before {
  /* these are used to create a shadow effect at the sides of the timeline */
  content: '';
  position: absolute;
  z-index: 2;
  top: 0;
  height: 100%;
  width: 20px;
}
.cd-horizontal-timeline .events-wrapper::before {
  left: 0;
  background-image: -webkit-linear-gradient( left , #f8f8f8, rgba(248, 248, 248, 0));
  background-image: linear-gradient(to right, #f8f8f8, rgba(248, 248, 248, 0));
}
.cd-horizontal-timeline .events-wrapper::after {
  right: 0;
  background-image: -webkit-linear-gradient( right , #f8f8f8, rgba(248, 248, 248, 0));
  background-image: linear-gradient(to left, #f8f8f8, rgba(248, 248, 248, 0));
}
.cd-horizontal-timeline .events {
  /* this is the grey line/timeline */
  position: absolute;
  z-index: 1;
  left: 0;
  top: 49px;
  height: 2px;
  /* width will be set using JavaScript */
  background: #dfdfdf;
  -webkit-transition: -webkit-transform 0.4s;
  -moz-transition: -moz-transform 0.4s;
  transition: transform 0.4s;
}
.cd-horizontal-timeline .filling-line {
  /* this is used to create the green line filling the timeline */
  position: absolute;
  z-index: 1;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: #7b9d6f;
  -webkit-transform: scaleX(0);
  -moz-transform: scaleX(0);
  -ms-transform: scaleX(0);
  -o-transform: scaleX(0);
  transform: scaleX(0);
  -webkit-transform-origin: left center;
  -moz-transform-origin: left center;
  -ms-transform-origin: left center;
  -o-transform-origin: left center;
  transform-origin: left center;
  -webkit-transition: -webkit-transform 0.3s;
  -moz-transition: -moz-transform 0.3s;
  transition: transform 0.3s;
}
.cd-horizontal-timeline .events a {
  position: absolute;
  bottom: 0;
  z-index: 2;
  text-align: center;
  font-size: 1.3rem;
  padding-bottom: 15px;
  color: #383838;
  /* fix bug on Safari - text flickering while timeline translates */
  -webkit-transform: translateZ(0);
  -moz-transform: translateZ(0);
  -ms-transform: translateZ(0);
  -o-transform: translateZ(0);
  transform: translateZ(0);
}
.cd-horizontal-timeline .events a::after {
  /* this is used to create the event spot */
  content: '';
  position: absolute;
  left: 50%;
  right: auto;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  transform: translateX(-50%);
  bottom: -5px;
  height: 12px;
  width: 12px;
  border-radius: 50%;
  border: 2px solid #dfdfdf;
  background-color: #f8f8f8;
  -webkit-transition: background-color 0.3s, border-color 0.3s;
  -moz-transition: background-color 0.3s, border-color 0.3s;
  transition: background-color 0.3s, border-color 0.3s;
}
.no-touch .cd-horizontal-timeline .events a:hover::after {
  background-color: #7b9d6f;
  border-color: #7b9d6f;
}
.cd-horizontal-timeline .events a.selected {
  pointer-events: none;
}
.cd-horizontal-timeline .events a.selected::after {
  background-color: #7b9d6f;
  border-color: #7b9d6f;
}
.cd-horizontal-timeline .events a.older-event::after {
  border-color: #7b9d6f;
}
@media only screen and (min-width: 1100px) {
  .cd-horizontal-timeline {
    margin: 6em auto;
  }
  .cd-horizontal-timeline::before {
    /* never visible - this is used in jQuery to check the current MQ */
    content: 'desktop';
  }
}

.cd-timeline-navigation a {
  /* these are the left/right arrows to navigate the timeline */
  position: absolute;
  z-index: 1;
  top: 50%;
  bottom: auto;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  height: 34px;
  width: 34px;
  border-radius: 50%;
  border: 2px solid #dfdfdf;
  /* replace text with an icon */
  overflow: hidden;
  color: transparent;
  text-indent: 100%;
  white-space: nowrap;
  -webkit-transition: border-color 0.3s;
  -moz-transition: border-color 0.3s;
  transition: border-color 0.3s;
}
.cd-timeline-navigation a::after {
  /* arrow icon */
  content: '';
  position: absolute;
  height: 16px;
  width: 16px;
  left: 50%;
  top: 50%;
  bottom: auto;
  right: auto;
  -webkit-transform: translateX(-50%) translateY(-50%);
  -moz-transform: translateX(-50%) translateY(-50%);
  -ms-transform: translateX(-50%) translateY(-50%);
  -o-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
  // background: url(../img/cd-arrow.svg) no-repeat 0 0;
}
.cd-timeline-navigation a.prev {
  left: 0;
  -webkit-transform: translateY(-50%) rotate(180deg);
  -moz-transform: translateY(-50%) rotate(180deg);
  -ms-transform: translateY(-50%) rotate(180deg);
  -o-transform: translateY(-50%) rotate(180deg);
  transform: translateY(-50%) rotate(180deg);
}
.cd-timeline-navigation a.next {
  right: 0;
}
.no-touch .cd-timeline-navigation a:hover {
  border-color: #7b9d6f;
}
.cd-timeline-navigation a.inactive {
  cursor: not-allowed;
}
.cd-timeline-navigation a.inactive::after {
  background-position: 0 -16px;
}
.no-touch .cd-timeline-navigation a.inactive:hover {
  border-color: #dfdfdf;
}

.cd-horizontal-timeline .events-content {
  position: relative;
  width: 100%;
  margin: 2em 0;
  overflow: hidden;
  -webkit-transition: height 0.4s;
  -moz-transition: height 0.4s;
  transition: height 0.4s;
}
.cd-horizontal-timeline .events-content li {
  position: absolute;
  z-index: 1;
  width: 100%;
  left: 0;
  top: 0;
  -webkit-transform: translateX(-100%);
  -moz-transform: translateX(-100%);
  -ms-transform: translateX(-100%);
  -o-transform: translateX(-100%);
  transform: translateX(-100%);
  padding: 0 5%;
  opacity: 0;
  -webkit-animation-duration: 0.4s;
  -moz-animation-duration: 0.4s;
  animation-duration: 0.4s;
  -webkit-animation-timing-function: ease-in-out;
  -moz-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
}
.cd-horizontal-timeline .events-content li.selected {
  /* visible event content */
  position: relative;
  z-index: 2;
  opacity: 1;
  -webkit-transform: translateX(0);
  -moz-transform: translateX(0);
  -ms-transform: translateX(0);
  -o-transform: translateX(0);
  transform: translateX(0);
}
.cd-horizontal-timeline .events-content li.enter-right, .cd-horizontal-timeline .events-content li.leave-right {
  -webkit-animation-name: cd-enter-right;
  -moz-animation-name: cd-enter-right;
  animation-name: cd-enter-right;
}
.cd-horizontal-timeline .events-content li.enter-left, .cd-horizontal-timeline .events-content li.leave-left {
  -webkit-animation-name: cd-enter-left;
  -moz-animation-name: cd-enter-left;
  animation-name: cd-enter-left;
}
.cd-horizontal-timeline .events-content li.leave-right, .cd-horizontal-timeline .events-content li.leave-left {
  -webkit-animation-direction: reverse;
  -moz-animation-direction: reverse;
  animation-direction: reverse;
}
.cd-horizontal-timeline .events-content li > * {
  max-width: 800px;
  margin: 0 auto;
}
.cd-horizontal-timeline .events-content h2 {
  font-weight: bold;
  font-size: 2.6rem;
  font-family: "Playfair Display", serif;
  font-weight: 700;
  line-height: 1.2;
}
.cd-horizontal-timeline .events-content em {
  display: block;
  font-style: italic;
  margin: 10px auto;
}
.cd-horizontal-timeline .events-content em::before {
  content: '- ';
}
.cd-horizontal-timeline .events-content p {
  font-size: 1.4rem;
  color: #959595;
}
.cd-horizontal-timeline .events-content em, .cd-horizontal-timeline .events-content p {
  line-height: 1.6;
}
@media only screen and (min-width: 768px) {
  .cd-horizontal-timeline .events-content h2 {
    font-size: 7rem;
  }
  .cd-horizontal-timeline .events-content em {
    font-size: 2rem;
  }
  .cd-horizontal-timeline .events-content p {
    font-size: 1.8rem;
  }
}

@-webkit-keyframes cd-enter-right {
  0% {
    opacity: 0;
    -webkit-transform: translateX(100%);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0%);
  }
}
@-moz-keyframes cd-enter-right {
  0% {
    opacity: 0;
    -moz-transform: translateX(100%);
  }
  100% {
    opacity: 1;
    -moz-transform: translateX(0%);
  }
}
@keyframes cd-enter-right {
  0% {
    opacity: 0;
    -webkit-transform: translateX(100%);
    -moz-transform: translateX(100%);
    -ms-transform: translateX(100%);
    -o-transform: translateX(100%);
    transform: translateX(100%);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0%);
    -moz-transform: translateX(0%);
    -ms-transform: translateX(0%);
    -o-transform: translateX(0%);
    transform: translateX(0%);
  }
}
@-webkit-keyframes cd-enter-left {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-100%);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0%);
  }
}
@-moz-keyframes cd-enter-left {
  0% {
    opacity: 0;
    -moz-transform: translateX(-100%);
  }
  100% {
    opacity: 1;
    -moz-transform: translateX(0%);
  }
}
@keyframes cd-enter-left {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-100%);
    -moz-transform: translateX(-100%);
    -ms-transform: translateX(-100%);
    -o-transform: translateX(-100%);
    transform: translateX(-100%);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0%);
    -moz-transform: translateX(0%);
    -ms-transform: translateX(0%);
    -o-transform: translateX(0%);
    transform: translateX(0%);
  }
}

span.info-small-timeline {
  float: left;
  color: #307aa5;
  font-size: 10px;
  font-weight: 500;
  margin-top: 2px;
  margin-bottom: 5px;
}
.badge-orange {
  color: #292821;
  background-color: #be731e;
}
.badge-blue-dark{
  color: #292821;
  background-color: #511ae7;
}
.detail-section a i {
  float: right;
  padding: 10px 5px 0px 0px;
}
.detail-section a p {
  display: inline-block;
  margin: 0px;
  font-size: 12px;
  padding: 10px;
}
.detail-section {
  cursor: pointer;
  border-radius: 5px;
  background: rgb(255 255 255);
}
.history-label {
  font-size: 11px;
  color: #555;
}
.history-value {
  font-size: 14px;
  color: #000;
  font-weight: 500;
}
.dashbord.intake-content {
  display: inline-block;
  width: 100%;
  border-radius: 3px;
  background: #f1faff;
  padding: 10px;
}
.dashbord.Chma-content{
  display: inline-block;
  width: 100%;
  border-radius: 3px;
  background: #fff1fd;
  padding: 10px;
}
.dashbord.mtp-content {
  display: inline-block;
  width: 100%;
  border-radius: 3px;
  background: #fff8dd;
  padding: 10px;
}
.icon-section i {
  font-size: 35px;
  padding: 5px;
  margin-bottom: 5px;
  color: #173a64;
}

.main-tp {
  font-size: 13px;
  padding-bottom: 5px;
  border-top: 1px solid #ccc;
  padding-top: 8px;
}
.treat-his{
    color: #666666;
    text-transform: uppercase;
    font-weight: 500;
    padding-bottom: 10px;
    font-size: 15px;
    padding: 10px 0;
    margin:0;
}
.table-history th {
  background: #9B9B9D;
  color: #fff;
  font-size: 12px;
  font-weight: 400;
  padding: 7px;
  text-align: center;
}
.table-history td {
  font-size: 14px;
  padding: 7px;
  text-align: center;
}
.table-history tbody {
  background: #fff;
}
.view-history i {
  font-size: 18px;
  color: #555;
}
.view-history i:hover {
  color: #000;
}
.view-history{
  cursor: pointer;
}
.gt-badge {
  background: #b1b127;
  padding: 2px 10px;
  border-radius: 5px;
  color: #fff;
  font-weight: 600;
  display: inline-block;
  width: 50px;
}
.it-badge {
  background: #18b4db;
  padding: 2px 10px;
  border-radius: 5px;
  color: #fff;
  font-weight: 600;
  display: inline-block;
  width: 50px;
}
.f-badge {
  background: #db18a8;
  padding: 2px 10px;
  border-radius: 5px;
  color: #fff;
  font-weight: 600;
  display: inline-block;
  width: 50px;
}
// .table-fixed thead {
//   width: 100%;
// }
// .table-fixed tbody {
//   height: 180px;
//   overflow-y: auto;
//   width: 100%;
// }
// .table-fixed thead, .table-fixed tbody, .table-fixed tr, .table-fixed td, .table-fixed th {
//   display: block;
// }
// .table-fixed tbody td, .table-fixed thead > tr> th {
//   float: left;
//     border-bottom-width: 0;
//     width: 178px;
//     height: 35px;
// }
.imp-time {
  font-size: 16px;
  color: #343638;
  font-weight: 600;
}
.imp-date {
    font-weight: 500;
    font-size: 13px;
}
.dr-name {
  font-weight: 600;
  color: #173a64;
}
.message-app {
  color: #173a64;
  font-weight: 500;
  display: inline-block;
  vertical-align: middle;
  font-size: 14px;
}
.medic-hist{
  background-color: #efefef;
}

.filter-patient-appoint label {
  font-weight: 500;
  color: #000;
}
.filter-patient-appoint {
  background: #efefef;
  margin: 5px;
  border-radius: 5px;
  width: 99%;
  padding-top: 7px;
  padding-bottom: 7px;
  text-align: right;
}
.appt-table .table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6;
  border-top: 0;
  font-size: 12px;
}
.appt-table .table td {
  font-size: 13px;
}
.stat-tp span{
  text-align: center;
}
.stat-tp {
  text-align: center;
}
.appt-table {
  height: 315px;
  overflow: auto;
}
.pt-badge {
  background: #18db80;
  padding: 2px 10px;
  border-radius: 5px;
  color: #fff;
  font-weight: 600;
  display: inline-block;
  width: 50px;
}
.pe-badge {
  background: #18dbc8;
  padding: 2px 10px;
  border-radius: 5px;
  color: #fff;
  font-weight: 600;
  display: inline-block;
  width: 50px;
}

.pm-badge {
  background: #18db69;
  padding: 2px 10px;
  border-radius: 5px;
  color: #fff;
  font-weight: 600;
  display: inline-block;
  width: 50px;
}
.select-small {
  height: 35px;
  line-height: 15px;
  font-size: 14px;
  padding: 0;
  width: 103px;
}
.status-patient {
  font-size: 13px;
  color: #1a7b7b;
  font-weight: 600;
}
.custom-btn-go .form-control {
  font-size: 13px;
  color: #212529;
  font-weight: 400;
  width: 150px !important;
  flex: unset;
}
.custom-btn-go .btn {
  font-size: 13px;
  background: #212529;
  border-color: #212529;
  color:#fff;
}
.custom-btn-go {
  width: 100%;
  float: left;
}
.custom-btn-go span {
  font-size: 13px;
  margin-right: 7px;
  vertical-align: middle;
  margin-top: 6px;
  margin-left: 5px;
  font-weight: 500;
  color: #173a64;
}
.status-patient-value {
  margin-left: 10px;
  margin-right: 5px;
  font-weight: 500;
  color: #173a64;
}
.edit-status {
  font-size: 12px;
  margin-top: 1px;
  margin-left: 5px;
}
.treat-summ-btn .custom-btn-go .form-control {
  font-size: 13px;
  color: #212529;
  font-weight: 400;
  width: 195px !important;
  flex: unset;
}
.location-schedule{
  text-align: center;
  font-size: 29px;
  margin: 0;
  color: #173a64;
}
.loc-sched-popup .modal {
  position: fixed;
  top: 127px;
  z-index: 1;
}
.loc-sched-popup .modal-dialog {
  top: 10%;
}
.rearange {
  color: #173a64;
  border: 1px solid #173a64;
  height: 37px;
  border-radius: 2px;
  padding: 0 7px;
}
.hint-msg {
  background: #faffb6;
  padding: 10px;
  border: 1px dotted;
  font-size: 17px;
  text-align: center;
  font-weight: 400;
  color: #555;
  margin-top: 30px;
}
.up-down-btn:hover {
  box-shadow: 0 2px 4px 2px #7e7e7e;
}
.up-down-btn {
  color: #fff;
  background: #173a64;
  border: none;
  padding: 2px 7px;
  border-radius: 3px;
}
.custom-border-date.mat-form-field-appearance-outline .mat-form-field-infix {
  padding: .375rem .75rem !important;
  top: -3px;
  position: relative;
}
.custom-border-date .mat-form-field-wrapper {
  padding: 0;
  margin: 0;
}
.custom-border-date svg {
  width: 18px !important;
}
.discharge-list .custom-border-date {
  width: 152px;
  font-size: 12px;
}
.invoice-section .custom-border-date.mat-form-field-appearance-outline .mat-form-field-infix {
  top: -10px;
}
.pc-badge {
  background: #1835db;
  padding: 2px 10px;
  border-radius: 5px;
  color: #fff;
  font-weight: 600;
  display: inline-block;
  width: 50px;
}
.sm-badge {
  background: #0b1348;
  padding: 2px 10px;
  border-radius: 5px;
  color: #fff;
  font-weight: 600;
  display: inline-block;
  width: 50px;
}
.modal-bg {
  background: rgb(38 38 38 / 50%);
}
.patient-info-label {
  color: #1a7b7b;
  font-weight: 500;
}
.patient-info-value {
  color: #173a64;
  padding-left: 5px;
  font-weight: 600;
}
.drive-wrapper {
  font-size: 14px;
}
/*documnet*/
.no-margin-top {
  margin-top: 19px;
  font-size: 15px;
  color: #173a64;
}
ul.folders li {
  padding: 3px 4px 3px 12px;
}
ul.folders li a {
  text-decoration: none;
  color: #4a4d56;
  font-size: 14px;
}
h6.doc-head {
  color: #555;
  border-bottom: 2px solid;
  padding-bottom: 14px;
  margin: 0;
}
ul.folders.patient-leftbar li a:hover {
  background: #efefef;
  cursor: pointer !important;
}
a.fil-name {
  position: absolute;
  right: 0;
}
.file-truncate-name {
  padding-right: 112px;
}
// .fixed-left-header {
//   position: fixed;
//   width: 340px;
// }
.ml-auto.btn-print-hover:hover {
  background: #173a64;
  color: #fff;
}
ul.folders.list-unstyled i {
  font-size: 21px;
}
.doc_table th {
  font-size: 13px;
  font-weight: 600;
  color: #000;
  font-family: 'Poppins', sans-serif;
}
.doc_table td {
  font-size: 13px;
  font-family: "Poppins", sans-serif;
}
/*Patient Documents*/
.document--patients{
  ul.folders li {
    padding: 10px 14px !important;
    float: left;
    height: 48px !important;
    cursor: pointer !important;
  }
  ul.folders.patient-leftbar.list-unstyled.nav.nav-tabs li {
    float: left;
    width: 100%;
    padding: 0 !important;
}
ul.folders.patient-leftbar.list-unstyled.nav.nav-tabs li a {
  float: left;
  width: 100%;
  padding:5px !important;
  cursor: pointer !important;
}
  ul.folders li.active {
    background: #173a64;
    a{
    color: #f6ca21 !important;
    }
    margin-right: 5px;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    opacity: 1 !important;
    border-bottom: 4px solid #f6ca21;
  }
  ul.dropdown-menu.show li {
    border-bottom: 1px solid #dcdcdc;
    padding: 5px;
    height: 36px;
  }
  ul.folders li a {
    text-decoration: none;
    color: #4a4d56;
    font-size: 11px;
    text-transform: uppercase;
    font-weight: 500;
  }
  ul.dropdown-menu.show {
    width: 100px !important;
    min-width: 115px;
    padding: 0;
  }
  ul.dropdown-menu.show li a {
    color: #000;
    font-size: 14px;
    padding: 4px 10px;
    width: 100%;
    text-decoration: none;
    cursor: pointer;
  }
   .patient-leftbar a.active {
    background: #173a64 !important;
    float: left;
    color: #fff !important;
    .gender-img i.fas.fa-user{
      color: #fff !important;
    }

  }
  .patient-leftbar .right-side .text-xs {
      font-size: 11px !important;
  }
}
table.table.table-styles.documenttable--fix thead td {
  font-weight: 600;
  font-size: 14px;
  border-bottom: 2px solid #efefef;
  padding: 6px;
}
table.table.table-styles.documenttable--fix tbody td {
  font-weight: normal;
  font-size: 13px;
  border-bottom: 1px solid #efefef;
  padding: 6px;
  color: #212529;
}
table.table.table-styles.documenttable--fix {
  margin-top: 10px;
}
.right-side .text-md {
  font-size: 14px;
}
ul.folders.patient-leftbar.list-unstyled.nav.nav-tabs {
  max-height: 331px;
  overflow-y: scroll;
}
.document--patients{
  /* width */
  ::-webkit-scrollbar {
    width: 10px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background:#173a64;
    border-radius: 10px;
  }
}
table.table.table-styles.documenttable--fix a {
  color: blue;
  text-decoration: underline;
  cursor: pointer !important;
}
.document--patients ul.dropdown-menu.show li:hover{
  background: #173a64;
  a{  
  color: #fff !important;
}
}
table.table.table-styles.documenttable--fix .dropdown i {
  cursor: pointer !important;
}
.right-side {
  text-transform: initial;
}
.document--patients ul.folders.list-unstyled {
  float: left;
  margin-bottom: 0 !IMPORTANT;
  width: 100%;
}
.doc_patient.document--patients{
h6.doc-head {
  color: #555;
  border-bottom: 2px solid;
  padding-bottom: 8px;
  margin: 0;
}
}
/*Phone school*/
.phoneschoolform--uifix .checkininput{
  .mat-form-field-appearance-legacy .mat-form-field-underline {
    height: 0px;
    display: none;
  }
  .mat-form-field-infix {
    border: 1px solid #dcdcdc;
    border-radius: 3px;
    padding-left: 10px;
    color: #495057 !important;
  }
}
button.deleteicon {
  width: auto !important;
  height: auto !important;
  line-height: initial !important;
  float: right;
  margin-right: 5px;
}
.filesectionloop {
  float: left;
  border: 1px solid #dcdcdc;
  padding-left: 10px;
  margin-top: 10px;
  min-width: 216px;
  font-size: 13px !important;
  max-width: 353px;
  width: 341px;
}
span.filenametext {
  float: left;
  margin-top: 2px;
  max-width: 300px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
/*Attachment docs*/

.modal.showdocspopup{
  background-color:#5b4949b3 !important;
}
.st-badge {
  background: #0c21a8;
  padding: 2px 10px;
  border-radius: 5px;
  color: #fff;
  font-weight: 600;
  display: inline-block;
  width: 50px;
}
.nt-badge {
  background: #19b0ae;
  padding: 2px 10px;
  border-radius: 5px;
  color: #fff;
  font-weight: 600;
  display: inline-block;
  width: 50px;
}