.nut-information {
    // background: #fff;
    margin-bottom: 10px;
    padding: 15px;
}
.nut-label {
    color: #173a64;
    font-weight: 500;
}
.nut-information .mat-radio-button {
    display: block;
    -webkit-tap-highlight-color: transparent;
    outline: 0;
    margin-top: 10px;
    padding: 10px 12px;
    border-radius: 5px;
    cursor: pointer;
    border: 1px solid #ddd;
    width:100%;
}
.question-list-nutri {
    display: inline-block;
    background-color: #fff;
    box-shadow: 0 4px 6px rgb(0 0 0 / 10%), 0 1px 3px rgb(0 0 0 / 10%);
    margin: 8px 0;
    border-radius: 4px;
    overflow: auto;
    cursor: pointer;
    width: 100%;
    box-sizing: border-box;
    text-align: left;
    padding-left: 35px;
    padding-top: 15px;
}
.nut-information .mat-radio-label-content {
    font-size: 15px;
    color: #4d4d4d;
    font-weight: 400;
}
.nut-information .mat-checkbox-label {
    font-size: 15px;
    color: #4d4d4d;
    font-weight: 400;
}
.nut-information .mat-checkbox {
    display: block;
    -webkit-tap-highlight-color: transparent;
    outline: 0;
    margin-top: 10px;
    padding: 10px 12px;
    border-radius: 5px;
    cursor: pointer;
    border: 1px solid #ddd;
    width:100%;
}
.nut-information .mat-radio-group{
    width:100%;
    padding-left: 15px;
}
.nut-information .check-custom{
    width:100%;
    padding-left: 15px;
}
.nut-information .mat-radio-button .mat-radio-label {
  margin:0
}
.nut-information .mat-checkbox-layout{
    margin:0
  }
  .nut-information .mat-checkbox:hover {
    background: #efefef;
}
.nut-information .mat-radio-button:hover {
    background: #efefef;
}
.custom-control.image-checkbox{
    position: relative;
    padding-left: 0;

    .custom-control-input:checked ~ .custom-control-label{
        &:after,
        &:before{
            opacity: 1;
        }
    }

    label{
        cursor: pointer;
        width:100%;
        height:110px;
        
        &:before{
            border-color: #007bff;
            background-color: #007bff;
        }

        &:after,
        &:before{
            transition: opacity .3s ease;
            opacity: 0;
            left: .25rem;
        }

        &:focus,
        &:hover{
            opacity: .8;
        }

        img{
            // border-radius: 2.5px;
            height: 105px;
            width: 180px;
        }
    }
}

.form-group-image-checkbox.is-invalid{
    label{
        color: #555;
    }
    .invalid-feedback{
        display: block;
    }
}
.text-slide-img {
    background: #e1e1e1;
    padding: 10px;
    font-weight: 500;
    text-align: center;
}
// .question-list-nutri:nth-child(2n+1) {
//     background: #fbfcff;
// }
// .custom-control-input:checked ~ .custom-control-label {
//     border: 3px solid #007bff;
// }

.img-text-active {
    border: 2px solid transparent;
}
.txt-area-active {
    border: 2px solid #3094fe;
}
.text-slide-img {
    background: #e8f8ff;
    font-weight: 500;
    text-align: center;
    color: #007bff;
    opacity: 1;
    // text-overflow: ellipsis;
    // overflow: hidden;
    margin: 0;
    position: relative;
    width: 180px;
    //white-space: pre;
    font-size: 13px;
    height: 65px;
    max-height: 200px
}
.nut-information .option-text {
    border: 1px solid #ccc;
    padding: 5px;
    margin-top: 0;
    background: #e8f8ff;
}
// .custom-control.image-checkbox {
//     height: 122px;
// }
.question-list-nutri fieldset {
    margin-top: 1rem;
    margin-bottom: 0.5rem;
    border: none;
    // box-shadow: 0px 0px 2px rgba(0,0,0,1);
    background-color: #fafbfc;
    padding: 12px;

  
   legend {
        background-color: skyBlue;
        padding: 5px;
        font-size: 1rem;
        color: white;
        text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);
        transform: translateX(-1.1rem);
        box-shadow: -1px 1px 1px rgba(0, 0, 0, 0.8);
        width: 101px;
        border-right: 2px solid rgb(0 0 0 / 80%);
    
    }
  
 
  legend:after {
    content: "";
    height: 0;
    width:0;
    background-color: transparent;
    border-top: 0.0rem solid transparent;
    border-right:  0.35rem solid black;
    border-bottom: 0.45rem solid transparent;
    border-left: 0.0rem solid transparent;
    position:absolute;
    left:-0.075rem;
    bottom: -0.45rem;
  }
}
.image-textarea {
    background: #e7f6fc;
    margin: 3px;
    margin-top: -1px;
    padding: 4px;
}
.blueButton {
    background-color: #173a64;
    color: #fff;
    display: inline-block;
    border: solid 3px #173a64;
    vertical-align: middle;
    text-shadow: 0 1px 0 #ffffff;
    line-height: 27px;
    min-width: 160px;
    text-align: center;
    padding: 5px 20px;
    text-decoration: none;
    border-radius: 0px;
    text-transform: capitalize;
    margin-right: 5px;
}
.medium-radio{
    width: 15px;
    height: 15px;
    vertical-align: middle;
}
.paincolor1{
    background: #a3d17a; 
}
.paincolor2{
    background: #b5d562; 
}
.paincolor3{
    background: #f9e415; 
}
.paincolor4{
    background: #fdba40; 
}
.paincolor5{
    background: #f79544; 
}
.paincolor6{
    background: #f1635c; 
}
.pain-color .img-fluid {
    width: 50px;
}
.pain-color {
    margin-top: 20px;
    margin-bottom: 20px;
}
.card-shadow{
    box-shadow: 0px 1px 9px #646464;
}
.custom-control.image-checkbox label:before {
    opacity: 1 !important;
    background: #ffffff;
}
.question-divider {
    position: relative;
    margin-bottom: 24px;
    padding-top: 12px;
    clear: both;
    border-bottom: 1px solid #D8D8D8;
    float:left;
    width:100%;
}
.divider-text {
    position: absolute;
    color: #6B7C93;
    left: 10px;
    top: 1px;
    background-color: #fff;
    padding: 2px 4px;
    font-size: 11px;
}