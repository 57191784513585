/*GT*/
.time-points{
  display: flex;
  flex-direction: row;
  padding: 10px;
}
.kids-name{
  display: flex;
  flex-direction: row;
  padding: 10px;
}
.time-points span {
  padding: 5px;
  border: 1px solid transparent;
  width: 100px;
  text-align: right;
  margin-bottom: 2px;
  height: 36px;
  font-size: 13px;
  line-height: 25px;
}
.kids-table {
  background: #fff;
  width: 100%;
}
.top-head-kids {
  font-size: 14px;
  padding: 10px;
  color: #173a64;
  font-weight: 600;
}
.info-kids {
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  color: #646464;
}
.kids-name a {
  padding: 5px;
  border: 1px solid #e7e7e7;
  margin-right: 10px;
  text-decoration: none;
  position: relative;
  /* flex: 1; */
  width: 247px;
  margin-bottom: 10px;
}
.fiftyvh{
  height:70vh;
}
.name-kids{
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.kids-name a:hover {
  box-shadow: 0px 2px 10px #a7a7a7;
}
.kids-name a i{
  position: absolute;
  right: 3px;
  top: 8px;
}
.booked .name-kids{
  color:#eb5757;
  font-size: 14px;
    font-weight: 400;
}
.available .name-kids{
  color:#27ae60;
  font-size: 14px;
    font-weight: 400;
}
.kids-table .booked {
  border-left: 4px solid #eb5757;
  background-color: #fef7f7;


}

.text-out {
  font-size: 10px !important;
  font-weight: 500;
  border-radius: 5px;
  padding: 5px 0;
  width: 100%;
  color: #3c6fe1 !important;
}
.kids-table .notbooked {
  border-left: 4px solid #646464;
}
.kids-table .available {
  border-left: 4px solid #27ae60;
  background-color: #f4fbf7;

}
.info-kids .book {
  color: #eb5757;
  padding-left: 5px;
  padding-right: 5px;
}
.info-kids .unbook {
  color: #27ae60;
  padding-left: 5px;
  padding-right: 5px;
}
.filter {
  font-size: 13px;
  font-weight: 500;
}
.kids-name a div {
  font-size: 12px;
  color: #4c4c4c;
}
ol.simple_with_drop.vertical {
  list-style-type: none;
  padding: 0;
  overflow: auto;
  max-height: 430px;
}
ol.simple_with_drop.vertical li {
  display: block;
  margin: 5px;
  padding: 5px;
  border: 1px solid #cccccc;
  color: #555;
  background: #eeeeee;
  cursor: all-scroll;
  font-size: 13px;
}
.group-name {
  padding: 10px;
  background: #efefef;
  border: 1px solid #f6f9f9;
  border-radius: 5px;
}
.group-name label {
  font-size: 12px;
  font-weight: 600;
  color:#173a64
}
.create-btn-group {
  background: #173a64;
  border: 1px solid #bec9cd;
  border-radius: 5px;
  color: #fff;
  padding: 3px 7px;
  font-size: 13px;
  width: 72px;
  height: 37px;
}
.assign-head {
  font-size: 13px;
  padding: 10px;
  color: #173a64;
  font-weight: 600;
  background: #ededed;
}
.assign-head2 {
  font-size: 13px;
  padding: 10px;
  // background: #fff;
  color: #173a64;
  font-weight: 600;
}
.group-items-name {
  padding: 10px;
  background: #194d8b;
  color: #fff;
  font-size: 13px;
}
.group-items {
  // border: 1px solid #ccc;
  // margin-top: 10px;
  // min-height: 100px;
  margin-bottom: 10px;
}
.chip-container {
  padding: 10px;
  background: #efef;
  border: 1px dotted #44a744;
  margin: 10px;
}
.drag-img img{
  width: 100px;
}
.kids-name-assigned-top {
  font-size: 14px;
  font-weight: 600;
  text-align: center;
  padding: 5px;
  margin-top: 10px;
}
.kids-name-assigned {
  font-size: 13px;
    font-weight: bold;
    text-align: center;
    padding: 5px;
    background: #efefef;
    color: #173a64;
}

.edit-options {
  position: absolute;
  right: -8px;
  top: 5px;
}
// .left-kids-section {
//   position: fixed;
// }
.theme-view label {
  font-weight: bold;
    font-size: 13px;
    margin-right: 10px;
    padding: 0;
    color: #173a64;
}
.theme-view span {
  font-size: 13px;
    padding-left: 5px;
    font-weight: 500;
    color: #484747;
}
.small-meta{
  float: left;
  width:100%;
  font-size: 13px;
}
.met-info-top {
  font-size: 13px;
  color: #555;
  font-weight: 600;
}
// .met-info-top {
//   font-size: 13px;
//   color: #173a64;
//   font-weight: 500;
//   background: #d5e8ff;
//   padding: 4px 20px;
//   border-radius: 4px;
// }
.met-info-top {
  font-size: 13px;
  color: #173a64;
  font-weight: 500;
  padding: 4px 20px;
  border-radius: 0;
  background: #fff;
  margin-bottom: 5px;
  box-shadow: 0 0 7px #adadad;
}
span.imp-info {
  color: #1479a9;
  font-size: 14px;
  display: block;
  font-weight: 500;
}
.met-info-top i {
  color: #173a64;
  margin-top: 7px;
  margin-right: 18px;
  font-size: 22px;
}
.imp-info-small{
  font-weight: 700;
  color: #555;
  font-size: 13px;
}
.assign-btn {
  position: absolute;
  right: 4px;
  top: 4px;
  color: #fff;
  background: #27ae60;
  padding: 5px;
  height: 24px;
  font-size: 9px;
  border: 1px solid;
  border-radius: 5px;
  opacity: 0;
  transition: opacity .35s ease;
}
.assign-section:hover .assign-btn {
  opacity: 1;
}
.reassign-btn {
  position: absolute;
  right: 4px;
  top: 4px;
  color: #fff;
  background: #eb5757;
  padding: 5px;
  height: 24px;
  font-size: 9px;
  border: 1px solid;
  border-radius: 5px;
  opacity: 0;
  transition: opacity .35s ease;
}
.reassign-section:hover .reassign-btn {
  opacity: 1;
}
.Add-sub-btn {
  text-decoration: underline;
  font-weight: 400;
}
a.link-top-subgroup {
  color: #fff;
  margin-right: 20px;
  border: 1px solid #fff;
  padding: 3px 13px;
  border-radius: 5px;
  font-size: 12px;
  font-weight: 500;
}
a.link-top-subgroup:hover {
  background: #fff;
  color: #3e60aa;
  text-decoration: none;
}
.empty-assign {
  text-align: center;
  margin-top: 15px;
}
.empty-assign i {
  font-size: 50px;
  color: #555;
  margin-top: 29px;
}
.assign-clinician label {
  font-size: 14px;
  margin-right: 10px;
  font-weight: 500;
  color: #173a64;
}
.assign-clinician a {
  padding: 3px;
  border: 1px solid #bfbfbf;
  font-size: 13px;
  border-radius: 5px;
  color: #000;
  background: #efefef;
  text-decoration: none;
}
.theme-view {
  margin-right: 25px;
}
.gp-schedule-container {
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 10px;
}
.gp-schedule-list {
  background: #173a64;
  padding: 3px 10px;
  border: 1px solid #ccc;
  border-radius: 2px;
}
.kids-assign-sec {
  white-space: normal;
  word-break: break-word;
}
.kids-assign-sec span {
  margin-right: 10px;
  font-size: 14px;
}
.teachers-head {
  font-size: 13px;
    font-weight: 500;
    color: #484747;
    padding-left: 10px;
    line-height: 36px;
}
.clinic-assign {
  // background: #e4f6ff;
  // margin-bottom: 5px;
  padding: 2px 0px;
}
.time-start-end {
  font-size: 12px;
  color: #fff;
  font-weight: 600;
  margin-right: 17px;
  vertical-align: middle;
  line-height: 26px;
}
.groups-name {
  font-size: 14px;
  font-weight: 600;
  margin-right: 13px;
  vertical-align: middle;
  line-height: 26px;
  color: #fff;
}
button.assign-notes {
  margin-right: 5px;
  border: 1px  solid;
  padding: 3px 12px;
  font-size: 13px;
  border-radius: 3px;
  background: #00bcd4;
  color: #fff;
}
.rating-points {
  margin-right: 5px;
  border: 1px solid;
  padding: 3px 12px;
  font-size: 13px;
  border-radius: 3px;
  background: #c9a12a;
  color: #fff;
}
.head-list-gt {
  font-size: 13px;
  font-weight: 600;
  color: #555;
}
// a.slot-view-btn {
//   font-size: 11px;
//   vertical-align: super;
//   text-decoration: none;
//   background:  #3082d7;
//   padding: 2px 13px;
//   color: #fff;
//   border-radius: 3px;
//   border: 1px solid;
// }
a.slot-view-btn {
  font-size: 11px;
  color: #fff !important;
  line-height: 1.2 !important;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 8px !important;
  height: 32px;
  background-color: #3082d7;
  border: 2px solid #3082d7 !important;
  border-radius: 0 !important;
  text-decoration: none  !important;
}
.slot-view-btn:hover {
  background: #0e4c8d;
}
a.release-view-btn {
  background: #d3812e;
  font-size: 11px;
  color: #fff !important;
  line-height: 1.2 !important;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 8px !important;
  height: 32px;
  text-decoration: none  !important;
  border-radius: 0 !important;
}
.release-view-btn:hover {
  background: #c36e18;
}
a.assign-view-btn{
  background: #5db85e;
  font-size: 11px;
  color: #fff !important;
  line-height: 1.2 !important;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 8px !important;
  height: 32px;
  text-decoration: none  !important;
  border-radius: 0 !important;
}
.assign-view-btn:hover {
  background: #3d813e;
}
// .slot-detail {
//   border: 1px solid #ccc;
//   padding: 2px 5px;
//   background: #f3f3f3;
// }
.slot-detail {
  // box-shadow: 0 5px 9px #d9d9d9;
  padding: 2px 5px;
  background: #f3f3f3;
  margin-top: 7px;
}
.assign-data {
  font-size: 12px;
  background: #efefef;
  padding: 5px;
  font-weight: 400;
  margin-top: 5px;
}
.slot-detail label {
  font-size: 15px;
}
.heading-treatment-date {
  font-weight: 500;
  margin-top: 10px !important;
  display: block;
}
.multiple-gt .card {
  box-shadow: 0 0 10px #cdcdcd;
}
.font-10{
  font-size: 10px;
}

/*fivepoint*/
.heading-assign-stud {
  font-weight: 500;
  color: #173a64;
}
.heading-assign-stud span {
  font-weight: 500;
  font-size: 14px;
  color: #1479a9;
}
.hr-diamond:before {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 60px;
  height: 2px;
  content: "";
  background-color: #173a64;
}
.hr-diamond {
  position: relative;
  margin-top: 10px;
}
.border-card {
  background: #fff;
  margin-bottom: 7px;
  align-items: center;
  font-size: 14px;
  padding: 12px 16px;
  cursor: pointer;
  border-radius: 4px;
  border: 1px solid #eaeaea;
  box-shadow: 0px 2px 1px 0px rgba(0, 0, 0, 0.1);
  transition: all 0.25s ease;
}
.dob-detail {
  width: 100%;
  font-size: 12px;
  font-weight: 500;
  color: #7c7c7c;
}
.dob-detail span {
  color: #000;
  font-weight: 600;
}
.border-card:hover {
  border-left: 4px solid #3378cb;
  -webkit-transform: translateY(-1px);
  transform: translateY(-1px);
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1), 0 5px 10px 0 rgba(0, 0, 0, 0.1);
  background: #efefef;
}
.name-label {
  font-weight: 500;
  font-size: 15px;
  margin-top: 15px;
  color: #484848;
}
.name-label span{
  font-size: 14px;
  font-weight: 500;
  color: #3282ab;
}
.stud-name-sec h5 {
  font-size: 14px;
  color: #323232;
  font-weight: 500;
}
.stud-name-sec h5 span {
  font-size: 14px;
  font-weight: 500;
  color: #3282ab;
}
.border-card.active {
  border-left: 4px solid #3378cb;
  transform: translateY(-1px);
  background: #efefef;
  border-right: none;
}
.stud-name-sec {
  overflow: auto;
  max-height: 540px;
}
.observ-bg {
  background: #efefef;
}
.responsive-table {
  width: 100%;
  margin-bottom: 1.5em;
  border-spacing: 0;
}
@media (min-width: 48em) {
  .responsive-table {
    font-size: 0.9em;
  }
}
@media (min-width: 62em) {
  .responsive-table {
    font-size: 13px;
  }
}
.responsive-table thead {
  position: absolute;
  clip: rect(1px 1px 1px 1px);
  /* IE6, IE7 */
  padding: 0;
  border: 0;
  height: 1px;
  width: 1px;
  overflow: hidden;

}
@media (min-width: 48em) {
  .responsive-table thead {
    position: relative;
    clip: auto;
    height: auto;
    width: auto;
    overflow: auto;
    border-bottom: 1px solid;
  }
}
.responsive-table thead th {
  // background-color: #173a64;
  // border: 1px solid #104e99;
  font-weight: 500;
  text-align: center;
  color: #000;
  font-size: 13px;
}
.responsive-table thead th:first-of-type {
  text-align: left;
}
.responsive-table tbody,
.responsive-table tr,
.responsive-table th,
.responsive-table td {
  display: block;
  padding: 0;
  text-align: left;
  white-space: normal;
}
@media (min-width: 48em) {
  .responsive-table tr {
    display: table-row;
  }
}
.responsive-table th,
.responsive-table td {
  padding: 0.5em;
  vertical-align: middle;
}
@media (min-width: 30em) {
  .responsive-table th,
.responsive-table td {
    padding: 0.75em 0.5em;
  }
}
@media (min-width: 48em) {
  .responsive-table th,
.responsive-table td {
    display: table-cell;
    padding: 0.5em;
  }
}
@media (min-width: 62em) {
  .responsive-table th,
.responsive-table td {
    padding: 0.75em 0.5em;
  }
}
@media (min-width: 75em) {
  .responsive-table th,
.responsive-table td {
    padding: 0.75em;
  }
}
.responsive-table caption {
  margin-bottom: 1em;
  font-size: 1em;
  font-weight: bold;
  text-align: center;
}
@media (min-width: 48em) {
  .responsive-table caption {
    font-size: 1.5em;
  }
}
.responsive-table tfoot {
  font-size: 0.8em;
  font-style: italic;
}
@media (min-width: 62em) {
  .responsive-table tfoot {
    font-size: 0.9em;
  }
}
@media (min-width: 48em) {
  .responsive-table tbody {
    display: table-row-group;
  }
}
.responsive-table tbody tr {
  margin-bottom: 1em;
}
@media (min-width: 48em) {
  .responsive-table tbody tr {
    display: table-row;
    border-width: 1px;
  }
}
.responsive-table tbody tr:last-of-type {
  margin-bottom: 0;
}
@media (min-width: 48em) {
  // .responsive-table tbody tr:nth-of-type(even) {
  //   background-color: rgba(0, 0, 0, 0.12);
  // }
}
.responsive-table tbody th[scope=row] {
  background-color: #173a64;
  color: white;
}
@media (min-width: 30em) {
  .responsive-table tbody th[scope=row] {
    // border-left: 1px solid #173a64;
    // border-bottom: 1px solid #173a64
  }
}
@media (min-width: 48em) {
  .responsive-table tbody th[scope=row] {
    background-color: transparent;
    color: #173a64;
    text-align: left;
  }
}
.responsive-table tbody td {
  text-align: right;
}
@media (min-width: 48em) {
  .responsive-table tbody td {
    // border-left: 1px solid #173a64;
    // border-bottom: 1px solid #173a64;
    text-align: center;
  }
}
// @media (min-width: 48em) {
//   .responsive-table tbody td:last-of-type {
//     border-right: 1px solid #173a64;
//   }
// }
// .responsive-table tbody td[data-type=currency] {
//   text-align: center;
// }
// .responsive-table tbody td[data-title]:before {
//   content: attr(data-title);
//   float: left;
//   font-size: 0.8em;
//   color: rgba(0, 0, 0, 0.54);
// }
// @media (min-width: 30em) {
//   .responsive-table tbody td[data-title]:before {
//     font-size: 0.9em;
//   }
// }
// @media (min-width: 48em) {
//   .responsive-table tbody td[data-title]:before {
//     content: none;
//   }
// }

.observ-section textarea {
  margin: 0 auto;
  padding: 0;
}
.add-notes-btn {
  font-size: 11px;
  border: 1px solid;
}
.add-notes-btn:hover {
  background: #fff;
}
nav.tabs-observ {
  background: #fff;
  margin-top: 9px;
  padding: 3px;
  border-radius: 5px;
}
nav.tabs-observ .nav-pills .nav-link.active {
  background: #fff;
  color: #173a64;
  border-bottom: 2px solid;
  border-radius: 0;
  font-weight: 600;
}
nav.tabs-observ .nav-pills .nav-link {
  color: #173a64;
  font-weight: 500;
}
.preview-btn-obser {
  border: 1px solid;
  background: #173a64;
  color: #fff !important;
  font-size: 11px;
  height: 33px;
  line-height: 12px;
  margin-top: 2px;
  padding: 10px;
  border-radius: 5px;
  display: inline-block;
  text-decoration: none !important;
}
.preview-btn-obser:hover {
  border: 1px solid;
  background: #fff;
  color: #173a64 !important;
}
.inform-group-inter i {
  position: absolute;
  background: #c1c1c1;
  padding: 6px;
  top: 0;
  right: 0;
  font-size: 11px;
  color: #4c4c4c;
  border: 1px solid #ccc;
}
.inform-group-inter {
  border: 1px solid #ced4da;
  background: #fff;
  padding: 5px;
  height: 52px;
  overflow: hidden;
  font-size: 7px;
  position: relative;
}
.notes-added {
  padding: 10px;
  background: #fff;
  border-radius: 5px;
}
.notes-added h5 {
  font-size: 15px;
  margin-bottom: 13px;
  font-weight: 500;
}
.notes-added textarea {
  font-style: italic;
  padding: 12px;
  font-size: 14px;
  height: 93px;
}
.notes-clinic label {
  font-size: 14px;
  font-weight: 500;
}
.notes-clinic textarea {
  font-style: italic;
  padding: 12px;
  font-size: 14px;
  height: 93px;
}
.notes-added-btn{
  font-size: 11px;
    border: 1px solid;
}
.first-entry-medication i {
  font-size: 45px;
  margin-top: 16px;
}
.first-entry-medication a {
 text-decoration: underline;
}
.sub-small-label{
  margin-top: -6px;
  display: block;
  margin-bottom: 0;
  font-size: 13px;
  text-align: center;
  border: 1px solid #ccc;
  background: #fff;
}
.add-medic {
  margin-top: 15px;
  background: #f9f9f9;
  padding: 3px;
  border-bottom: 1px solid #ccc;
}
.b4-meal .mat-radio-label-content {
  font-size: 12px;
  color: #173a64;
}
.medic-his-list ul {
  list-style: none;
  padding: 0;
}
.medic-his-list label {
  font-size: 14px;
  font-weight: 500;
  float: left;
  width: 100%;
  margin: 0;
}
.medic-his-list span {
  font-size: 16px;
  font-weight: 400;
  color: #2d6c97;
}
.date-medic {
  font-size: 12px;
  font-weight: 600;
  margin-right: 10px;
}
.date-medic span {
  font-size: 12px;
  font-weight: 600;
  color: #2d6c97;
}
.alergy {
  font-size: 13px;
  color: #fff;
  background: #d10d0d;
  margin-bottom: 5px;
  border-radius: 5px;
  padding: 5px;
}
.morn-time {
  border: 1px solid;
  padding: 4px;
  font-size: 10px !important;
  border-radius: 5px;
  margin-right: 5px;
  margin-bottom: 5px;
  font-weight: 600 !important;
  float: left;
}
.medic-his-list a{
  cursor: pointer;
}
.Add-sub-btn-small {
  font-size: 13px;
  font-weight: 400;
  text-decoration: underline;
  display: block;
}
.panel-list {
  border-top: 1px dotted #ccc;
  margin-top: 9px;
  padding-top: 6px;
}
.observ-section .mat-error {
  float: left;
  width: 100%;
}
tr.sign-design .btn {
  padding: 0 !important;
  width: 100px;
  margin: 0 auto;
}
.exp-btn {
  border: 2px solid #9fa2a4;
  width: 30px;
  border-radius: 5px;
  background: linear-gradient(#fbfbfb, #d1d1d1);
  float: right;
  height: 13px;
  padding-left: 5px;
  padding-right: 5px;
}
.dots {
  font-size: 30px !important;
  margin-top: -26px;
  letter-spacing: -1px;
  float: left;
}
.total-grand {
  background: #d2e4f9;
}
.total-grand td {
  font-weight: 600;
}
/*pdf design*/
.preview-fivepoint .modal-dialog{
    max-width: 1000px;
    margin: 1.75rem auto;
}
.stud-grp-name span {
  font-size: 14px;
  font-weight: 500;
  color: #3282ab;
}
.stud-grp-name {
  font-size: 14px;
  color: #323232;
  font-weight: 500;
}
.font-14{
  font-size: 14px;
}
.preview-fivepoint .modal {
  background: rgb(0 0 0 / 60%);
}
.preview-fivepoint .modal-body {
  height: 487px;
  overflow: auto;
}
.yes-no-5point {
  font-weight: 600;
  color: #000;
}
.group-view-notes {
  text-align: center;
  background: #efefef;
  font-weight: 500;
  padding: 5px;
}
.grp-sub-heading {
  color: #3282ab;
  font-size: 15px;
  font-weight: 500;
  margin-bottom: 10px;
}
.grp-sub-heading-sub {
  color: #000;
  font-size: 12px;
  font-weight: 600;
  margin-bottom: 10px;
  text-align: left;
   background: #bbbbbb;
  padding: 6px 3px;
  border-bottom: 2px solid #ccc;
}
.notes-container-grp {
  font-size: 12px;
  height: 100%;
  margin-bottom: 10px;
}
.letter-total {
  border-radius: 5px;
  background: #c2c2c2;
  height: 26px;
  width: 26px;
  display: inline-block;
  line-height: 25px;
  color: #000;
  text-align: center;
  font-weight: 500;
}
.clinicl-view label {
  font-size: 15px;
  font-weight: 500;
  color: #000;
}
.clinicl-view p {
  font-size: 12px;
  // border: 1px solid #ccc;
  padding: 10px;
  border-radius: 5px;
  background: #e0f1ff;
}
.six h1 {
  text-align: center;
  color: #222;
  font-size: 17px;
  font-weight: 500;
  text-transform: uppercase;
  word-spacing: 1px;
  letter-spacing: 0px;
  color: #3282ab;
  margin-bottom: 22px;
}
.six{
  position: relative;
}
.six h1 span {
  line-height: 2em;
  padding-bottom: 15px;
  text-transform: none;
  font-size: .7em;
  font-weight: normal;
  font-style: italic;
  font-family: "Playfair Display","Bookman",serif;
  color: #999;
  letter-spacing: -0.005em;
  word-spacing: 1px;
  letter-spacing: none;
  display: block;
}
.six h1:after, .six h1:before {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 45px;
  height: 4px;
  content: "";
  right: 45px;
  margin: auto;
  background-color: #ccc;
  top: 49px;
}
.preview-fivepoint .six h1:after, .six h1:before {
  top: 28px;
}
.six h1:before {
  background-color: #8db9cf;
  left: 45px;
  width: 90px;
}
.line:before, .line:after {
  height: 1px;
  margin: 0 1em;
  background: black;
  content: "";
    flex: 1;
}
.past-history.line {
  color: #555;
  font-size: 13px;
  margin-top: 30px;
  margin-bottom: 28px;
}
.line {
  align-items: center;
  margin: 1em -1em;
  display: flex;
}
.todays-Date label {
  margin: 0;
}
.todays-Date {
  text-align: left;
  font-size: 12px;
  font-weight: 600;
  color: #2463af;
  margin: 10px 0;
}
.popup-list .col-event-mytask {
  width: 100%;
}
.popup-list {
  overflow: auto;
  max-height: 530px;
}
.heading-point-five {
  color: #34567e;
  margin-top: 10px;
  margin-bottom: 5px;
}
span.goto-txt {
  color: #505050;
  font-size: 14px;
  margin-top: 10px;
  margin-right: 10px;
  font-weight: 400;
}
.time-stamp-txt {
  margin-left: 10px;
  font-size: 12px;
  background: #173a64;
  color: #fff;
  padding: 0px 15px;
  height: 22px;
  border-radius: 10px;
  line-height: 22px;
}
.time-stamp-txt label {
  margin-right: 10px;
}
.cal-event-tooltip {
  background: #fff !important;
  color: #000 !important;
}
.Activity-popup .modal-dialog {
  max-width: 1000px;
  margin: 1.75rem auto;
}
.Activity-popup-view .modal-dialog {
  top:12%;
  max-width: 1000px;
  margin: 1.75rem auto;
}
.check-fields-large-label .mat-checkbox-label {
  font-size: 14px !important;
}
.custom-tab-border .nav-link.active,.custom-tab-border .nav-link:hover {
  background: #fff;
  color: #173a64;
  border-bottom: 2px solid !important;
  border-radius: 0;
  font-weight: 600;
  border: 0;
}
.custom-tab-border .nav-link {
  color: #173a64;
  font-weight: 500;
  background: #fff;
}
.custom-tab-border .nav-tabs {
  border-bottom: 0;
  background: #efefef;
  padding: 10px;
  border-radius: 5px;
}
.fw-bold {
  font-weight: 700;
  color: #243591;
}
.list-of-pat {
  width: 100%;
  padding: 10px;
}
.button-radio-custom {
  background-color: #fff;
  color: #363636;
  cursor: pointer;
  justify-content: center;
  text-align: center;
  margin-bottom: 0.5rem;
  white-space: nowrap;
  margin-right: 5px;
  border-radius: 5px;
  font-size: 13px;
  border: 2px solid lightgrey;
  transition: all 0.3s ease;
}
.button-radio-custom.active,.button-radio-custom:hover {
  border-left: 4px solid #3378cb;
  transform: translateY(-1px);
  background: #efefef;
  color: #323232;
  font-weight: 600;
  transform: translateY(-1px);
    box-shadow: 0 5px 10px 0 rgb(0 0 0 / 10%), 0 5px 10px 0 rgb(0 0 0 / 10%);
}
.goals-listed-view ul {
  list-style-type: none;
  padding: 0;
}
.goals-listed-view li {
  padding: 8px 40px;
  margin: 5px 0;
  border-radius: 0;
  background: #f7f7f7;
  text-align: left;
  position: relative;
}
.goals-listed-view li i {
  color: green;
  position: absolute;
  left: 13px;
  top: 13px;
  font-size: 17px;
}
tr.goals-design {
  background: #fff;
  border-bottom: 3px solid #b7b7b7;
}
.sign-label {
  font-weight: 600;
  font-size: 14px;
  margin-right: 40px !important;

  margin-top: 16px;
}
.last-sign{
  padding-bottom: 25px;
  margin-top: 25px;
  // background: #fff;
}
.aggr-table thead th {
  font-weight: 500;
  text-align: left;
  color: #000;
  font-size: 13px;
  padding: 0.75em;
  border-bottom: 1px solid;
  border-top: 0;
}
.aggr-table tbody th[scope=row] {
  background-color: transparent;
  color: #173a64;
  font-size: 13px;
  padding: 0.75em;
  font-weight: bold;
}
.aggr-table tbody th,.aggr-table tbody td {
  font-size: 13px;
  padding: 0.75em;
  border-top: 0;

}
.aggr-table tbody td {
  text-align: center;

}
.kpi-table tbody td {
  text-align: left;
}
.kpi-table .letter-total {
  border-radius: 5px;
  background: #7a7a7a;
  height: 15px;
  width: 15px;
  display: inline-block;
  line-height: 17px;
  color: #fff;
  text-align: center;
  font-size: 10px;
}
.goals-plan-date {
  color: #000;
}
.status-goals span {
  width: 80%;
  line-height: 16px;
  margin-bottom: 5px;
}
.togler table {
  width: 100%;
  border: 1px solid #ccc;
}
.togler table td{
  padding: 0;
}
.overall-percentage .progress1 {
  width: 55px;
  height: 55px !important;
  float: left;
  line-height: 150px;
  background: none;
  margin: 5px;
  box-shadow: none;
  position: relative
}

.overall-percentage .progress1:after {
  content: "";
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 12px solid #fff;
  position: absolute;
  top: 0;
  left: 0
}

.overall-percentage .progress1>span {
  width: 50%;
  height: 100%;
  overflow: hidden;
  position: absolute;
  top: 0;
  z-index: 1
}

.overall-percentage .progress1 .progress-left {
  left: 0
}

.overall-percentage .progress1 .progress-bar1 {
  width: 100%;
  height: 100%;
  background: none;
  border-width: 5px;
  border-style: solid;
  position: absolute;
  top: 0
}
.overall-percentage {
  margin-top: -15px;
}
span.over-lab {
  float: left;
  margin-top: 23px;
  font-size: 14px;
  font-weight: 500;
  margin-right: 6px;
}
.overall-percentage .progress1 .progress-left .progress-bar1 {
  left: 100%;
  border-top-right-radius: 80px;
  border-bottom-right-radius: 80px;
  border-left: 0;
  -webkit-transform-origin: center left;
  transform-origin: center left
}

.overall-percentage .progress1 .progress-right {
  right: 0
}

.overall-percentage .progress1 .progress-right .progress-bar1 {
  left: -100%;
  border-top-left-radius: 80px;
  border-bottom-left-radius: 80px;
  border-right: 0;
  -webkit-transform-origin: center right;
  transform-origin: center right;
  animation: loading-1 1.8s linear forwards
}

.overall-percentage .progress1 .progress-value {
  width: 90%;
  height: 90%;
  border-radius: 50%;
  background: #000;
  font-size: 12px;
  color: #fff;
  line-height: 50px;
  text-align: center;
  position: absolute;
  top: 5%;
  left: 5%
}

.overall-percentage .progress1.blue .progress-bar1 {
  border-color: #049dff
}

.overall-percentage .progress1.blue .progress-left .progress-bar1 {
  animation: loading-2 1.5s linear forwards 1.8s
}

.overall-percentage .progress1.yellow .progress-bar1 {
  border-color: #fdba04
}

.overall-percentage .progress1.yellow .progress-right .progress-bar1 {
  animation: loading-3 1.8s linear forwards
}

.overall-percentage .progress1.yellow .progress-left .progress-bar1 {
  animation: none
}

@keyframes loading-1 {
  0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg)
  }

  100% {
      -webkit-transform: rotate(180deg);
      transform: rotate(180deg)
  }
}

@keyframes loading-2 {
  0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg)
  }

  100% {
      -webkit-transform: rotate(144deg);
      transform: rotate(144deg)
  }
}

@keyframes loading-3 {
  0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg)
  }

  100% {
      -webkit-transform: rotate(135deg);
      transform: rotate(135deg)
  }
}
.custom-small-input {
  font-size: 13px;
  height: 25px;
  line-height: 1;
  padding: 2px;
  margin-bottom: 5px;
}
.status-goals {
  margin-right: 10px;
}
td.togler th {
  background: #dddddd;
}
td.togler td {
  padding: 10px;
}
.comment-btn {
  border: 1px solid #343434;
  padding: 5px;
  border-radius: 5px;
  box-shadow: 0 0 7px #ccc;
  text-decoration: none;
  color: #000;
  margin-bottom: 5px !important;
  display: block;
  width: 200px;
}
.comment-btn:hover {
  border: 1px solid #343434;
  padding: 5px;
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 0 7px #ccc;
  text-decoration: none;
  color: #000;
  margin-bottom: 5px !important;
  display: block;
  width: 200px;
}
.comment-view {
  background: #fff;
  padding: 5px;
  width: 200px;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  border-radius: 3px;
  border: 1px solid #ccc;
  cursor: pointer;
}
.cli_value_family span {
  font-size: 13px;
  font-weight: 500;
  margin-right: 6px;
  //text-transform: capitalize;
  color:#555;
}
.five-points-container .tabs-observ .nav-link.active{
  border-bottom: 2px solid !important;
  border: none;
}

.five-points-container .tabs-observ .nav-link{
 background-color: #fff;
}
span.cli_value_family:last-child small {
    display: none;
}
.no-patients {
  text-align: center;
  margin-top: 50px;
  font-size: 18px;
  font-weight: 500;
  height:80vh;
}
.image-nopatient {
  margin-top: 50px;
  margin-bottom: 50px;
}
.overall-percentage .total-progress {
  position: inherit;
  float: left;
}
.overall-percentage .total-progress .progress .progress-bar {
  border-color: #049dff;
}
.overall-percentage .total-progress .progress {
  width: 60px;
  height: 60px;
  line-height: 160px;
  background: none;
  margin: 0 auto;
  box-shadow: none;
  position: relative;
  margin-top: 3px;
  margin-right: 10px;
}
.overall-percentage .total-progress .progress .progress-value {
  font-weight: 600;
  background: #000;
  border: 9px solid #fff;
  color: #fff;
  font-size: 11px;
}

.goal-page-indiv .fa-caret-right{
  transition: all .4s ease;
}
.goal-page-indiv .panel-title .fa-caret-right{
  transform: rotate(90deg);
}
.goal-page-indiv .panel-title .collapsed .fa-caret-right{
  transform: unset;
}
.goal-page-indiv h4 a {
  color: #000;
  font-size: 13px;
  font-weight: 600;
}
.goal-stat-chang select {
  font-size: 10px;
  padding: 1px;
  height: 22px;
  font-weight: 600;
}
.ben-head {
  font-size: 13px;
  background: #f9f9f9;
  padding: 9px;
  margin-bottom: 6px;
  border: 1px solid #ccc;
}
.ben_unit {
  text-align: center;
  font-weight: 500;
}
.ben_tit {
  font-weight: 600;
  font-size: 15px;
  margin-bottom: 5px;
}
.value-goal {
  font-size: 14px;
  font-weight: 500;
  color: #173a64;
}
.goal-name label {
  font-weight: 500;
  color: #555;
}
.goal-percen {
  background: #eee;
  padding: 10px;
}
.status-stat {
  padding: 2px 8px;
  background: #fff;
  border: 1px solid #ccc;
  font-size: 12px;
  font-weight: 600;
  border-radius: 3px;
}
.status-stat.completed{
  color:#77cb66;
}
.status-stat.inprogress{
  color:#ffc54d;
}
.status-stat.delay{
  color:#ff3f50;
}
.status-tabl{
  padding: 2px 8px;
  border: 1px solid #ccc;
  font-size: 10px;
  font-weight: 500;
  border-radius: 3px;
}
.status-tabl.completed {
  color: #fff;
  background: #77cb66;
}
.status-tabl.inprogress{
  background:#ffc54d;
  color: #fff;
}
.status-tabl.delay{
  background:#ff3f50;
  color: #fff;
}
.edit-float {
  position: absolute;
  right: 5px;
  top: 5px;
}
.edit-float a{
color: #173a64;
}
.progress-btn-logs {
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  z-index: 1;
}
.aggr-table tbody th[scope=row] a{
  color: #173a64;
}
.aggr-table .collapsed .fa-caret-right {
  transform: rotate(90deg);
}
.aggr-table .collapsed .fa-caret-right {
  transition: all 0.4s ease;
}
.sub-descib {
  color: #bf3333;
  font-size: 11px;
  }
  .aggr-table tbody {
    border: none !important;
}
.admin-theraphy label {
  font-size: 12px;
  font-weight: 500;
  margin: 0;
}
.admin-theraphy p {
  font-size: 15px;
  color: #173a64;
  font-weight: 600;
  margin: 0;
}
.admin-theraphy a {
  font-size: 15px;
  color: #173a64;
}
.benchmark-popup-rating .modal {
  background: rgba(0,0,0,.32);
}
.benchmark-popup-rating .modal-dialog {
  top: 30%;
  width: 600px;
  min-width: 600px;
  height: auto !important;
}
.log-activity .modal-dialog {
  top: 25%;
  width: 700px;
  min-width: 700px;
}
.list-container-log {
  margin-bottom: 5px;
  background: #f5f5f5;
  padding: 10px;
  border: 1px solid #ccc;
}
.list-container-log .list-val {
  font-weight: 600;
  font-size: 13px;
  color: #040404;
  line-height: 26px;
}
.list-container-log .list-label {
  font-size: 13px;
    color: #006c9d;
    margin-bottom: 5px;
    width: 315px;
    font-weight: 500;
}
.bench-head {
  font-weight: 600;
  color: #000;
}
.custom-panel mat-expansion-panel-header {
  background: #fff !important;
}
.custom-panel .mat-expansion-indicator::after {
  color: #262626 !important;
}
.tab-custom-logs .looping-section {
  background: #fff;
  padding-top: 15px;
}
.tab-custom-logs .mat-tab-label-active {
  background: #fff !important;
  color: #173a64;
  margin-right: 5px;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  opacity: 1 !important;
  font-size: 12px;
  padding: 0 15px !important;
}
.tab-custom-logs .mat-tab-label {
  font-size: 12px;
  padding: 0 15px !important;
  background: #ebebeb;
  margin-right: 5px;
}
.tab-custom-logs .mat-ink-bar {
  height: 2px !important;
  background: #9b9a99 !important;
}
.preview-sub {
  font-size: 13px;
  font-weight: 600;
  margin-top: 10px;
  color: #000;
}
.details-value {
  font-weight: 500;
  color: #484747;
  font-size: 14px;
}
.treatmentplan-severity{
  padding: 2px;
  border: 1px solid #ccc;
  font-size: 10px;
  font-weight: 500;
  border-radius: 3px;
  color: white;
}

span.imp-info1 {
  color: #1479a9;
  font-size: 14px;
  // display: block;
  font-weight: 500;
}