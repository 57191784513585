.clinical-review-page{
  .check-custom .mat-checkbox-label {
    color: unset;
    text-transform: capitalize;
    font-size: unset;
    font-weight: unset;
    white-space: break-spaces;
    line-height: 15px;
  }
  .crb-label {
    font-weight: 500;
    text-transform: capitalize;
    margin-bottom: 10px;
    color: #173a64;
}


}
.check-list-custom .mat-list-base{
  padding: 0;
}
.check-list-custom .mat-list-item-content.mat-list-item-content-reverse {
  flex-direction: unset !important;
  padding: 0 !important;
}
.check-list-custom .mat-list-text {
  margin-left: 8px;
    padding-right: 17px !important;
}
.check-list-custom .mat-list-base .mat-list-item, .mat-list-base .mat-list-option {
  display: inline-block !important;
  width: auto !important;
  height: auto !important;
}
.check-list-custom .mat-pseudo-checkbox-checked, .mat-accent .mat-pseudo-checkbox-checked {
  background: $primary-clr;
  border: 1px solid $white !important;
    outline: 1px groove;
}
.check-list-custom .mat-pseudo-checkbox::after{
content: unset;
}
.check-list-custom .mat-list-option:hover,.check-list-custom .mat-list-option:focus{
background-color: #fff;
}
.link-btn-client {
  text-decoration: underline;
  color: $primary-clr;
    padding: 0px;
    font-size: 14px;
    font-weight: 600;
    margin-right: 15px;
    margin-top: 3px;
}
.stright-label {
  padding-right: 18px;
}
.stright-label label {
  padding-right: 3px;
  color: $primary-clr;
  font-size: 14px;
  vertical-align: middle;
  margin-top: 6px;
  font-weight: 500;
}
.stright-label span {
  font-weight: 600;
    font-size: 14px;
    color: $primary-clr;
}
.doc_table td {
  vertical-align: middle !important;
}