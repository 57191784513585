.landing-page {
	.welcome-board {
		border: 2px solid $Akaroa;
		padding: 25px;
		border-radius: 20px;
	}
	.card {
		border-radius: 10px;
		border: none;
	}
	h1 {
		color: $primary-clr;
	}
	.sub-txt-board {
		font-size: 17px;
		font-weight: 400;
		color: $primary-clr;
        text-align: center;
	}
}
.welcome-txt{
    color: $primary-clr;
}
.thin-txt{
    font-weight: 300;
}
.welcome-img img {
    max-width: 300px;
}
.start-btn{
    color: $primary-clr;
    background: $secondary-clr;
    border-radius: 0;
    margin-top: 15px;
    margin-bottom: 15px;
    text-transform: uppercase;
    font-weight: 400;
    text-shadow: 0 -1px -1px $BuddhaGold;
  -moz-box-shadow: 0 4px 0 $BuddhaGold, 0 5px 5px 1px rgba(0, 0, 0, 0.4);
  -webkit-box-shadow: 0 4px 0 $BuddhaGold, 0 5px 5px 1px rgba(0, 0, 0, 0.4);
  box-shadow: 0 4px 0 $BuddhaGold, 0 5px 5px 1px rgba(0, 0, 0, 0.4);
  -moz-transition: all 0.15s ease-in-out;
  -o-transition: all 0.15s ease-in-out;
  -webkit-transition: all 0.15s ease-in-out;
  transition: all 0.15s ease-in-out;
}
.start-btn:hover {
    background-color: $secondary-clr-light;
    text-shadow: 0 -1px -1px $BuddhaGold;
    -moz-box-shadow: 0 4px 0 $BuddhaGold, 0 5px 5px 1px rgba(0, 0, 0, 0.4);
    -webkit-box-shadow: 0 4px 0 $BuddhaGold, 0 5px 5px 1px rgba(0, 0, 0, 0.4);
    box-shadow: 0 4px 0 $BuddhaGold, 0 5px 5px 1px rgba(0, 0, 0, 0.4);
    color: $black;
  }

// .bg-flower{
//     background: url(../../images/flower.png);
//     background-repeat: no-repeat;
//     background-position: 100% 90%;
//     background-size: 30%;
//     background-color: $white;
// }
// @media only screen and (max-width: 1200px) {
//     .bg-flower {
//         background-image: none;
//     }
//     }