.login-page {
.mat-select-value {
	span {
		float: left;
		height: 23px;
	}
}
// .mat-select-panel {
//     background: transparent;
//     box-shadow: none !important;
// }
// .mat-option.mat-active {
//     background: transparent !important;
//     box-shadow: none !important;
// }

}
.import-btn{
    background: $secondary-clr;
    color: $primary-clr;
    font-size: 13px;
    padding: 0 15px !important;
    height: 40px;
    border-radius: 0 !important;
    border: 2px solid $secondary-clr !important;
    text-transform: uppercase;
    &:hover{
        
        background-color: $secondary-clr;
        color: $primary-clr !important;
    }
}
.primary-btn-outlined{
    font-size: 13px;
    color: $primary-clr !important;
    line-height: 1.2 !important;
    text-transform: uppercase;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 15px !important;
    height: 40px;
    border: 2px solid $primary-clr !important;
    border-radius: 0 !important;
    &:hover{
        background-color: $primary-clr;
        color: $white !important;
    }
}
.primary-btn-outlined-small {
    font-size: 11px;
    color: $primary-clr !important;
    line-height: 1.2 !important;
    text-transform: uppercase;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 10px !important;
    height: 32px;
    border: 2px solid $primary-clr !important;
    border-radius: 0 !important;
    &:hover{
        background-color: $primary-clr;
        color: $white !important;
    }
}
.primary-btn-filled-small{
    font-size: 11px;
    color: $white !important;
    line-height: 1.2 !important;
    text-transform: uppercase;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 8px !important;
    height: 32px;
    background-color: $primary-clr;
    border: 2px solid $primary-clr !important;
    border-radius: 0 !important;
    &:hover{
        background-color: $primary-clr-dark;
        color: $white !important;
    }
}
.cancel-btn-outlined{
    font-size: 13px;
    color: #555 !important;
    line-height: 1.2 !important;
    text-transform: uppercase;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 15px !important;
    height: 40px;
    border: 2px solid $cancel-bg !important;
    border-radius: 0 !important;
    &:hover{
        background-color: $cancel-bg;
        color: $white !important;
    }
}
.primary-btn-filled{
    font-size: 12px;
    color: $white !important;
    line-height: 1.2 !important;
    text-transform: uppercase;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 15px !important;
    height: 40px;
    background-color: $primary-clr;
    border: 2px solid $primary-clr !important;
    border-radius: 0 !important;
    &:hover{
        background-color: $primary-clr-dark;
        color: $white !important;
    }
}

.btn-theme{
    background-color: $primary-clr !important;
    border-color: $primary-clr !important;
    color: $white !important;
    border-radius: 0 !important;
    text-transform: uppercase;
    font-size: 13px;
    height: 40px;
      padding-top: 10px;
  }
  .btn-cancel {
    background-color: $cancel-bg;
    color: #212529;
    border-radius: 0 !important;
    text-transform: uppercase;
    font-size: 12px;
    height: 40px;
    border: 2px solid $cancel-bg;
}
.theme-small {
    border: 1px solid $primary-clr !important;
    margin-right: 10px !important;
    color: $primary-clr !important;
    border-radius: 0 !important;
    font-weight: 400 !important;
  }
  .edit-btn{
	color:$primary-clr;
	background: $secondary-clr;
	border-radius: 0 !important;
    margin-top: 0px;
    margin-bottom: 0px;
    text-transform: uppercase;
    font-size: 13px;
    padding: 0 15px;
    height: 40px;
}
.prev-btn{
	background: $FrenchGray;
	color: $primary-clr;
	border-radius: 0 !important;
    margin-top: 15px;
    margin-bottom: 15px;
    padding: 0 15px !important;
    font-size: 13px;
}
// .primary-btn-s{
//     width: 100px;
// }
// .primary-btn-m{
//     width: 150px;
// }
.primary-btn-l{
    width: 215px;
}
// .primary-btn-w{
//     width: 100%;
// }
.h-40{
    height: 40px !important;
}
.mat-card, .mat-form-field, .mat-button, .mat-raised-button, .mat-icon-button, .mat-stroked-button, .mat-flat-button, .mat-fab, .mat-mini-fab {
    font-family: 'Poppins', sans-serif !important;
}
.login-section {
	.bg-image {
		background-image: url('../../images/EMR_login_new.jpg');
		// background-size: cover;
		// background-position: center;
        background-size: 100%;
    background-position: left;
	}
}
.login {
   // background: url(../../images/flower.png);
   // background-repeat: no-repeat;
    background-position: 100% 90%;
    background-size: 70%;
    background-color: $white;
	.forgot-pass {
		color: $primary-clr;
		font-weight: 500;
		text-transform: capitalize;
		font-size: 13px;
		&:hover {
			color: $primary-clr;
			font-weight: 500;
			text-transform: capitalize;
			font-size: 13px;
			text-decoration: underline;
		}
	}
	.rememberme {
		label {
			color: $primary-clr;
			font-weight: 500;
			text-transform: capitalize;
			font-size: 13px;
		}
		input {
			vertical-align: middle;
		}
	}
	.login-btn {
		background: $primary-clr;
		margin: 0 auto !important;
		padding: 0 30px !important;
		border-radius: 0 !important;
		span {
			color: $secondary-clr;
			text-transform: uppercase;
		}
	}
	height: 100vh;
}
.ui-float-label input {
    border-bottom: 1px solid;
    border-color: rgba(0,0,0,.42);
    border-radius: 0;
    margin-bottom: 10px;
}
@media only screen and (max-width: 1200px) {
.login {
    background: none;
}
}
.popup-design--replicate {
    position: absolute;
    left: -373px;
    top: 105px;
    width: 345px;
    background-color: $white;
    border-radius: 10px;
    padding:10px;
    border:1px solid $red;
}
.icon-align--newpop {
    position: absolute;
    right: -11px;
}
.check-custom.large-font .mat-checkbox-label {
    font-size: 1rem;
    font-weight: 400;
    color: inherit;
    line-height: inherit;
    white-space: normal;
}
.toggle-password {
    position: absolute;
    right: 11px;
    top: 13px;
}
.custom-password input {
    padding-right: 35px;
}
.custom-password {
    position: relative;
}
.client-mat-autocomplete-list {
    &.mat-autocomplete-panel {
        margin-top: 0 !important;
    }    
}
.client-mat-autocomplete {
    .mat-form-field {
        display: block;
        &-infix {
            border-top: none;
        }
    }
}