.main-navbar {
	.navbar {
		.user-avatar {
			max-width: 2.5rem;
		}
		>* {
			display: -ms-flexbox;
			display: flex;
		}
		.notifications {
			position: relative;
			.badge {
				position: absolute;
				padding: .25rem .375rem;
				font-size: .5rem;
				left: 50%;
				top: 16%;
			}
			.dropdown-menu {
				.dropdown-item {
					white-space: normal;
					display: -ms-flexbox;
					display: flex;
					-ms-flex-flow: row;
					flex-flow: row;
					padding-top: .625rem;
					padding-bottom: .625rem;
					border-bottom: 1px solid #e1e5eb;
				}
				padding: 0;
				min-width: 25rem;
				border-right: 0;
				left: auto;
				.notification__icon-wrapper {
					display: -ms-flexbox;
					display: flex;
					padding: 0 5px;
				}
				.notification__icon {
					background-color: $AthensGray;
					box-shadow: 0 0 0 1px $white,inset 0 0 3px rgba(0,0,0,.2);
					width: 2.1875rem;
					height: 2.1875rem;
					line-height: 0;
					display: block;
					text-align: center;
					margin: auto;
					border-radius: 50%;
					i {
						color: $gray-600;
						line-height: 2.0625rem;
						font-size: 1.0625rem;
						margin: 0;
					}
				}
				.notification__all {
					display: block;
					font-weight: 500;
					font-size: 11px;
					border-bottom-left-radius: .375rem;
					border-bottom-right-radius: .375rem;
				}
				.notification__category {
					font-size: 12px;
					color: $primary-clr;
					display: inline-block;
					text-transform: uppercase;
					margin-bottom: 5px;
					font-weight: 500;
					float: left;
					width: 330px;
				}
				.notification__content {
					.notify-italics{
						font-style: italic;
						color: $grey-bold;
						font-size: 10px;
					}
					p {
						margin: 0;
						line-height: 1.5;
						font-size: 11px;
						color: $Scorpion;
					}
				}
			}
		}
		.nav-link {
			min-width: 3.75rem;
			min-width: 3.75rem;
		}
		.nav-link-icon {
			i {
				line-height: 2.5rem;
			}
		}
		.navbar-nav {
			.dropdown-menu {
				position: absolute;
				right: 0;
				width: 100%;
				border-top-left-radius: 0;
				border-top-right-radius: 0;
				border-top: none;
			}
		}
	}
}
.nav-link-icon__wrapper {
	position: relative;
}
.nav-link-icon {
	i.material-icons {
		font-size: 1.5625rem;
	}
}
.notify-list {
    max-height: 360px;
    overflow: auto;
}
.dropdown {
	.material-icons {
		color: $SilverSand;
		margin-right: .25rem;
	}
}
i.material-icons {
	top: 2px;
	font-size: inherit;
	position: relative;
	top: 2px;
}
.badge-success {
	color: $white;
	background-color: $Salem;
}
.nav-link {
	font-size: .8125rem;
	font-weight: 400;
}
.dropdown-toggle {
	&::after {
		display: none;
	}
	i {
		color: $HitGray;
		padding: 0 10px;
	}
}
.header-logo {
	img {
		width: 350px;
		margin-left: 20px;
		margin-top: 5px;
	}
}
.profile-name {
	vertical-align: middle;
	font-weight: 700;
	color: $profilename;
	line-height: 14px;
	text-align: left;
	min-width: 60px;
}
.profile-desin {
	font-weight: 500;
	color: $primary-clr;
}
.dropdown-menu-small {
	box-shadow: 0 .5rem 2rem rgba(0,0,0,.11),0 3px 10px rgba(0,0,0,.05),0 2px 3px rgba(0,0,0,.06);
	padding: .25rem 0;
	font-size: .813rem;
	.dropdown-item {
		padding: .375rem .875rem;
		font-size: .813rem;
	}
}
.blue-mainbar {
	background: $primary-clr !important;
	margin-top: 12px;
	padding: 0;
	height: 50px;
	vertical-align: middle;
	.nav-link.active {
		color: $secondary-clr;
		font-weight: 500;
		&:before {
			content: "";
			position: absolute;
			bottom: 0;
			min-width: 3.75rem;
			min-width: 3.75rem;
			border-bottom: 4px solid $secondary-clr;
		}
	}
	.nav-link {
		&:hover {
			color: $secondary-clr;
			&:before {
				content: "";
				position: absolute;
				bottom: 0;
				min-width: 3.75rem;
				min-width: 3.75rem;
				border-bottom: 4px solid $secondary-clr;
			}
		}
		&:active {
			color: $secondary-clr;
			
		}
	}
}
.menu-value {
	a {
		color: $white;
		font-size: 11px;
		margin-right: 24px;
		text-transform: uppercase;
		font-weight: 400;
		padding-bottom: 0px;
		vertical-align: middle;
		position: relative;
		outline: none !important;
		padding-top: 0px;
		display: flex;
		align-items: center;
		height: 50px;
	}
}
.grid-btn {
	margin-top: 7px;
}
.form-control-underlined{
    border-width: 0;
    border-bottom-width: 1px;
    border-radius: 0;
    padding-left: 0;
    background: transparent;
    margin-top: 10px;
    margin-right: 10px;
    border-color: $BreakerBay !important;
    padding-right: 25px;
    padding-bottom: 0;
}
.form-control-feedback {
    position: absolute;
    z-index: 2;
    display: block;
    width: 2.375rem;
    height: 2.375rem;
    line-height: 2.375rem;
    text-align: center;
    pointer-events: none;
    color: $BreakerBay !important;
    right: 0;
    top: 13px;
}
.form-control-underlined::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color:  $BreakerBay;
    opacity: 1; /* Firefox */
}

.form-control-underlined:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color:  $BreakerBay;
}

.form-control-underlined::-ms-input-placeholder { /* Microsoft Edge */
    color:  $BreakerBay;
}
.menu-value a.dropdown-item {
    color: #173a64;
    font-size: 12px;
    margin: 0;
    border-bottom: 1px solid #efefef;
    text-transform: capitalize;
    font-weight: 500;
    padding-top: 10px;
}
.menu-value .mat-button.mat-button-base {
    color: $white;
    font-size: 17px;
    margin-right: 25px;
    text-transform: uppercase;
    font-weight: 200;
    padding-bottom: 12px;
    vertical-align: middle;
	border-bottom: 4px solid $primary-clr;
	padding: .5rem 1rem;
    line-height: normal;
}
 .mat-menu-panel .mat-menu-item {
    border-bottom: 1px solid $Gallery;
		padding-top: 7px;
		padding-bottom: 7px;
		height: 36px;
		line-height: 20px;
}
.mat-menu-panel .mat-menu-item:hover {
    color: $white;
    background: $primary-clr !important;
}
.menu-value .mat-button.mat-button-base:hover {
border-bottom: 4px solid $secondary-clr;
color: $secondary-clr;
font-weight: 500;
}

@media only screen and (max-width: 600px) {
    .header-logo img {
        width: 181px;
        margin-left: 20px;
        margin-top: 18px;
    }
  }
  .dropdown-header {
    background: $CatskillWhite;
    padding: 15px;
    position: relative;
    text-align: center;
    color: $primary-clr;
    font-weight: bold;
    border-radius: 10px 10px 0 0;
    border: 0px;
    border-style: solid;
    border-bottom-width: 1px;
    -moz-border-image: -moz-linear-gradient(right, $white, $Geyser, $Geyser, $white) 1 20%;
    -o-border-image: -o-linear-gradient(right, $white, $Geyser, $Geyser, $white) 1 20%;
    border-image: linear-gradient(to right, $white 0%, $Geyser 40%, $Geyser 60%, $white 100%) 1 20%;
    box-shadow: 0px 2px 10px -2px #a0a7aa;
}
.dropdown-header .triangle {
    position: absolute;
	top: -13px;
    left: 190px;
    height: 26px;
    width: 26px;
    border-radius: 6px 0px 0px 0px;
    transform: rotate(45deg);
    background: $CatskillWhite;
}
.notifications .unread {
    border-left: 5px solid $drop-bor;
    background: $white;
}
.notifications .read-notify{
	border-left: 5px solid $white !important;
    background: $white;
}
.notifications .read-notify .notification__category{
	font-weight: 600 !important;
    color: $Edward !important;
}
.notifications .read-notify p{
    color: $SilverSand !important;
}
.notifications .time-stam{
	color: $SilverSand !important;
}
.main-navbar .navbar .notifications .dropdown-menu .dropdown-item:hover p {
    color: $Scorpion !important;
}
.main-navbar .navbar .notifications .dropdown-menu .dropdown-item:hover .notification__category{
    color: $Scorpion !important;
}
.main-navbar .navbar .notifications .dropdown-menu .dropdown-item:hover .make-unread{
  display: block;
}
.notification__content{
	position: relative;
}
.make-unread {
    position: absolute;
    right: 0;
    top: 0px;
    display: none;
	cursor: pointer;
}
.make-unread .fas {
   color: $drop-bor;
   font-size: 18px;
}
.clear-action {
    border-bottom-left-radius: 0.375rem;
    border-bottom-right-radius: 0.375rem;
    padding: 15px 10px;
    float: left;
    width: 100%;
}
.clear-action a{
	color: $Neptune;
	font-size: 12px;
	font-weight: 500;
}
.notifications .dropdown-menu {
    left: -172px !important;
}

.notify{
	.mat-column-status {
		word-wrap: break-word !important;
		white-space: unset !important;
		flex: 0 0 8% !important;
		width: 8% !important;
		overflow-wrap: break-word;
		word-wrap: break-word;
	  
		word-break: break-word;
	  
		-ms-hyphens: auto;
		-moz-hyphens: auto;
		-webkit-hyphens: auto;
		hyphens: auto;
	  }
	  .mat-column-createdOn{
		word-wrap: break-word !important;
		white-space: unset !important;
		flex: 0 0 10% !important;
		width: 10% !important;
		overflow-wrap: break-word;
		word-wrap: break-word;
	  
		word-break: break-word;
	  
		-ms-hyphens: auto;
		-moz-hyphens: auto;
		-webkit-hyphens: auto;
		hyphens: auto;
	  }
	  .mat-cell {
		padding-left: 10px;
	}
}
.viewall-btn {
    color: $drop-bor;
    font-size: 12px;
    font-weight: 500;
    text-decoration: underline;
}
.loc-changer .mat-form-field-wrapper {
    padding: 0;
    padding-right: 14px !important;
    margin-top: 9px;
}
.loc-changer mat-select{
    color: #fff;
    background: #173a64;
    border: 2px solid #fff;
    border-radius: 5px;
	outline: none;
	padding: 5px;
}
.loc-changer mat-option {
    background: #fff;
    color: #000;
}
.loc-changer .mat-select-value-text {
    color: #fff;
}
.loc-changer .mat-select-arrow {
	color: #fff !important;
}
.loc-changer .mat-form-field-underline{
	display: none;
}
.staff-header {
    // border-top: 2px solid #e3e3e3;
}
.main-navbar .navbar .user-avatar {
    width: 2.5rem;
    height: 2.5rem;
}
.doc_table td, .doc_table th {
    padding: 6px;
}