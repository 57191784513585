.financial-page{

  .fin-label{
    font-weight: 500;
    text-transform: uppercase;
    margin-bottom: 7px;
    color: $primary-clr;
  }
  .hint-cover{
    position: relative;
  }
  .hint {
    font-size: 11px;
    color: $primary-clr;
    width: 100%;
    border: 1px solid #999999;
    padding: 5px;
    display: none;
    position: absolute;
}
.hint-cover:hover .hint{
  display: block;

}
}
.finance-view{
  .form-control-static{
      background: $Zumthor;
      padding: 10px;
      border-radius: 5px;
      font-size: 14px;
  }
  .control-label {
    font-size: 12px;
    font-weight: bold;
    color: $primary-clr;
    
}
}
.price-category{
  background: aliceblue;
  padding: 10px;
  border-radius: 3px;
  margin-bottom: 15px;
}
.secondary-btn-filled {
  background: $secondary-clr;
  color: $primary-clr;
  text-transform: uppercase;
  font-size: 12px;
  height: 40px;
  border: 2px solid $secondary-clr;
  border-radius: 0 !important;
}
.margin-up{
  margin-top: -11px;
}
.finance-modal .modal-dialog {
  max-width: 700px;
  margin: 1.75rem auto;
}
.finance-modal .heading {
  color: #173a64;
  font-weight: 500;
  font-size: 15px;
}
.finance-modal .modal-header {
  padding: 9px 17px;
}
.pricelistview label {
  width: 100%;
  font-size: 14px;
  // padding: 10px;
  float: left;
  border: none;
  border-radius: 0;
  font-weight: 500;
}
.pricelistview {
  background: aliceblue;
  padding: 20px 10px;
  border-radius: 5px;
}

.pricelistview input {
  background: #fff !important;
  width: 100%;
  padding: .375rem .75rem;
  float: left;
  border: 1px solid #ced4da;
  border-radius: .25rem;
  height: calc(1.5em + .75rem + 2px);
  margin-top: 0 !important;
  line-height: 1.5 !important;
  font-size: 1rem;
    font-weight: 400;
}
.choosing-label {
  font-weight: 500;
  color: $primary-clr;
}
.selecting-comp {
  position: absolute;
  top: 1px;
  left: 30px;
}
.Catalogue-view-list li.active .card{
  border: 2px solid $primary-clr;
}
.h-vh-75{
  height: 75vh;
}
.finance-modal {
  margin-top: -17px;
}
// .finance-modal .input-group-text {
//   background: #efeffe;
//   border: none;
// }
.finance-modal {
  margin-top: -17px;
  font-size: 14px;
}
.finance-review .input-group-text {
  background: #efeffe;
  border: none;
}
.proposal-form {
  border: 1px solid #c9c9c9;
  padding: 25px;
}
.req-tour .card-header {
  background: #173a64;
  color: #fff;
}
.label-tour {
  font-weight: 500;
  font-size: 14px;
}
.accept-btn .btn{
  border-radius: 0 !important;
  padding: 0 15px !important;
  height: 40px;
}
.btn-yes {
  border: 2px solid $primary-clr;
  border-radius: 0;
  padding: 0 15px;
  height: 30px;
  color: $primary-clr;
  font-size: 14px;
  background-color: $white;
}
.btn-yes:hover {
  border: 2px solid $primary-clr;
  background-color: $primary-clr;
  border-radius: 0;
  padding: 0 15px;
  height: 30px;
  color: $white;
  font-size: 14px;
}
.fill-header {
  background: #e3e3e3;
}
.custom-radio .mat-radio-container{
  height: 15px;
  width: 15px;
}
.custom-radio .mat-radio-outer-circle{
  height: 15px;
  width: 15px;
}
.custom-radio .mat-radio-inner-circle{
  height: 15px;
  width: 15px;
}
.custom-radio .mat-radio-button .mat-radio-ripple{
  height: 20px; /*double of your required circle radius*/
  width: 20px;  /*double of your required circle radius*/
  left: calc(50% - 10px); /*'10px'-same as your required circle radius*/
  top: calc(50% - 10px); /*'10px'-same as your required circle radius*/
}
.custom-radio .mat-radio-label-content {
  padding-left: 5px !important;
  font-size: 14px;
}
.deduct-value-list {
  margin-top: -20px !important;
}
.deduct-value-list .mat-select-value-text {
  font-size: 15px;
}
.insu-name {
  font-weight: 600;
  color: #000;
}
.deny-modal textarea {
  height: 100px;
}
.pricelistview-review label {
  width: 100%;
  font-weight: 500;
  background: #efefef;
  margin-bottom: 10px;
  padding: 5px;
}
.pricelistview-review p {
  background: #efefef;
  padding: 10px;
  width: 100%;
  font-weight: 500;
  min-height: 44px;
}
.tour-form label {
  margin-top: 23px;
  font-weight: 500;
}
.req-tour-section {
  background: aliceblue;
  // margin-top: 30px;
  margin-bottom: 5px;
  padding: 10px;
}
.calendar-small {
  font-size: 12px;
  .btn-group .btn {
    background: #3e689b;
    border-color: #3e689b;
    font-size: 12px;
    line-height: 22px;
    color: #fff;
    border-radius: 0 !important;
    i {
      color: #fff;
  }
  }
  .cal-month-view .cal-day-cell.cal-today {
    background-color: #387db8;
    color: #fff;
    opacity: 1;
}
  .cal-day-badge {
    background-color: #3e689b;
    color: #fff;
}
 .cal-month-view .cal-day-cell.cal-weekend .cal-day-number {
  color: #3e689b !important;
}
.cal-month-view .cal-day-cell.cal-today .cal-day-number {
  font-size: 1.4em;
}
  .cal-month-view  {
    min-height: 50px !important;
  }
  .cal-day-cell {
    min-height: 50px !important;
  }
  .cal-month-view .cal-cell-top {
    min-height: 50px;
  }
  .btn.btn-primary.active {
    background-color: #3e689b !important;
    border-color: #3e689b !important;
   }
   .btn.btn-outline-secondary {
    font-size: 12px;
    line-height: 22px;
    background: #3e689b!important;
    color: #ffffff !important;
}
  h3 {
    text-align: center;
    font-size: 17px;
    background: #3e689b;
    padding: 10px;
    color: #fff;
}
.cal-day-badge {
  display: none;
}
.modal-dialog {
  max-width: 800px;
}
.modal {
  margin-top: 102px;
}
}
.calendar-picked{
  background: #fff;
  .mat-calendar-controls {
    margin-top: 0;
}
.mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background: #efefef;
}
}
.confirm-schedule {
  font-size: 12px !important;
}
.dotted-border .mat-checkbox{
  padding: 10px;
    border: 1px dotted;
    vertical-align: middle;
}
.cal-events {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-block !important;
  width: 52px;
}
.new-check .mat-select-panel .mat-option {
  height: 3em !important;
}
.finance-modal .pricelistview {
  overflow-y: auto;
  max-height: 230px;
  overflow-x: hidden;
  padding:10px;
}
.heading-price-list{
  font-weight: 600 !important;
}
.custom-multiselect .mat-form-field-underline {
  display: none;
}
.custom-multiselect .mat-form-field-infix {
  border: 1px solid #c5c5c5;
  border-radius: 3px;
}
.custom-multiselect .mat-select-value {
  padding-left: 10px !important;
}
.custom-multiselect .mat-empty.mat-form-field-empty mat-label {
  padding-left: 10px !important;
}
.custom-multiselect.mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label, .custom-multiselect.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus+.mat-form-field-label-wrapper .mat-form-field-label{
display: none;
}