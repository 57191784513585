.schdule-modality h2::after {
  content: "";
  position: relative;
  display: block;
  width: 30px;
  height: 3px;
  background: #173a64;
  margin-top: 5px;
  left: 1px;
}
.schdule-modality h2 {
  color: #173a64;
  font-size: 15px;
  margin-bottom: 20px;
}
.scheduleAppointemnts h2::after {
  content: "";
  position: relative;
  display: block;
  width: 30px;
  height: 3px;
  background: #173a64;
  margin-top: 5px;
  left: 1px;
}
.scheduleAppointemnts h2 {
  color: #173a64;
  font-size: 15px;
  margin-bottom: 20px;
}
.radio-width {
  width: 65px;
}
.w-161{
  width: 161px;
}
.w-205 {
  width: 205px;
}
.top-back {
  font-size: 15px;
  color: #173a64;
  font-weight: 600;
  text-transform: uppercase;
}
.schdule-modality .form-control{
  font-size: 0.8rem;
}
.schdule-modality .mat-error{
  font-size: 11px;
}
.conflict-cell {
  border: 1px solid #ff0000 !important;
  background: #f5e1e1;
  color: #c92323;
}
.top-2-modality {
  background: aliceblue;
  padding: 10px;
}
.one-style h1 {
  text-align: center;
  text-transform: uppercase;
  padding-bottom: 5px;
  margin-bottom: 12px;
  margin-top: 12px;
  position: relative;
  font-size: 17px;
  font-weight: 600;
  color: #173a64;
}
.schedule-summary {
  padding: 1.5rem;
  width: 100%;
  background-color: #f5f7ff;
  border-radius: 0.6875rem;
  margin-bottom: 2rem;
  text-align: center;
  color: #1f2f56;
  font-weight: 500;
  font-size: 14px;
}
.one-style h1:before {
  width: 28px;
  height: 5px;
  display: block;
  content: "";
  position: absolute;
  bottom: 3px;
  left: 50%;
  margin-left: -14px;
  background-color: $primary-clr;
}
.one-style h1:after {
  width: 100px;
  height: 1px;
  display: block;
  content: "";
  position: relative;
  margin-top: 13px;
  left: 50%;
  margin-left: -50px;
  background-color: $primary-clr;
}
.Modality-view-make label {
  font-weight: 500;
  color: #000;
  font-size: 14px;
}
.view-value {
  font-size: 14px;
  color: #8d8d8d;
}
.info-modality {
  padding: 5px;
  font-size: 13px;
  font-weight: 500;
  border: 1px solid #ccc;
  border-radius: 3px;
  border-left: 4px solid green;
  box-shadow: 0 0 10px #ccc;
}
.options-tabs .nav-link.active {
  color: #173a64;
  font-weight: 600;
}
.options-tabs .nav-link:hover {
  color: #173a64;
  font-weight: 600;
}
.options-tabs .nav-link {
  color: #a0a0a0;
}
.options-tabs .nav-tabs .nav-item.show .nav-link,.options-tabs .nav-tabs .nav-link.active {
  color: #495057;
  background-color: #fff;
  border-color: #bfbfbf #bfbfbf #fff;
}
.options-tabs .nav-tabs {
  border-bottom: 1px solid #a3a3a3;
}
.summary-slot {
  font-size: 14px;
  color: #626262;
}
.summary-slot span {
  padding-left: 10px;
  color: #000;
  font-weight: 500;
}
.slot-txt {
  padding: 0;
  font-weight: 400;
  color: #000;
}
.sub-inc span {
  color: #41b59a;
}
.modality-table thead {
  background: $primary-clr;
  border: 1px solid #efefef;
  color:#fff;
}

.modality .cal-week-view .cal-time-events .cal-event {
    
  height: auto !important;
}
.modality .event-anchor span {
  color: #000 !important;
}
.modality .event-anchor a {
  color: rgb(102, 93, 235) !important;
  text-decoration: underline;
}
.re-schedule-popup .modal-dialog {
  top: 13%;
  width: 500px;
  min-width: 500px;
  height: auto !important;
}
.choose-date {
 // width: 120px;
  margin-left: 15px;
  
  .mat-form-field-wrapper {
    padding-bottom: 0;
  }
}
.cancel-tour .modal-dialog {
  top: 40%;
  width: 500px;
  min-width: 500px;
  height: auto !important;
}
// .cancel-tour .btn {
//   width: 60px;
// }
.top-details-info label {
  font-size: 14px;
  margin-right: 15px;
  margin-bottom: 0;
  font-weight: 600;
  color: #173a64;
}
.top-details-info div {
  font-size: 14px;
  color: #2e2e2e;
  font-weight: 500;
}
.btn-links {
  font-size: 12px;
  border: 1px solid;
  background: #efefef;
  padding: 5px;
  margin-top: 10px;
}
.btn-links:hover {
  background: #555 !important;
  color: #fff;
}
.schedule-pop-head{
  font-size: 14px;
    width: 100%;
    padding: 5px;
    background: #f3f3f3;
    margin-left: -5px;
}
.link-trans {
  float: left;
  width: 100%;
  font-size: 13px;
  text-decoration: underline;
  margin-bottom: 5px;
}
.schedule-time-head {
  color: #000 !important;
  font-weight: 600 !important;
}
.head-top-schedule {
  display: block;
  padding: 10px 0;
  font-weight: 500;
  border-bottom: 1px solid #ccc;
  margin-bottom: 10px;
}
.btn-rad-add {
  border-radius: .25rem !important;
  height: auto !important;
}
.small-select select {
  font-size: 13px;
  width: 180px;
  padding-left: 5px;
  }
  .schedule-popup {
    top: 30%;
}
.date-hide .mat-form-field-infix {
  display: none;
}
.date-hide .mat-form-field-underline {
  display: none;
}
.info-tag {
  background: #f6ffa7;
  padding: 5px;
  border-radius: 5px;
  padding-left: 9px;
}
.anyclick-popup .modal-dialog {
  top: 0%;
  width: 1000px;
  min-width: 1000px;
  height: auto !important;
  .form-autocomplete {
    position: relative;
    .p-autocomplete {
      display: block;
    }
    .search-icon {
      background: #fff;
      color: #727171;
      font-size: 22px;
      position: absolute;
      left: 8px;
      top: 8px;
    }
    .p-inputtext {
      height: 37px;
      width: 100%;
      padding-left: 35px;
    }
  }
}
.auth-popup .modal-dialog {
  top: 14%;
  width: 1000px;
  min-width: 1000px;
  height: auto !important;

}
.insurance-img .modal-dialog {
  top: 14%;
  width: 1000px;
  min-width: 1000px;
  height: auto !important;

}
.blocktime-popup .modal-dialog{
  width: 800px;
  min-width: 800px;  
  // width: auto !important;
  // min-width: auto !important;
  height: auto !important;
 
}
.blocktime-popup .modal-dialog textarea.form-control{
  resize: none !important;
}
.Treatment-popup .modal-dialog{
  width: 820px;
  min-width: 820px;  
  // width: auto !important;
  // min-width: auto !important;
  height: auto !important;
  max-height: 400px;
  overflow-y: auto;
 
}
.Treatment-popup .modal-dialog textarea.form-control{
  resize: none !important;
}
.modal-content-scrollable {
  /* Set a height for the content */
  max-height: 300px; /* Adjust the max-height as needed */
  overflow-y: auto;
}
.selecttime-popup .modal-dialog{
  top: 40%;
}

.body-billed-value {
  overflow-x: hidden;
  max-height: 382px;
  overflow-y: auto;
}
.intake-reschedule-popup {
  top: 20%;
}

.intake-reschedule-popup .modal-body{
    height: 50vh;
    overflow-y: auto;
 // overflow-x: auto;
}
.schedular .cal-week-view .cal-time-events .cal-event {
  padding:0px !important;
}

.Notes-popup .modal-dialog{
  width: 950px;
  min-width: 950px;  
  // width: auto !important;
  // min-width: auto !important;
  height: auto !important;
  max-height: 400px;
  overflow-y: auto;
 
}