.search {
  width: 100%;
  margin-bottom: auto;
  margin-top: 0px;
  height: 50px;
  background-color: #fff;
  padding: 10px;
  border-radius: 5px
}

.search-input {
  color: white;
  border: 0;
  outline: 0;
  background: none;
  width: 0;
  margin-top: 5px;
  caret-color: transparent;
  line-height: 20px;
  transition: width 0.4s linear
}

.search .search-input {
  padding: 0 10px;
  width: 100%;
  caret-color: $primary-clr;
  font-size: 16px;
  font-weight: 300;
  color: black;
  transition: width 0.4s linear
}

.search-icon {
  height: 34px;
  width: 34px;
  float: right;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  background-color: $primary-clr;
  font-size: 10px;
  bottom: 30px;
  position: relative;
  border-radius: 5px;
  text-decoration: none !important;
    cursor: pointer;
}

.search-icon:hover {
  color: #fff !important
}
.val-list {
  font-size: 14px;
  color: #173a64;
}
.title-consent {
  font-weight: 600;
}
.list-template ul {
  list-style-type: none;
  padding: 0;
}
.invi-check {
  position: absolute;
  left: 20px;
  top: 23px;
}
.result_action {
  font-size: 15px;
}
.status {
  font-weight: 500;
}
.for_approval{
  color:$ChateauGreen;
}
.for_reject{
  color:$Cardinal;
}
.line-btn {
  border: 1px solid $primary-clr;
  margin-right: 7px;
  font-size: 12px;
  border-radius: 0;
  color: $primary-clr;
}
.list-template li {
  margin-bottom: 10px;
}
.field-head {
  font-weight: 500;
  color: $white  !important;
 
}
.mat-expansion-indicator::after{
  color: $white !important;
}
mat-expansion-panel-header{
  background: $primary-clr !important;
}
.field-value {
  float: left;
  width: 100%;
  padding: 8px 8px;
  color: $primary-clr !important;
  font-size: 16px;
}
mat-expansion-panel {
  margin-bottom: 6px !important;
}
.previewmodel .modal-dialog {
  width: 850px;
  max-width: 850px !important;
}
// .previewmodel .modal-body {
//   overflow: scroll;
// }
.preview-body {
  max-height: 550px;
  overflow-y: auto;
}
.button-position{
  position: relative;
  top:-10px;
}
// .consent-page .mat-tab-group {
//   flex-direction: row;
// }
// .consent-page .mat-tab-header {
//   border-bottom: none;
// }
// .consent-page .mat-tab-header-pagination {
//   display: none !important;
// }
// .consent-page .mat-tab-labels {
//   flex-direction: column;
// }
// .consent-page .mat-ink-bar {
//   height: 100%;
//   left: 100% !important;
// }
// .consent-page .mat-tab-body-wrapper {
//   flex: 1 1 auto;
// }
.consent-page .mat-tab-label {
  background: $white;
  color: $primary-clr;
  margin-right: 5px;
  box-shadow: 0 7px 10px #e8e7e7;
  margin-bottom: 2px;
  opacity: 1;
}
.consent-page .mat-tab-label-active {
  background: $primary-clr;
  color: $secondary-clr;
  margin-right: 5px;
}
.custom-textarea-new-large{
  width: 100%;
    border-radius: 5px;
    border-color: #a6a5a5;
    height: 175px;
    font-size: 14px;
    margin-bottom: 10px;
}
.no-price {
  float: left;
  width: 100%;
  padding: 18px 10px;
  text-align: center;
  background: #b9c6d7;
  margin-top: 10px;
  margin-bottom: 10px;
  border: 1px dotted;
  color: $primary-clr !important;
  text-decoration: none !important;
}
.yes-no-insurance{
  vertical-align: sub;
}

.small-heading-label{
  color:$primary-clr;
  font-size: 16px;
  font-weight: 400;
}
.list-head {
  font-size: 13px;
  font-weight: 600;
  color: #173a64;
  text-transform: capitalize;
}
.list-value {
  font-size: 13px;
    float: left;
    width: 100%;
    font-weight: 500;
    margin-bottom: 5px;
}
.sub-head-list {
  text-decoration: underline;
  margin-bottom: 3px;
}
.catalogue-lsit {
  padding: 5px 15px;
}
.list-value-new {
  font-size: 13px;
  font-weight: 500;
  margin-bottom: 5px;
}
.create-consent-page quill-editor {
  min-height: 400px !important;
  float: left;
}
.create-consent-page .ql-container {
  height: 89%;
}
.empty-data {
  width: 100%;
  background: #fff;
  text-align: center;
}
.deductible mat-radio-button {
  font-weight: 500;
}
.cover-option mat-radio-button {
  font-weight: 500;
}
.deduct-value {
  margin-left: 60px;
  vertical-align: middle;
  margin-top: -12px;
}
.highlight-text {
  font-size: 16px;
  font-weight: 600;
  padding-right: 5px;
}
.small-text-highlight {
  font-size: 13px;
}
.price-label {
  font-size: 15px;
  margin-bottom: 0;
}
.deduct-label {
  font-size: 14px;
  margin-bottom: 0;
}
.deduct-label-value{
  font-size: 13px;
}
.custom-price-select {
  height: 30px;
  font-size: 12px;
  padding-right: 0px;
}
.consent-ques.pr-3 {
  font-weight: 500;
  vertical-align: top;
  margin-top: 5px;
  display: inline-block;
}
.temp-head {
  padding-left: 0;
  font-weight: 700;
}
.temp-node i {
  font-size: 10px;
  color: #173a64;
  float: left;
  height: 23px;
  margin-top: 6px;
}
.create-date .val-list {
  font-weight: normal;
  font-size: 12px;
  color: #676767;
}
.esign-labled .list-head{
  font-size: 15px !important;
  font-weight: 500 !important;
}
.party-doc {
  font-size: 14px;
  font-weight: 500;
  color: $primary-clr;
}
.ql-clipboard {
  position: fixed;
  display: none;
  
  left: 50%;
  top: 50%;
  }

  .goalstable .mat-column-sno  {
    word-wrap: break-word !important;
    white-space: unset !important;
    flex: 0 0 10% !important;
    width: 10% !important;
    overflow-wrap: break-word;
    word-wrap: break-word;
    word-break: break-word;
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;
  }
  .goalstable .mat-column-goalName   {
    word-wrap: break-word !important;
    white-space: unset !important;
    flex: 0 0 20% !important;
    width: 20% !important;
    overflow-wrap: break-word;
    word-wrap: break-word;
    word-break: break-word;
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;
  }
  .goalstable .mat-column-goalName   {
    word-wrap: break-word !important;
    white-space: unset !important;
    flex: 0 0 15% !important;
    width: 15% !important;
    overflow-wrap: break-word;
    word-wrap: break-word;
    word-break: break-word;
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;
  }
  .goalstable .mat-column-definition    {
    word-wrap: break-word !important;
    white-space: unset !important;
    flex: 0 0 30% !important;
    width: 30% !important;
    overflow-wrap: break-word;
    word-wrap: break-word;
    word-break: break-word;
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;
  }
  .goalstable .cdk-column-definition ul{
    padding-left: 10px;
  }
  .goalstable .cdk-column-definition ol{
    padding-left: 10px;
  }
  .goalstable .mat-column-timeUnits     {
    word-wrap: break-word !important;
    white-space: unset !important;
    flex: 0 0 10% !important;
    width: 10% !important;
    overflow-wrap: break-word;
    word-wrap: break-word;
    word-break: break-word;
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;
  }
  .goalstable .mat-column-timeType     {
    word-wrap: break-word !important;
    white-space: unset !important;
    flex: 0 0 10% !important;
    width: 10% !important;
    overflow-wrap: break-word;
    word-wrap: break-word;
    word-break: break-word;
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;
  }
  .goalstable .mat-column-actions     {
    word-wrap: break-word !important;
    white-space: unset !important;
    flex: 0 0 10% !important;
    width: 10% !important;
    overflow-wrap: break-word;
    word-wrap: break-word;
    word-break: break-word;
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;
  }
  .price-textarea{
    height: 275px;
  }