.details-head .top-label {
    font-size: 15px;
    color: #173a64;
    font-weight: 600;
    text-transform: uppercase;
}
.user-avatar1 img {
    width: 80px;
}
.user-avatar1 {
    display: table-cell;
    vertical-align: middle;
}
.user-info {
    display: table;
}
.user-data {
    display: table-cell;
    vertical-align: middle;
    text-align: left;
    padding-left: 10px;
}
.username{
	font-size: 15px;
    color: $primary-clr;
    font-weight: 600;
	text-transform: uppercase;
}
.username-thin{
	font-size: 13px;
    color: $primary-clr;
    font-weight: 500;
	//text-transform: capitalize;
}
.username-thin-lower{
	font-size: 15px;
    color: $primary-clr;
    font-weight: 500;
	text-transform: lowercase;
}
.sub-text-grey{
	font-size: 13px;
    color: $DoveGray;
    font-weight: 500;
    margin-bottom: 7px;
	text-transform: uppercase;
}
.sub-text-grey-small{
	font-size: 10px;
    color: $DoveGray;
    font-weight: 500;
    margin-bottom: 7px;
	text-transform: capitalize;
}
.gender-img i {
    color: #173a64;
    font-size: 27px;
}
.details-card .circle {
    position: absolute;
    top: -10px;
    left: -6px;
    font-size: 22px;
}
.details-card  {
	overflow: hidden;
}

.mat-tab-label-active {
    background: $primary-clr;
    color: $secondary-clr;
    margin-right: 5px;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
	opacity: 1 !important;
}
.mat-tab-label {
    min-width: 100px !important;
    text-transform: uppercase;
	font-family: 'Poppins', sans-serif;
}
.mat-tab-group{
	font-family: 'Poppins', sans-serif;
}
.mat-ink-bar {
    height: 4px !important;
	background: $secondary-clr !important;
}
.mat-tab-header {
    border-bottom: none !important;
}
.first-head{
    color: $primary-clr;
}
.fieldset-custom{
    display: block;
    margin-inline-start: 2px;
    margin-inline-end: 2px;
    padding-block-start: 0.35em;
    padding-inline-start: 0.75em;
    padding-inline-end: 0.75em;
    padding-block-end: 0.625em;
    min-inline-size: min-content;
    border-width: 2px;
    border-style: groove;
    border-color: threedface;
    border-image: initial;
    width: 100%;
    float: right;

}
.fieldset-custom legend{
    font-size: 13px;
    width: 123px;
    margin-bottom: 0;
}
.fieldset-custom .mat-form-field{
    height: 10px;
}
.fieldset-custom .mat-form-field-infix {
    padding: 0;
}
/*note*/
.control-label-new {
    padding: 0;
    font-size: 14px;
    color: $primary-clr;
  }
  .checkininput{
 .mat-select-value{
  color: #495057;
 }
}
  .custom-textarea-new {
    width: 100%;
    border-radius: 5px;
    border-color: #a6a5a5;
    height: 150px;
  }
 
  .list-note {
    box-shadow: 0 0 10px rgb(219 219 219);
    padding: 10px;
  }
  .list-note .icon-note {
    color: #313131;
    font-size: 17px;
  }
  .list-note .icon-note:hover {
    color: $primary-clr-dark;
  }
  .username-sec {
    vertical-align: text-top;
      font-weight: 500;
      font-size: 15px;
  }
  .note-content {
    font-size: 14px;
  }
  .timeanddate {
    font-size: 13px;
    background: #cbddfe;
    float: right;
    padding: 5px;
    border-radius: 0;
    color: $primary-clr;
  }
  .time-label {
    font-weight: 500;
    padding-right: 10px;
  }
  .mat-primary.mat-standard-chip.mat-chip-with-trailing-icon,.mat-standard-chip{
    background: $AquaSqueeze !important;
      border: 1px solid $Nevada !important;
      color: $Nevada !important;
      border-radius: 0 !important;
      font-size: 10px;
      padding: 5px !important;
      height: 24px !important;
      min-height: 24px !important;
  }
  .h-vh-55{
    height: 55vh;
  }
  .notes-modal{
    margin-top: 126px;
  }
  .tag-label {
    float: left;
    font-size: 13px;
    font-weight: 600;
    margin-right: 10px;
    margin-top: 3px;
    color:$primary-clr;
  }
  .invi-check2 {
    position: absolute;
    left: 3px;
    top: 4px;
  }

  .notes-section .mat-standard-chip {
    font-size: 13px;
}
  /*note*/
  .previewmodel .ql-editor {
    font-size: 12px;
    white-space: pre;
}
.notes-section .ql-mention-list {
  list-style-type: none;
  background: #fff;
  padding: 0;
  overflow: auto;
  max-height: 100px;
  border: 1px solid rgba(0,0,0,.125);
  z-index: 999999;
    position: absolute;
}
.notes-section .ql-mention-list-item {
  position: relative;
  display: block;
  padding: 0.45rem 0.50rem;
  margin-bottom: -1px;
  background-color: #fff;
  border-top: 1px solid rgba(0,0,0,.125);
}
.notes-section .mention {
  color: #2121df;
}
p.note-content p {
  margin: 0;
}
.notes-section .ql-container {
  height: 69%;
}
.notes-section quill-editor {
  height: 195px !important;
}
.task-notes .ql-container {
  height: 68%;
}
.task-notes quill-editor {
  height: 200px;
}
.task-create-section {
  width: 100%;
}
.mat-error {
  line-height: normal;
}
.list-note .mention {
  display: none;
}
.view-note .mat-chip.mat-standard-chip {
  font-size: 13px;
}
.mat-paginator .mat-form-field-underline {
  display: none;
}
.mat-paginator .mat-form-field-infix {
  border: 1px solid #c9c9c9;
  border-radius: 3px;
  padding-left: 2px;
  padding-right: 2px;
}
.mat-paginator-range-label {
  font-weight: 500;
}
.mat-paginator .mat-paginator-page-size-label {
  font-weight: 500;
}
.mat-paginator-page-size {
  margin-top: 10px;
}
.custom-calendar {
  font-size: 13px;
  padding: 20px;
  margin-top: 13px;
}
.custom-calendar .btn.btn-primary {
  background: #efefef;
  border-color: #9e9e9e;
  font-size: 12px;
  line-height: 22px;
  color: #000;
}
.custom-calendar .btn.btn-primary.active{
  background-color: #3e689b !important;
  border-color:#3e689b !important;
}
.custom-calendar .btn-primary:not(:disabled):not(.disabled):active{
  color: #262954;
    background-color: #efefef;
    border-color: #efefef;
}
.custom-calendar .cal-month-view .cal-open-day-events {
  color: #222;
  background-color: #fff;
}
.custom-calendar  .btn.btn-outline-secondary {
  font-size: 12px;
  line-height: 22px;
  background: #fff !important;
  color: #000 !important;
}
.custom-calendar .row.text-center .col-md-4:first-child {
  text-align: left;
}
.custom-calendar .row.text-center .col-md-4:last-child {
  text-align: right;
}
.custom-calendar .row.text-center h3{
  font-size: 17px;
}
.custom-calendar .cal-month-view .cal-day-cell.cal-today .cal-day-number {
  font-size: 1.5em;
  cursor: pointer;
}
.custom-calendar .modal {
  margin-top: 102px;
}
.custom-calendar .modal-dialog {
  max-width: 800px;
}
.custom-calendar .cal-day-badge {
  background-color: $primary-clr;
  color: $white;
}
.custom-calendar .cal-month-view .cal-day-cell.cal-weekend .cal-day-number {
  color: $primary-clr !important;
}
.center-bg {
  background: aliceblue;
  padding-top: 20px;
  padding-bottom: 20px;
}
.inside-tabs-msg .expand-body {
  max-width: 70% !important;
  flex: 0 0 70% !important;
}
.detail-view-half .msg-text {
  text-align: center;
  float: left;
  width: 100%;
  margin-bottom: 10px;
}
.detail-view-half .custom-title #compose-mail {
  margin: 0 auto;
  float: none !important;
  display: inline-block;
  text-align: center;
  margin-left: 15px;
}
.notes-section .box-lead-head {
  color: #173a64;
  font-weight: 600;
}
.togle-task .btn-secondary:focus {
  box-shadow: none !important;
}
.notes-section {
  height: 60vh;
  overflow-y: auto;
  overflow-x: hidden;
}
.history-list {
  height: 60vh;
  overflow-y: auto;
  overflow-x: hidden;
}
// .filter-plain .mat-form-field-underline {
//   display: none;
// }
.history-list ul {
  padding: 0;
  list-style-type: none;
}
.history-list ul li {
 margin-bottom: 10px;
}
.list-history {
  background: #f3f8fb;
  padding: 15px;
  border-radius: 5px;
}
.sub-history{
  font-size:14px;
  color: $primary-clr;
  font-weight: 600;
}
.sub-history2{
  font-size:14px;
  color: #7c7f82;
  font-weight: 500;
}
.data-hide {
  position: absolute;
  top: -36px;
  left: 14px;
  background: $primary-clr;
  padding: 3px 9px;
  border: 1px solid #555;
  border-radius: 5px;
  font-size: 17px;
  cursor: pointer;
  color:#fff;
}
.mat-flat-button.mat-primary.mat-button-disabled, .mat-flat-button.mat-accent.mat-button-disabled, .mat-flat-button.mat-warn.mat-button-disabled, .mat-flat-button.mat-button-disabled.mat-button-disabled, .mat-raised-button.mat-primary.mat-button-disabled, .mat-raised-button.mat-accent.mat-button-disabled, .mat-raised-button.mat-warn.mat-button-disabled, .mat-raised-button.mat-button-disabled.mat-button-disabled, .mat-fab.mat-primary.mat-button-disabled, .mat-fab.mat-accent.mat-button-disabled, .mat-fab.mat-warn.mat-button-disabled, .mat-fab.mat-button-disabled.mat-button-disabled, .mat-mini-fab.mat-primary.mat-button-disabled, .mat-mini-fab.mat-accent.mat-button-disabled, .mat-mini-fab.mat-warn.mat-button-disabled, .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  color: rgba(0,0,0,.26) !important;
}
.detail-view-full .left-detail {
  display: none;
}
.detail-view-full .top-label{
  padding-left: 45px;
}
.detail-view-full .full-width-section{
  flex: 0 0 100%;
    max-width: 100%;
}
.detail-view-half .asigned {
  flex: 0 0 100%;
  max-width: 100%;
}
.left-detail .card-body{
  padding: 1rem !important;
}
.color-code i {
  color: #173a64;
  padding-right: 5px;
}
.task-value-section {
  padding: 10px;
}
.color-code {
  margin-top: 5px;
  font-weight: 500;
  margin-bottom: 10px;
}
.task-value-section mat-expansion-panel-header {
  background: #d5d5d5 !important;
}
.task-value-section .mat-expansion-panel-header.mat-expanded {
  height: 30px;
  padding-right: 0;
}
.task-value-section .mat-expansion-panel-header {
  height: 30px;
  padding-right: 0;
}
.task-value-section .mat-expansion-panel-header-description {
  display: inline-block;
    color: $primary-clr;
    font-weight: 500;
}
.mat-expansion-panel-header-title {
    color: $primary-clr;
    font-weight: 500;
}
.task-content-mail ul {
  padding: 0;
  margin-bottom: 0;
}
.task-content-mail li {
  font-size: 12px;
  // background: #efefef;
  margin-bottom: 5px;
  margin-top: 3px;
  list-style-type: decimal;
  border-bottom: 1px solid #efefef;
    padding: 5px;
}
.task-empty {
  margin-top: 80px;
  margin-bottom: 130px;
  font-weight: 500;
}
.task-empty i {
  font-size:64px;
}
.themed {
  border: 1px solid #c0c0c0;
}
.themed.btn-light:not(:disabled):not(.disabled).active, .themed.btn-light:not(:disabled):not(.disabled):active, .show>.themed.btn-light.dropdown-toggle {
  color: #fff;
  background-color: #3e689b;
  border-color: #3e689b;
}
.mat-raised-button.mat-button-disabled:not([class*=mat-elevation-z]) {
   border-color: rgba(0,0,0,.12) !important;
}
.small-task .task-status {
  font-size: 10px;
  padding: 0px 7px !important;
}
.small-task  .tag-label {
  width: 100%;
}
.small-task .mention {
  display: none;
}
.small-task .task-content-small p{
  margin: 0;

}
.lead-detail-view quill-editor {
  display: inline-block;
  height: 107px;
  width: 100%;
}
.lead-detail-view .mail-modal.minimized .modal-header {
  width: 300px;
  margin: unset;
  right: 0;
  bottom: 44px;
  position: absolute;
}
.form-control.is-invalid{
  background-image: none !important;
}