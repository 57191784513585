.form-control-pf-value {
  background: 0 0;
  border: 1px solid transparent;
  display: inline-block;
  padding: 2px 6px;
}
.form-control-pf-edit .form-control-pf-textbox,.form-control-pf-edit .form-control-pf-save,.form-control-pf-edit .form-control-pf-cancel {
  display: inline-block;
}
.form-control-pf-edit .form-control-pf-value{
  display: none;
}
 .form-control-pf-value{
  display: block;
}
//  .form-control-pf-textbox, .form-control-pf-save, .form-control-pf-cancel {
//   display: none;
// }
.form-control-pf-edit {
  background: none;
  background-image: none !important;
  border: none;
  color: #173a64 !important;
  opacity: 1;
  font-size: 12px;
  margin-left: 5px;
  padding: 3px;
  line-height: 20px;
  height: 38px;
}
.form-control-pf-editable {
  display: flex;
}
.btn.form-control-pf-save {
  background: none;
  background-image: none !important;
  border: none;
  color: #27ca48 !important;
  opacity: 1;
  font-size: 12px;
  margin-left: 5px;
  padding:5px;
  line-height: 20px;
  height: 38px;
}
.btn.form-control-pf-cancel {
  background: none;
  background-image: none !important;
  border: none;
    color: #e91f18 !important;
    opacity: 1;
    font-size: 12px;
    margin-left: 5px;
    padding: 5px;
    line-height: 20px;
    height: 38px;
}
.cmha-meta-data .form-control-pf-edit,.cmha-meta-data .col-form-label {
  font-size: 14px;
  color: #494747;
  font-weight: 500;
}
.form-control-pf-value i {
  font-size: 12px;
  color: #5f5f5f;
}
.form-control-pf-value {
  font-size: 14px;
  color: #173a64;
  font-weight: 500;
}
.form-control-pf-value {
  background: #F7F7FD;
  width: 100%;
  text-align: left;
  padding: 10px;
}
.Psychi-current-section {
  background: aliceblue;
  padding: 15px !important;
}
.Psychi-current-section  .form-control-pf-value {
  background: #fff;
  width: 100%;
  text-align: left;
}
.set-label {
  font-size: 14px;
  font-weight: 500;
  color: #555;
}
.set2-temp .mat-radio-label {
  font-size: 14px;
  font-weight: 500;
  color: #555;
}
.sub-hint-txt {
  font-size: 12px;
  color: #272a88;
  font-weight: 500;
  margin-bottom: 7px;
}
.set5-temp .check-custom .mat-checkbox-label ,.set5-temp .mat-radio-label-content{
  color: #173a64;
  text-transform: capitalize;
  font-size: 14px;
  font-weight: 500;
}
.set-label2 {
  font-weight: 500;
  font-size: 15px;
}
.set6-temp .inputWrapper .add, .inputWrapper .remove {
  position: absolute;
  top: 9px;
  right: 4px;
}
.set6-temp .inputWrapper .inputElement {
  margin-right: 50px;
}
.set6-temp .inputWrapper .add {
  right: 36px;
}
.medication label {
  font-weight: 500;
  color: #000;
  font-size: 14px;
}
.table-head-medication label {
  margin: 0;
}
.table-head-medication {
  background: #efefef;
  padding: 7px;
  vertical-align: middle;
  margin-bottom: 15px;
  margin-top: 11px;
}
.table-head-medication label {
  font-weight: 500;
  color: #000;
  font-size: 14px;
}
.side-heading-table {
  font-size: 14px;
  font-weight: 500;
  color: #007bff;
}
.additional-steps {
  margin-top: 25px;
  background: aliceblue;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-bottom: 10px;
}