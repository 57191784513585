.Treatment-list ul{
  list-style-type: none;
  padding: 0;
}
.Treatment-list .w-40 {
  width: 60px !important;
  height: 60px !important;
}
.Treatment-list .sub-except {
  font-size: 11px !important;
  font-weight: 400;
}
.Treatment-list .gd-primary {
  position: relative;
    line-height: 1;
    border-radius: 500px;
    white-space: nowrap;
    font-weight: 700;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    border-radius: 500px;
    box-shadow: 0 5px 10px 0 rgb(50 50 50 / 15%);
  color: #fff;
  border: none;
  background: #448bff linear-gradient(45deg, #448bff, #44e9ff);
  font-size: 23px;
}
.button-blue {
  background-color: rgb(255 255 255 / 12%) !important;
  color: #173a64 !important;
  width: 190px;
  border: 1px solid #173a64;
  font-weight: 500;
}
.icon-listing a {
  font-size: 16px;
}
.icon-listing a:hover {
  color: #23759d;
}
.Treatment-list .text-color {
  color: #225491;
  font-size: 14px;
  font-weight: 500;
}
.status-newd {
  border-left: 4px solid green;
}
.status-inprog {
  border-left: 4px solid #ffc107;
}
.Treatment-list .status-section .badge {
  padding: 6px 15px;
  font-weight: 600;
  font-size: 10px;
}



/*new design-tabs*/
.tabs-newd {
  position: relative;
  background: white;
  padding: 20px;
  padding-bottom: 80px;
  width: 100%;
  // box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  // border-radius: 5px;
}
.tabs-newd input[name=tab-control] {
  display: none;
}
.tabs-newd .content section h2,
.tabs-newd ul li label {
  font-size: 15px;
  color: #428BFF;
}
.tabs-newd ul {
  list-style-type: none;
  padding-left: 0;
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
  justify-content: space-between;
  align-items: flex-end;
  flex-wrap: wrap;
}
.tabs-newd ul li {
  box-sizing: border-box;
  flex: 1;
  width: 25%;
  padding: 0 10px;
  text-align: center;
}
.tabs-newd ul li label {
  transition: all 0.3s ease-in-out;
  color: #929daf;
  padding: 5px auto;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  white-space: nowrap;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.tabs-newd ul li label br {
  display: none;
}
.tabs-newd ul li label svg {
  fill: #929daf;
  height: 1.2em;
  vertical-align: bottom;
  margin-right: 0.2em;
  transition: all 0.2s ease-in-out;
}
.tabs-newd ul li label:hover, .tabs-newd ul li label:focus, .tabs-newd ul li label:active {
  outline: 0;
  color: #bec5cf;
}
.tabs-newd ul li label:hover svg, .tabs-newd ul li label:focus svg, .tabs-newd ul li label:active svg {
  fill: #bec5cf;
}
.tabs-newd .slider {
  position: relative;
  width: 16.6%;
  transition: all 0.33s cubic-bezier(0.38, 0.8, 0.32, 1.07);
}
.tabs-newd .slider .indicator {
  position: relative;
  width: 20%;
  max-width: 100%;
  margin: 0 auto;
  height: 4px;
  background: #428BFF;
  border-radius: 1px;
}
.tabs-newd .content {
  margin-top: 30px;
}
.tabs-newd .content section {
  display: none;
  -webkit-animation-name: content;
          animation-name: content;
  -webkit-animation-direction: normal;
          animation-direction: normal;
  -webkit-animation-duration: 0.3s;
          animation-duration: 0.3s;
  -webkit-animation-timing-function: ease-in-out;
          animation-timing-function: ease-in-out;
  -webkit-animation-iteration-count: 1;
          animation-iteration-count: 1;
  line-height: 1.4;
}
.tabs-newd .content section h2 {
  color: #173a64;
  // display: none;
}
.tabs-newd .content section h2::after {
  content: "";
  position: relative;
  display: block;
  width: 30px;
  height: 3px;
  background: #173a64;
  margin-top: 5px;
  left: 1px;
}
.tabs-newd input[name=tab-control]:nth-of-type(1):checked ~ ul > li:nth-child(1) > label {
  cursor: default;
  color: #428BFF;
}
.tabs-newd input[name=tab-control]:nth-of-type(1):checked ~ ul > li:nth-child(1) > label svg {
  fill: #428BFF;
}
@media (max-width: 600px) {
  .tabs-newd input[name=tab-control]:nth-of-type(1):checked ~ ul > li:nth-child(1) > label {
    background: rgba(0, 0, 0, 0.08);
  }
}
.tabs-newd input[name=tab-control]:nth-of-type(1):checked ~ .slider {
  transform: translateX(0%);
}
.tabs-newd input[name=tab-control]:nth-of-type(1):checked ~ .content > section:nth-child(1) {
  display: block;
}
.tabs-newd input[name=tab-control]:nth-of-type(2):checked ~ ul > li:nth-child(2) > label {
  cursor: default;
  color: #428BFF;
}
.tabs-newd input[name=tab-control]:nth-of-type(2):checked ~ ul > li:nth-child(2) > label svg {
  fill: #428BFF;
}
@media (max-width: 600px) {
  .tabs-newd input[name=tab-control]:nth-of-type(2):checked ~ ul > li:nth-child(2) > label {
    background: rgba(0, 0, 0, 0.08);
  }
}
.tabs-newd input[name=tab-control]:nth-of-type(2):checked ~ .slider {
  transform: translateX(100%);
}
.tabs-newd input[name=tab-control]:nth-of-type(2):checked ~ .content > section:nth-child(2) {
  display: block;
}
.tabs-newd input[name=tab-control]:nth-of-type(3):checked ~ ul > li:nth-child(3) > label {
  cursor: default;
  color: #428BFF;
}
.tabs-newd input[name=tab-control]:nth-of-type(3):checked ~ ul > li:nth-child(3) > label svg {
  fill: #428BFF;
}
@media (max-width: 600px) {
  .tabs-newd input[name=tab-control]:nth-of-type(3):checked ~ ul > li:nth-child(3) > label {
    background: rgba(0, 0, 0, 0.08);
  }
}
.tabs-newd input[name=tab-control]:nth-of-type(3):checked ~ .slider {
  transform: translateX(200%);
}
.tabs-newd input[name=tab-control]:nth-of-type(3):checked ~ .content > section:nth-child(3) {
  display: block;
}
.tabs-newd input[name=tab-control]:nth-of-type(4):checked ~ ul > li:nth-child(4) > label {
  cursor: default;
  color: #428BFF;
}
.tabs-newd input[name=tab-control]:nth-of-type(4):checked ~ ul > li:nth-child(4) > label svg {
  fill: #428BFF;
}
@media (max-width: 600px) {
  .tabs-newd input[name=tab-control]:nth-of-type(4):checked ~ ul > li:nth-child(4) > label {
    background: rgba(0, 0, 0, 0.08);
  }
}
.tabs-newd input[name=tab-control]:nth-of-type(4):checked ~ .slider {
  transform: translateX(300%);
}
.tabs-newd input[name=tab-control]:nth-of-type(4):checked ~ .content > section:nth-child(4) {
  display: block;
}
.tabs-newd input[name=tab-control]:nth-of-type(5):checked ~ .slider {
  transform: translateX(400%);
}
.tabs-newd input[name=tab-control]:nth-of-type(5):checked ~ .content > section:nth-child(5) {
  display: block;
}

.tabs-newd input[name=tab-control]:nth-of-type(5):checked ~ ul > li:nth-child(5) > label {
  cursor: default;
  color: #428BFF;
}
.tabs-newd input[name=tab-control]:nth-of-type(5):checked ~ ul > li:nth-child(5) > label svg {
  fill: #428BFF;
}
@media (max-width: 600px) {
  .tabs-newd input[name=tab-control]:nth-of-type(5):checked ~ ul > li:nth-child(5) > label {
    background: rgba(0, 0, 0, 0.08);
  }
}

@media (max-width: 600px) {
  .tabs-newd input[name=tab-control]:nth-of-type(6):checked ~ ul > li:nth-child(6) > label {
    background: rgba(0, 0, 0, 0.08);
  }
}
.tabs-newd input[name=tab-control]:nth-of-type(6):checked ~ .slider {
  transform: translateX(500%);
}
.tabs-newd input[name=tab-control]:nth-of-type(6):checked ~ .content > section:nth-child(6) {
  display: block;
}
.tabs-newd input[name=tab-control]:nth-of-type(6):checked ~ ul > li:nth-child(6) > label {
  cursor: default;
  color: #428BFF;
}
.tabs-newd input[name=tab-control]:nth-of-type(6):checked ~ ul > li:nth-child(6) > label svg {
  fill: #428BFF;
}
@-webkit-keyframes content {
  from {
    opacity: 0;
    transform: translateY(5%);
  }
  to {
    opacity: 1;
    transform: translateY(0%);
  }
}
@keyframes content {
  from {
    opacity: 0;
    transform: translateY(5%);
  }
  to {
    opacity: 1;
    transform: translateY(0%);
  }
}
@media (max-width: 1000px) {
  .tabs-newd ul li label {
    white-space: initial;
  }
  .tabs-newd ul li label br {
    display: initial;
  }
  .tabs-newd ul li label svg {
    height: 1.5em;
  }
}
@media (max-width: 600px) {
  .tabs-newd ul li label {
    padding: 5px;
    border-radius: 5px;
  }
  .tabs-newd ul li label span {
    display: none;
  }
  .tabs-newd .slider {
    display: none;
  }
  .tabs-newd .content {
    margin-top: 20px;
  }
  .tabs-newd .content section h2 {
    display: block;
  }
}
.info-top {
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 30px;
}
.treat-label {
  font-weight: 500;
  font-size: 14px;
  color: #313131;
}
.treat-tab{
  font-weight: 500;
    font-size: 17px;
    color: #313131;
}
.treat-val {
  font-weight: 500;
  color: #173a64;
  font-size: 16px;
}
.Treatment-plan .sub-hint-txt {
  font-size: 14px;
  color: #272a88;
  font-weight: 500;
  margin-bottom: 7px;
}
// .three h1 {
//   font-size: 16px;
//   font-weight: 500;
//   letter-spacing: 0;
//   line-height: 1.5em;
//   padding-bottom: 15px;
//   position: relative;
//   color: #173a64;
// }
// .three h1:before {
//   content: "";
//   position: absolute;
//   left: 0;
//   bottom: 0;
//   height: 5px;
//   width: 55px;
//   background-color: #173a64;
// }
// .three h1:after {
//   content: "";
//   position: absolute;
//   left: 0;
//   bottom: 2px;
//   height: 1px;
//   width: 95%;
//   max-width: 255px;
//   background-color: #173a64;
// }
.customed-check-label {
  color: #173a64;
  font-size: 14px;
  margin: 0;
  display: inline-block;
  font-weight: 500;
}
.customed-check-label input {
  vertical-align: middle;
}
.Treatment-plan textarea {
  font-size: 12px;
  // padding: 10px;
  font-style: italic;
  color: #000;
  font-weight: 600;
}
.custom-textbox {
  border: 1px solid #ced4da;
  font-size: 13px;
  height: calc(1.5em + .75rem + 2px);
  padding: .375rem .75rem;
  color: #495057;
  border-radius: .25rem;
}
.relv-textarea {
  margin-top: -16px;
}
.profile-patient .card-subtitle {
  font-weight: 400;
  margin-bottom: 15px;
  color: #616d73;
  font-size: 12px;
}
.profile-patient .card-title {
  color: #212529;
  font-size: 13px;
  font-weight: 500;
  margin-bottom: 8px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: left;
}
.patient-info b {
  float: left;
  text-align: left;
  width: 25%;
  font-weight: 400;
  color: #6c757d;
}
.sep-section {
  background: aliceblue;
  margin-top: 20px;
  margin-bottom: 20px;
}
.icon-buttons-treat {
  padding: 15px;
}
.icon-buttons-treat a {
  margin-right: 28px;
  margin-left: 20px;
}
.goals-val {
  font-size: 14px;
  font-weight: 500;
  color: #173a64;
}
// .patient-info ul li .info-head {
//   position: relative;
//   font-size: 0.875rem;
//   font-weight: 500;
//   color: #272b41;
//   text-transform: capitalize;
//   text-align: left;
//   display: inline-block;
//   width: 25%;
// }
// .patient-info ul li .info-val {
//   color: #757575;
//   display: inline-block;
//   font-weight: 400;
//   text-overflow: ellipsis;
//   overflow: hidden;
//   width: 185px;
//   text-align: left;
//   white-space: nowrap;
//   vertical-align: middle;
// }
// .patient-info ul li {
//   width: 100%;
//   float: left;
//   margin-bottom: 8px;
// }
// .patient-info ul {
//   padding: 0;
//   list-style: none;
//   font-size: .875rem;
//   margin: 0;
// }
.goal-heads {
  font-weight: 500;
  color: #173a64;
  font-size: 15px;
  margin-bottom: 10px;
}
.goal-heads-sub{
  font-weight: 500;
  color: #173a64;
  font-size: 13px;
  margin-bottom: 8px;
}
.goal-wrapper {
  background: #fff;
  margin-top: 15px;
  padding: 10px;
  margin-bottom: 15px;
  // box-shadow: 3px 3px 7px #ccc;
}
.history-section .medication input::-webkit-input-placeholder { /* Chrome/Opera/Safari */
 font-size: 13px;
}
.history-section .medication input::-moz-placeholder { /* Firefox 19+ */
  font-size: 13px;
}
.history-section .medication input:-ms-input-placeholder { /* IE 10+ */
  font-size: 13px;
}
.history-section .medication input:-moz-placeholder { /* Firefox 18- */
  font-size: 13px;
}
.history-section .medication select{ /* Chrome/Opera/Safari */
  font-size: 13px;
 }
 .goals-viewd .val-list {
  font-size: 12px;
  color: #173a64;
  font-weight: 500;
}
.goals-viewd .invi-check {
  position: absolute;
  left: 37px;
  top: 5px;
}
.tog-btn i {
  margin-right: -40px;
  font-size: 12px;
  z-index: 999;
  background: #fff;
  padding: 6px;
  border-radius: 5px;
  border: 1px solid #ccc;
}
.tog-btn {
  width: 30px;
  height: 32px;
  border-radius: 50%;
  position: relative;
}
.wrap-tog-btn.active {
  display: inline-block;
  position: absolute;
  top: -40px;
  left: 166px;
  margin-right: -20px;
  transition: 0.3s;
  z-index: 1;
}
.wrap-tog-btn {
  display: inline-block;
  position: absolute;
  top: -31px;
    left: 1px;
  margin-right: -20px;
  transition: 0.3s;
  z-index: 1;
}
.diag-label {
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 0;
}
.chip-container-content {
  background: #f5f5f5;
  padding: 10px;
  border: 1px solid;
}
// .mpa{
//   background: #f3f3f3;
// }
.Suicidal-alert {
  padding: 5px;
  font-size: 13px;
  border-left: 4px solid;
  border-radius: 0;
}
.diag-sec .mat-primary.mat-standard-chip.mat-chip-with-trailing-icon, .mat-standard-chip {
  background: #F2F9F7 !important;
  border: 1px solid #686F71 !important;
  color: #686F71 !important;
  border-radius: 0 !important;
  font-size: 12px;
  padding: 5px !important;
  height: 24px !important;
  min-height: 24px !important;
}
.count-round{
  background: #173a64;
  border-radius: 50%;
  height: 30px;
  color: #f6ca21 !important;
  width: 30px;
  text-align: center !important;
  vertical-align: middle;
  padding-top: 5px;
  margin-top: -5px;
  display: inline-block;
  padding-right: 3px;
  margin-left: 10px;
}
.sugg-head {
  color: #000;
  padding: 5px;
  margin: 10px 0;
}
.two h1:before {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 60px;
  height: 2px;
  content: "";
  background-color: #c50000;
}
.sub-goal-div {
  font-size: 10px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 3px;
  line-height: 2em;
  color: rgba(0, 0, 0, 0.4);
  padding-bottom: 0px;
  display: block;
}
.goal-suggestion {
  padding: 10px;
  margin-top: 10px;
  background: aliceblue;
  margin-bottom: 10px;
}
.sugges-list-goal .card-title {
  font-size: 13px;
  color: #006c9d;
  margin-bottom: 5px;
}
.top-list-goal .card-title {
  font-size: 13px;
  color: #006c9d;
  margin-bottom: 5px;
  width: 75%;
}
.top-list-goal .card-title1 {
  font-size: 13px;
  color: #006c9d;
  margin-bottom: 5px;
  width: 475px;
}
.sugges-list-goal .card-title span {
  font-weight: 600;
  font-size: 13px;
  color: #040404;
  line-height: 26px;
}
.sugges-list-goal .card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #ffffff;
  background-clip: border-box;
  border: 1px solid #e6e4e9;
  border-radius: 3px;
  height: 315px;
  margin-bottom: 20px;
}
.mt--27{
  margin-top: -27px;
}
.widget-49-date-primary {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: #edf1fc;
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
}
.widget-49-date-day {
  color: #4e73e5;
  font-weight: 500;
  font-size: 0.8rem;
  line-height: 1;
}
.widget-49-date-month {
  color: #4e73e5;
  line-height: 1;
  font-size: 0.5rem;
  text-transform: uppercase;
  font-weight: 600;
  margin-top: 3px;
  margin-bottom: 3px;
}
.widget-49-meeting-info {
  display: flex;
  flex-direction: column;
  margin-left: 1rem;
}
.widget-49-pro-title {
  color: #3c4142;
  font-size: 14px;
}
.widget-49-meeting-time {
  color: #B1BAC5;
  font-size: 13px;
}
.widget-49-title-wrapper {
  display: flex;
}
.sugges-list-goal input{
  display: none;
}
// .sugges-list-goal input[type=radio]:checked + label::after {
//   color: #3d3f43;
//   font-family: FontAwesome;
//   border: 2px solid #1dc973;
//   content: "\f00c";
//   font-size: 24px;
//   position: absolute;
//   top: -25px;
//   left: 50%;
//   transform: translateX(-50%);
//   height: 50px;
//   width: 50px;
//   line-height: 50px;
//   text-align: center;
//   border-radius: 50%;
//   background: white;
//   box-shadow: 0px 2px 5px -2px rgba(0, 0, 0, 0.25);
// }
.sugges-list-goal input[type=radio]:checked + label::after {
  color: #1dbb4e;
  font-family: "Font Awesome 5 Free";
  border: 2px solid #1dbb4e;
  content: "";
  font-size: 16px;
  position: absolute;
  top: 8px;
  left: 95%;
  transform: translateX(-50%);
  height: 30px;
  width: 30px;
  line-height: 29px;
  text-align: center;
  border-radius: 50%;
  background: white;
  box-shadow: 0px 2px 5px -2px rgba(0, 0, 0, 0.25);
  font-weight: 900;
}
.sugges-list-goal .card.selected{
  border: 1px solid #1dbb4e;
}
.sugges-list-goal .card:hover{
  border: 1px solid #1dbb4e;
}
.example-accordion {
  display: block;
  max-width: 100%;
}

.example-accordion-item {
  display: block;
  border: solid 1px #ccc;
}

.example-accordion-item + .example-accordion-item {
  border-top: none;
}
.example-accordion-item-header {
  display: flex;
  align-content: center;
  justify-content: space-between;
  background: #fbfbfb;
}

.example-accordion-item-header {
  display: flex;
  align-content: center;
  justify-content: space-between;
}

.example-accordion-item-description {
  font-size: 0.85em;
  color: #3c3a3a;
  width: 155px;
}
.selection-gaols-head {
  color: #28a74b;
  font-weight: 500;
}
.goal-title-prom {
  color: #173a64 !important;
  font-weight: 600 !important;
  font-size: 16px !important;
  margin-bottom: 15px !important;
}
.example-accordion-item-header,
.example-accordion-item-body {
  padding: 16px;
}

.example-accordion-item-header:hover {
  cursor: pointer;
  background-color: #eee;
}

.example-accordion-item:first-child {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.example-accordion-item:last-child {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}
.widget-49-meeting-action a {
  text-decoration: underline !important;
  color: #52b7ec;
}
.stats {
  background: #f2f5f8 !important;
  color: #000 !important;
}
.articles {
  font-size: 10px;
  color: #a1aab9;
}
.followers {
  font-size: 10px;
  color: #a1aab9;
}
.aggr-goal-sec label {
  font-weight: 500;
  color: #000;
  font-size: 14px;
}
.activity-value {
  font-size: 13px;
  color: #2a2a2a;
  font-weight: 400;
  background: #efefef;
  padding: 5px;
}
.aggr-goal-sec .inputWrapper .remove {
  right: 35px !important;
}
.activity-value-new {
  font-size: 13px;
  color: #2a2a2a;
  font-weight: 400;
  background: #efefef;
  padding: 5px;
  font-weight: 600;
}
.tags .tex {
  display: inline-block;
  height: 20px;
  line-height: 21px;
  position: relative;
  margin: 0 13px 3px 0;
  padding: 0 6px 0 11px;
  background: #777;
  -webkit-border-bottom-right-radius: 3px;
  border-bottom-right-radius: 3px;
  -webkit-border-top-right-radius: 3px;
  border-top-right-radius: 3px;
  -webkit-box-shadow: 0 1px 2px rgba(0,0,0,0.2);
  box-shadow: 0 1px 2px rgba(0,0,0,0.2);
  color: #fff;
  font-size: 8px;
  text-decoration: none;
  text-shadow: 0 1px 2px rgba(0,0,0,0.2);
  font-weight: 400;
  }
  .tags .tex:before {
    content: "";
    position: absolute;
    top:0;
    left: -12px;
    width: 0;
    height: 0;
    border-color: transparent #777 transparent transparent;
    border-style: solid;
    border-width: 9px 12px 12px 0;
    }
    span.tags {
      margin-left: 21px;
  }
.tags .tex:after {
    content: "";
    position: absolute;
    top: 7px;
    left: 1px;
    float: left;
    width: 5px;
    height: 5px;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    background: #fff;
    -webkit-box-shadow: -1px -1px 2px rgba(0,0,0,0.4);
    box-shadow: -1px -1px 2px rgba(0,0,0,0.4);
    }
   .kpi  mat-expansion-panel-header {
      background: #efefef !important;
      font-size: 13px !important;
      color: #2a2a2a;
      font-weight: 400;
      background: #efefef;
      padding: 5px !important;
      font-family: 'Poppins', sans-serif !important;
      height: auto !important;

  }
  .aggr-goal-sec .kpi .remove {
    right: 23px !important;
}
  .kpi .mat-expansion-panel-header-title {
    color: #2a2a2a;
    font-weight: 600;
}
.kpi .mat-expansion-indicator::after {
  color: #565656 !important;
  margin-right: 6px;
}
.mat-expanded{
  border: 1px solid #ccc;
}
.kpi .mat-expansion-indicator {
  color: #565656 !important;
  margin-right: 6px;
}
.kpi .mat-expansion-panel-body {
  padding: 10px;

}
.kpi .mat-expansion-panel-header-description{
  font-size: 13px !important;
  color: #2a2a2a;
  font-weight: 600;
  font-family: 'Poppins', sans-serif !important;
  flex-grow: initial;
}
.kpi .mat-expansion-panel {
  background: #fff;
  color: rgba(0,0,0,.87);
  box-shadow: none !important;
  border-radius: 0 !important;
}
.kpis-list p {
  font-weight: 500;
  font-size: 13px;
  margin: 2px;
}
.no-goal {
  text-align: center;
  font-weight: 500;
  color: #555;
  margin-top: 40px;
  background: #efefef;
  padding: 10px;
}
.no-goal img{
  width:200px;
}
.aggr-headsection {
  font-weight: 500;
  text-align: left;
  color: #000;
  font-size: 13px;
  padding: 0.75em;
  border-bottom: 1px solid;
}
.aggr-section {
  background: #efefef;
  padding: 15px 20px;
}
.sub-aggr-label {
  background-color: transparent;
  color: #173a64;
  font-size: 13px;
  padding: 0.75em;
  font-weight: bold;
}
.aggr-goal-sec a.remove i {
  font-size: 21px;
}
.kpi-subsection td {
  padding: 0;
}




.section {
  position: relative;
}
.list-goals {
  padding-left: 30px;
}
.goals-plan {
  font-weight: 600;
  vertical-align: middle;
  margin-top: 6px;
  margin-right: 10px;
  color: #555;
}
.due-date-form {
  width: 170px;
  font-size: 15px;
}


.section input[type=checkbox] {
  visibility: hidden;
  position: absolute;
  left: 0;
  top: 10px;
  z-index: 1;
}
.sub-aggr-label i {
  font-size: 19px;
  vertical-align: bottom;
  margin-right: 5px;
}
/* (B4) SUB-SECTION ITEMS */
.section ul { margin-top: 10px; }
.section ul li { color: #d43d3d; }
.kpi-section {
  width: 100%;
}
.kpi-subsection {
  background: #efefef;
  padding: 15px 20px;
}
.options-goal .mat-radio-disabled {
  pointer-events: none;
  opacity: 1;
}
.aggr-goal-sec .mat-tab-label{

  padding: 0 10px !important;
  font-size: 13px !important;
}
.filter-group-search {
  height: 50px;
}
.content-multi-sec {
  background: aliceblue;
  padding: 10px;
  margin-bottom: 20px;
}
.presenting-table thead th {
  font-size: 13px;
  font-weight: 600;
}
.presenting-table thead {
  background: #efefef;
}
.presenting-table{
  table-layout: fixed;
}
.custom-check .mat-checkbox-checked.mat-accent .mat-checkbox-background{
  background-color: #173a64;
}
.custom-check span.mat-checkbox-label {
  font-weight: 500;
  color: #000;
  font-size: 14px;
}
.delete-float {
  position: absolute;
  right: 27px;
  top: 5px;
}
.prsenting-tab{
  width: 99% !important;
}
.bennew_tit {
  font-size: 15px;
  margin-bottom: 5px;
  color: #173a64;
  font-weight: 500;
}
.bennew_tit .mat-checkbox-label {
  font-size: 15px !important;
  color: #173a64 !important;
  font-weight: 500 !important;
}
.per-based{
  vertical-align: middle;
  display: inline-block;
  margin-top: 17px;
  padding: 5px;
}
.presenting-section button.btn.btn-primary.list_add_button.btn-theme {
  border-radius: .25rem !important;
}