.create-patients .contact-group .media img {
  width: 65px;
}
.create-patients .media-left,
.create-patients .media > .pull-left {
  padding-right: 10px;
}
.create-patients .contact-group .media-heading {
  font-size: 16px;
  font-weight: 600;
  color: $primary-clr;
  text-transform: capitalize;
}
.contact-group .list-group-item:hover {
  background-color: #fcfcfc;
}
.contact-group .list-group-item {
  border: none;
  margin-top: 10px;
  text-decoration: none;
  color: #555 !important;
}
.contact-group .media-heading small {
  margin-left: 5px;
  font-size: 13px;
  font-weight: 400;
  color: #999;
}
.contact-group .media-content {
  margin-top: 5px;
  font-size: 14px;
}
.contact-group .media-content ul {
  margin-top: 8px;
  margin-bottom: 0;
}
.list-unstyled {
  padding-left: 0;
  list-style: none;
}
.contact-group .media-content ul > li {
  display: inline-block;
  // min-width: 155px;
  margin-bottom: 5px;
  width: 100%;
}
.lead-stage-head {
  padding-left: 16px;
}
.lead-stage-patients label {
  font-size: 13px;
  padding-right: 10px;
  color: #3ca1ab;
  font-weight: 500;
}
.lead-stage-patients span {
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
}
.create-patients .btn-patient {
  font-size: 13px;
  width: auto;
  border: 1px solid #bababa;
  margin-bottom: 10px;
  text-align: left;
  background: #fff;
  margin-right: 5px;
}
.program-heading {
  text-transform: uppercase;
  font-size: 15px;
  color: #173a64;
  font-weight: 600;
}
.btn-patient:hover {
  background: #555;
  color: #fff;
}
.staff {
  background: aliceblue;
  padding-top: 10px;
  padding-bottom: 10px;
}
.staff-list {
  padding: 10px;
  background: #fff;
  border-radius: 5px;
}
.staff-list ul {
  padding: 0px;
  list-style-type: none;
}
.naming-staff {
  color: #173a64;
  font-weight: 600;
}
.scheduling-cover {
  .form-error-message {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background-color: #ff4e5d;
    color: #fff;
    padding: 5px;
    box-sizing: border-box;
    bottom: -5px;
    font-size: 11px;
    position: relative;
    z-index: 900;
    right: auto;
    top: auto;
    box-shadow: 0px 2px 4px rgb(102 102 102 / 50%);
    border-radius: 6px;
  }
  .form-error-message img {
    margin: 0 8px 0 3px;
    width: 20px;
    height: 20px;
  }
  .form-error-arrow {
    position: absolute;
    top: -16px;
    left: 10px;
    height: 0px;
    width: 0px;
    border: 8px solid transparent;
    border-bottom-color: #ff4e5d;
    border-bottom: 8px solid rgb(255 78 93);
  }
  .form-error-arrow-inner {
    display: none;
  }
  .form-error-arrow-inner {
    position: absolute;
    height: 0px;
    width: 0px;
    border: 10px solid transparent;
    border-bottom-color: #ffcccc;
    border-bottom-width: 11px;
    left: -10px;
    top: -10px;
  }
  .scheduling-list {
    border-left: 5px solid #173a64;
    padding: 10px;
    background: #efefef;
  }
}
.conflicts-schedule .scheduling-list {
  border-left: 5px solid #ff4e5d;
}
.circle-bullet {
  padding-right: 2px;
  color: #37a1ac;
  font-size: 12px;
}
.patient-dashboard {
  .card {
    background-color: #ffffff;
    margin-bottom: 30px;
    -webkit-box-shadow: 2px 6px 15px 0px rgb(69 65 78 / 10%);
    -moz-box-shadow: 2px 6px 15px 0px rgba(69, 65, 78, 0.1);
    box-shadow: 2px 6px 15px 0px rgb(69 65 78 / 10%);
    // box-shadow: 0 1px 8px 0 rgb(0 0 0 / 20%), 0 3px 4px 0 rgb(0 0 0 / 14%), 0 3px 3px -2px rgb(0 0 0 / 12%);
    border: 1px solid #eee;
  }
  .card-title {
    margin: 0;
    color: #173a64;
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.6;
    text-align: left;
    text-transform: uppercase;
  }
  .card-icon {
    width: 50px;
    height: 50px;
    line-height: 50px;
    font-size: 22px;
    margin: 10px;
    box-shadow: 5px 3px 10px 0 rgb(21 15 15 / 30%);
    border-radius: 10px;
    padding-top: 4px;
    background: #173a64;
    margin: 0 auto;
  }
  .bg-green {
    background-color: #198754 !important;
    color: #fff;
  }
  .bg-red {
    background-color: #ef5350 !important;
    color: #fff;
  }
  .stats {
    font-size: 12px;
    margin-top: 12px;
    font-weight: 500;
  }
  .illustration-text {
    color: #555;
    font-size: 15px;
  }
  .illustration-text span {
    color: #0d6efd;
    font-size: 20px;
    display: block;
  }
  .intake-head {
    font-size: 15px;
    margin-top: 5px;
    color: #319c57;
    font-weight: 500;
    text-transform: capitalize;
  }
  .intake-head-sub {
    font-size: 13px;
    color: #212529;
    font-weight: 500;
    text-transform: capitalize;
    margin-top: 10px;
    line-height: 24px;
  }
  .choose-intake .mat-radio-label-content {
    font-size: 13px !important;
    color: #2c8dbb;
    font-weight: 500;
  }
  .full-height {
    height: 85%;
  }
  .choose-intake .mat-radio-button {
    width: 100%;
  }
}
.new-align-dasboard .full-height {
  height: 93%;
}
.btn-dashboard {
  padding: 8px 9px;
  line-height: 12px;
  border: 1px solid;
  font-weight: 400;
  font-size: 13px;
}
.green-theme {
  color: #fff !important;
  background: #219841;
}
.blue-theme {
  color: #fff !important;
  background-color: #123b86;
}
.empty-treatment img {
  width: 55px;
}
.empty-invoice img {
  width: 55px;
}
.empty-treatment {
  font-size: 15px;
  text-align: center;
  margin-bottom: 15px;
}
.empty-invoice {
  font-size: 15px;
  text-align: center;
  margin-bottom: 15px;
}
.date-custom .mat-form-field-infix {
  min-height: 39px;
}
.create-patients .icons-section {
  padding: 5px;
  background: aliceblue;
  height: 100%;
}
.stage-contact {
  font-weight: 600;
  font-size: 15px;
  color: #1a7b7b;
  padding-left: 0;
}
.crba {
  border-radius: 50%;
  border: 4px solid #cccccc;
  display: flex;
  width: 80px;
  height: 80px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  margin: 0 auto;
}
.crba.cross:before {
  content: "";
  position: absolute;
  height: 30px;
  width: 30px;
  background-color: red;
  border-radius: 50%;
  bottom: 0;
  left: -9px;
  border: 5px solid #fff;
}

.crba.cross:after {
  content: "✕";
  position: absolute;
  color: #ffffff;
  bottom: 3px;
  left: 0px;
  font-size: 15px;
}
.crba.tick:before {
  content: "";
  position: absolute;
  height: 30px;
  width: 30px;
  background-color: green;
  border-radius: 50%;
  bottom: 0;
  left: -9px;
  border: 5px solid #fff;
}

.crba.tick:after {
  content: "✔";
  position: absolute;
  color: #ffffff;
  bottom: 3px;
  left: 0px;
  font-size: 15px;
}
.crba img {
  width: 45px;
}
.progress-indicator {
  margin: 0 0 0.3em;
  padding: 0;
  font-size: 80%;
  text-transform: uppercase;
}
.progress-indicator > li {
  list-style: none;
  text-align: center;
  width: auto;
  padding: 0;
  margin: 0;
  position: relative;
  text-overflow: ellipsis;
  color: #bbb;
  display: block;
}
.progress-indicator > li.warning .bubble {
  color: #edb10a;
}
.progress-indicator > li.danger .bubble {
  color: #d3140f;
}
.progress-indicator > li.completed,
.progress-indicator > li.completed .bubble {
  color: #65d074;
}
.progress-indicator > li.completed .bubble,
.progress-indicator > li.completed .bubble:after,
.progress-indicator > li.completed .bubble:before {
  background-color: #65d074;
  border-color: #247830;
}
.progress-indicator > li:last-child .bubble:after,
.progress-indicator > li:last-child .bubble:before {
  width: 50%;
  margin-right: 50%;
}
.progress-indicator > li .bubble:before {
  left: 0;
}
.progress-indicator > li .bubble:after,
.progress-indicator > li .bubble:before {
  display: block;
  position: absolute;
  top: 4px;
  width: 100%;
  height: 3px;
  content: "";
  background-color: #bbb;
}
.progress-indicator > li:last-child .bubble:after,
.progress-indicator > li:last-child .bubble:before {
  width: 50%;
  margin-right: 50%;
}
.progress-indicator > li .bubble:after {
  right: 0;
}
.progress-indicator > li.completed .bubble,
.progress-indicator > li.completed .bubble:after,
.progress-indicator > li.completed .bubble:before {
  background-color: #65d074;
  border-color: #247830;
}
.progress-indicator > li .bubble {
  border-radius: 1000px;
  width: 10px;
  height: 10px;
  background-color: #bbb;
  display: block;
  margin: 0 auto 0.5em;
  border-bottom: 1px solid #888;
}
.flexer-element,
.progress-indicator > li {
  -ms-flex: 1;
  -webkit-flex: 1;
  -moz-flex: 1;
  flex: 1;
}
.progress-indicator {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}
.progress-indicator > li:first-child .bubble:after,
.progress-indicator > li:first-child .bubble:before {
  width: 50%;
  margin-left: 50%;
}
.progress-indicator > li.warning .bubble,
.progress-indicator > li.warning .bubble:after,
.progress-indicator > li.warning .bubble:before {
  background-color: #edb10a;
  border-color: #5a4304;
}
.progress-indicator > li.danger .bubble,
.progress-indicator > li.danger .bubble:after,
.progress-indicator > li.danger .bubble:before {
  background-color: #d3140f;
  border-color: #440605;
}
.progress-indicator > li.info .bubble,
.progress-indicator > li.info .bubble:after,
.progress-indicator > li.info .bubble:before {
  background-color: #5b32d6;
  border-color: #25135d;
}
.status-indicator {
  width: 248px;
  margin-left: -23px;
}
.status-indicatorNew {
 
  width: 345px;
  margin-left: -23px;
}
.help-text {
  font-weight: 500;
  font-size: 15px;
  color: $primary-clr;
}
.right-date-section {
  // background: aliceblue;
  padding-top: 15px;
  padding-bottom: 15px;
}
.sub-head-section {
  font-weight: 500;
  font-size: 16px;
  text-transform: capitalize;
  padding-left: 6px;
  color: #3b3b3b;
}
.breif-calen.calendar-small .cal-month-view .cal-day-cell.cal-today {
  background-color: #ededfb;
  color: #000;
  opacity: 1;
}
.breif-calen.calendar-small
  .cal-month-view
  .cal-day-cell.cal-today
  .cal-day-number {
  color: #000;
  font-weight: 500;
}
.not-yet img {
  width: 50px;
  margin: 0 auto;
  display: table;
  margin-top: 50px;
  margin-bottom: 25px;
}
.not-yet {
  font-size: 14px;
  text-align: center;
}
.intake-declined {
  color: #cc1212;
  font-size: 15px;
  margin-top: 5px;
  font-weight: 500;
  text-transform: capitalize;
}
.intake-inprocess {
  font-size: 15px;
  margin-top: 5px;
  color: #173a64;
  font-weight: 500;
  text-transform: capitalize;
}
.time-schedule {
  padding: 9px;
  border: 1px solid;
  float: left;
  border-radius: 5px;
  margin-top: 15px;
  margin-bottom: 15px;
  font-size: 14px;
  width: 100%;
  font-weight: 400;
  text-align: center;
  background: #efef;
}
.breif-calen.calendar-small
  .cal-month-view
  .cal-day-cell.cal-in-month.cal-has-events {
  cursor: pointer;
  position: relative;
  height: 60px;
}
.breif-calen.calendar-small .cal-events {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-block !important;
  width: 35px;
  position: absolute;
  bottom: 0;
}
.breif-calen.calendar-small .cal-month-view .cal-event {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  display: inline-block;
  margin: 0px;
}
.intake-icon img {
  width: 40px;
  margin-bottom: 5px;
}
.date-day {
  height: 22px;
  font-size: 14px;
  font-weight: 600;
  color: #173a64;
}
.date-conflict::before {
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  content: "\f06a";
}
.date-conflict {
  padding-left: 10px;
}
.consent-view-form #v-pills-tab .nav-link {
  padding: 10px !important;
  width: 100%;
}
.intake-section-icon img {
  width: 60px;
}
/*progress*/
.total-progress {
  position: absolute;
  right: 0;
  top: -27px;

  .progress {
    width: 70px;
    height: 70px;
    line-height: 160px;
    background: none;
    margin: 0 auto;
    box-shadow: none;
    position: relative;
  }
  .progress:after {
    content: "";
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: 5px solid #efefef;
    position: absolute;
    top: 0;
    left: 0;
  }
  .progress > span {
    width: 50%;
    height: 100%;
    overflow: hidden;
    position: absolute;
    top: 0;
    z-index: 1;
  }
  .progress .progress-left {
    left: 0;
  }
  .progress .progress-bar {
    width: 100%;
    height: 100%;
    background: none;
    border-width: 5px;
    border-style: solid;
    position: absolute;
    top: 0;
    // border-color: #ffb43e;
    border-color: #089649;
  }
  .progress .progress-left .progress-bar {
    left: 100%;
    border-top-right-radius: 80px;
    border-bottom-right-radius: 80px;
    border-left: 0;
    -webkit-transform-origin: center left;
    transform-origin: center left;
  }
  .progress .progress-right {
    right: 0;
  }
  .progress .progress-right .progress-bar {
    left: -100%;
    border-top-left-radius: 80px;
    border-bottom-left-radius: 80px;
    border-right: 0;
    -webkit-transform-origin: center right;
    transform-origin: center right;
  }
  .progress .progress-value {
    display: flex;
    border-radius: 50%;
    font-size: 0.8rem;
    text-align: center;
    line-height: 20px;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    font-weight: 600;
  }
  .progress .progress-value span {
    text-transform: uppercase;
    font-weight: 600;
    font-size: 8px;
  }

  /* This for loop creates the necessary css animation names 
  Due to the split circle of progress-left and progress right, we must use the animations on each side. 
  */
  .progress[data-percentage="1"] .progress-right .progress-bar {
    animation: loading-1 0.5s linear forwards;
  }
  .progress[data-percentage="1"] .progress-left .progress-bar {
    animation: 0;
  }

  .progress[data-percentage="2"] .progress-right .progress-bar {
    animation: loading-2 0.5s linear forwards;
  }
  .progress[data-percentage="2"] .progress-left .progress-bar {
    animation: 0;
  }

  .progress[data-percentage="3"] .progress-right .progress-bar {
    animation: loading-3 0.5s linear forwards;
  }
  .progress[data-percentage="3"] .progress-left .progress-bar {
    animation: 0;
  }

  .progress[data-percentage="4"] .progress-right .progress-bar {
    animation: loading-4 0.5s linear forwards;
  }
  .progress[data-percentage="4"] .progress-left .progress-bar {
    animation: 0;
  }

  .progress[data-percentage="5"] .progress-right .progress-bar {
    animation: loading-5 0.5s linear forwards;
  }
  .progress[data-percentage="5"] .progress-left .progress-bar {
    animation: 0;
  }

  .progress[data-percentage="6"] .progress-right .progress-bar {
    animation: loading-6 0.5s linear forwards;
  }
  .progress[data-percentage="6"] .progress-left .progress-bar {
    animation: 0;
  }

  .progress[data-percentage="7"] .progress-right .progress-bar {
    animation: loading-7 0.5s linear forwards;
  }
  .progress[data-percentage="7"] .progress-left .progress-bar {
    animation: 0;
  }

  .progress[data-percentage="8"] .progress-right .progress-bar {
    animation: loading-8 0.5s linear forwards;
  }
  .progress[data-percentage="8"] .progress-left .progress-bar {
    animation: 0;
  }

  .progress[data-percentage="9"] .progress-right .progress-bar {
    animation: loading-9 0.5s linear forwards;
  }
  .progress[data-percentage="9"] .progress-left .progress-bar {
    animation: 0;
  }

  .progress[data-percentage="10"] .progress-right .progress-bar {
    animation: loading-10 0.5s linear forwards;
  }
  .progress[data-percentage="10"] .progress-left .progress-bar {
    animation: 0;
  }

  .progress[data-percentage="11"] .progress-right .progress-bar {
    animation: loading-11 0.5s linear forwards;
  }
  .progress[data-percentage="11"] .progress-left .progress-bar {
    animation: 0;
  }

  .progress[data-percentage="12"] .progress-right .progress-bar {
    animation: loading-12 0.5s linear forwards;
  }
  .progress[data-percentage="12"] .progress-left .progress-bar {
    animation: 0;
  }

  .progress[data-percentage="13"] .progress-right .progress-bar {
    animation: loading-13 0.5s linear forwards;
  }
  .progress[data-percentage="13"] .progress-left .progress-bar {
    animation: 0;
  }

  .progress[data-percentage="14"] .progress-right .progress-bar {
    animation: loading-14 0.5s linear forwards;
  }
  .progress[data-percentage="14"] .progress-left .progress-bar {
    animation: 0;
  }

  .progress[data-percentage="15"] .progress-right .progress-bar {
    animation: loading-15 0.5s linear forwards;
  }
  .progress[data-percentage="15"] .progress-left .progress-bar {
    animation: 0;
  }

  .progress[data-percentage="16"] .progress-right .progress-bar {
    animation: loading-16 0.5s linear forwards;
  }
  .progress[data-percentage="16"] .progress-left .progress-bar {
    animation: 0;
  }

  .progress[data-percentage="17"] .progress-right .progress-bar {
    animation: loading-17 0.5s linear forwards;
  }
  .progress[data-percentage="17"] .progress-left .progress-bar {
    animation: 0;
  }

  .progress[data-percentage="18"] .progress-right .progress-bar {
    animation: loading-18 0.5s linear forwards;
  }
  .progress[data-percentage="18"] .progress-left .progress-bar {
    animation: 0;
  }

  .progress[data-percentage="19"] .progress-right .progress-bar {
    animation: loading-19 0.5s linear forwards;
  }
  .progress[data-percentage="19"] .progress-left .progress-bar {
    animation: 0;
  }

  .progress[data-percentage="20"] .progress-right .progress-bar {
    animation: loading-20 0.5s linear forwards;
  }
  .progress[data-percentage="20"] .progress-left .progress-bar {
    animation: 0;
  }

  .progress[data-percentage="21"] .progress-right .progress-bar {
    animation: loading-21 0.5s linear forwards;
  }
  .progress[data-percentage="21"] .progress-left .progress-bar {
    animation: 0;
  }

  .progress[data-percentage="22"] .progress-right .progress-bar {
    animation: loading-22 0.5s linear forwards;
  }
  .progress[data-percentage="22"] .progress-left .progress-bar {
    animation: 0;
  }

  .progress[data-percentage="23"] .progress-right .progress-bar {
    animation: loading-23 0.5s linear forwards;
  }
  .progress[data-percentage="23"] .progress-left .progress-bar {
    animation: 0;
  }

  .progress[data-percentage="24"] .progress-right .progress-bar {
    animation: loading-24 0.5s linear forwards;
  }
  .progress[data-percentage="24"] .progress-left .progress-bar {
    animation: 0;
  }

  .progress[data-percentage="25"] .progress-right .progress-bar {
    animation: loading-25 0.5s linear forwards;
  }
  .progress[data-percentage="25"] .progress-left .progress-bar {
    animation: 0;
  }

  .progress[data-percentage="26"] .progress-right .progress-bar {
    animation: loading-26 0.5s linear forwards;
  }
  .progress[data-percentage="26"] .progress-left .progress-bar {
    animation: 0;
  }

  .progress[data-percentage="27"] .progress-right .progress-bar {
    animation: loading-27 0.5s linear forwards;
  }
  .progress[data-percentage="27"] .progress-left .progress-bar {
    animation: 0;
  }

  .progress[data-percentage="28"] .progress-right .progress-bar {
    animation: loading-28 0.5s linear forwards;
  }
  .progress[data-percentage="28"] .progress-left .progress-bar {
    animation: 0;
  }

  .progress[data-percentage="29"] .progress-right .progress-bar {
    animation: loading-29 0.5s linear forwards;
  }
  .progress[data-percentage="29"] .progress-left .progress-bar {
    animation: 0;
  }

  .progress[data-percentage="30"] .progress-right .progress-bar {
    animation: loading-30 0.5s linear forwards;
  }
  .progress[data-percentage="30"] .progress-left .progress-bar {
    animation: 0;
  }

  .progress[data-percentage="31"] .progress-right .progress-bar {
    animation: loading-31 0.5s linear forwards;
  }
  .progress[data-percentage="31"] .progress-left .progress-bar {
    animation: 0;
  }

  .progress[data-percentage="32"] .progress-right .progress-bar {
    animation: loading-32 0.5s linear forwards;
  }
  .progress[data-percentage="32"] .progress-left .progress-bar {
    animation: 0;
  }

  .progress[data-percentage="33"] .progress-right .progress-bar {
    animation: loading-33 0.5s linear forwards;
  }
  .progress[data-percentage="33"] .progress-left .progress-bar {
    animation: 0;
  }

  .progress[data-percentage="34"] .progress-right .progress-bar {
    animation: loading-34 0.5s linear forwards;
  }
  .progress[data-percentage="34"] .progress-left .progress-bar {
    animation: 0;
  }

  .progress[data-percentage="35"] .progress-right .progress-bar {
    animation: loading-35 0.5s linear forwards;
  }
  .progress[data-percentage="35"] .progress-left .progress-bar {
    animation: 0;
  }

  .progress[data-percentage="36"] .progress-right .progress-bar {
    animation: loading-36 0.5s linear forwards;
  }
  .progress[data-percentage="36"] .progress-left .progress-bar {
    animation: 0;
  }

  .progress[data-percentage="37"] .progress-right .progress-bar {
    animation: loading-37 0.5s linear forwards;
  }
  .progress[data-percentage="37"] .progress-left .progress-bar {
    animation: 0;
  }

  .progress[data-percentage="38"] .progress-right .progress-bar {
    animation: loading-38 0.5s linear forwards;
  }
  .progress[data-percentage="38"] .progress-left .progress-bar {
    animation: 0;
  }

  .progress[data-percentage="39"] .progress-right .progress-bar {
    animation: loading-39 0.5s linear forwards;
  }
  .progress[data-percentage="39"] .progress-left .progress-bar {
    animation: 0;
  }

  .progress[data-percentage="40"] .progress-right .progress-bar {
    animation: loading-40 0.5s linear forwards;
  }
  .progress[data-percentage="40"] .progress-left .progress-bar {
    animation: 0;
  }

  .progress[data-percentage="41"] .progress-right .progress-bar {
    animation: loading-41 0.5s linear forwards;
  }
  .progress[data-percentage="41"] .progress-left .progress-bar {
    animation: 0;
  }

  .progress[data-percentage="42"] .progress-right .progress-bar {
    animation: loading-42 0.5s linear forwards;
  }
  .progress[data-percentage="42"] .progress-left .progress-bar {
    animation: 0;
  }

  .progress[data-percentage="43"] .progress-right .progress-bar {
    animation: loading-43 0.5s linear forwards;
  }
  .progress[data-percentage="43"] .progress-left .progress-bar {
    animation: 0;
  }

  .progress[data-percentage="44"] .progress-right .progress-bar {
    animation: loading-44 0.5s linear forwards;
  }
  .progress[data-percentage="44"] .progress-left .progress-bar {
    animation: 0;
  }

  .progress[data-percentage="45"] .progress-right .progress-bar {
    animation: loading-45 0.5s linear forwards;
  }
  .progress[data-percentage="45"] .progress-left .progress-bar {
    animation: 0;
  }

  .progress[data-percentage="46"] .progress-right .progress-bar {
    animation: loading-46 0.5s linear forwards;
  }
  .progress[data-percentage="46"] .progress-left .progress-bar {
    animation: 0;
  }

  .progress[data-percentage="47"] .progress-right .progress-bar {
    animation: loading-47 0.5s linear forwards;
  }
  .progress[data-percentage="47"] .progress-left .progress-bar {
    animation: 0;
  }

  .progress[data-percentage="48"] .progress-right .progress-bar {
    animation: loading-48 0.5s linear forwards;
  }
  .progress[data-percentage="48"] .progress-left .progress-bar {
    animation: 0;
  }

  .progress[data-percentage="49"] .progress-right .progress-bar {
    animation: loading-49 0.5s linear forwards;
  }
  .progress[data-percentage="49"] .progress-left .progress-bar {
    animation: 0;
  }

  .progress[data-percentage="50"] .progress-right .progress-bar {
    animation: loading-50 0.5s linear forwards;
  }
  .progress[data-percentage="50"] .progress-left .progress-bar {
    animation: 0;
  }

  .progress[data-percentage="51"] .progress-right .progress-bar {
    animation: loading-50 0.5s linear forwards;
  }
  .progress[data-percentage="51"] .progress-left .progress-bar {
    animation: loading-1 0.5s linear forwards 0.5s;
  }

  .progress[data-percentage="52"] .progress-right .progress-bar {
    animation: loading-50 0.5s linear forwards;
  }
  .progress[data-percentage="52"] .progress-left .progress-bar {
    animation: loading-2 0.5s linear forwards 0.5s;
  }

  .progress[data-percentage="53"] .progress-right .progress-bar {
    animation: loading-50 0.5s linear forwards;
  }
  .progress[data-percentage="53"] .progress-left .progress-bar {
    animation: loading-3 0.5s linear forwards 0.5s;
  }

  .progress[data-percentage="54"] .progress-right .progress-bar {
    animation: loading-50 0.5s linear forwards;
  }
  .progress[data-percentage="54"] .progress-left .progress-bar {
    animation: loading-4 0.5s linear forwards 0.5s;
  }

  .progress[data-percentage="55"] .progress-right .progress-bar {
    animation: loading-50 0.5s linear forwards;
  }
  .progress[data-percentage="55"] .progress-left .progress-bar {
    animation: loading-5 0.5s linear forwards 0.5s;
  }

  .progress[data-percentage="56"] .progress-right .progress-bar {
    animation: loading-50 0.5s linear forwards;
  }
  .progress[data-percentage="56"] .progress-left .progress-bar {
    animation: loading-6 0.5s linear forwards 0.5s;
  }

  .progress[data-percentage="57"] .progress-right .progress-bar {
    animation: loading-50 0.5s linear forwards;
  }
  .progress[data-percentage="57"] .progress-left .progress-bar {
    animation: loading-7 0.5s linear forwards 0.5s;
  }

  .progress[data-percentage="58"] .progress-right .progress-bar {
    animation: loading-50 0.5s linear forwards;
  }
  .progress[data-percentage="58"] .progress-left .progress-bar {
    animation: loading-8 0.5s linear forwards 0.5s;
  }

  .progress[data-percentage="59"] .progress-right .progress-bar {
    animation: loading-50 0.5s linear forwards;
  }
  .progress[data-percentage="59"] .progress-left .progress-bar {
    animation: loading-9 0.5s linear forwards 0.5s;
  }

  .progress[data-percentage="60"] .progress-right .progress-bar {
    animation: loading-50 0.5s linear forwards;
  }
  .progress[data-percentage="60"] .progress-left .progress-bar {
    animation: loading-10 0.5s linear forwards 0.5s;
  }

  .progress[data-percentage="61"] .progress-right .progress-bar {
    animation: loading-50 0.5s linear forwards;
  }
  .progress[data-percentage="61"] .progress-left .progress-bar {
    animation: loading-11 0.5s linear forwards 0.5s;
  }

  .progress[data-percentage="62"] .progress-right .progress-bar {
    animation: loading-50 0.5s linear forwards;
  }
  .progress[data-percentage="62"] .progress-left .progress-bar {
    animation: loading-12 0.5s linear forwards 0.5s;
  }

  .progress[data-percentage="63"] .progress-right .progress-bar {
    animation: loading-50 0.5s linear forwards;
  }
  .progress[data-percentage="63"] .progress-left .progress-bar {
    animation: loading-13 0.5s linear forwards 0.5s;
  }

  .progress[data-percentage="64"] .progress-right .progress-bar {
    animation: loading-50 0.5s linear forwards;
  }
  .progress[data-percentage="64"] .progress-left .progress-bar {
    animation: loading-14 0.5s linear forwards 0.5s;
  }

  .progress[data-percentage="65"] .progress-right .progress-bar {
    animation: loading-50 0.5s linear forwards;
  }
  .progress[data-percentage="65"] .progress-left .progress-bar {
    animation: loading-15 0.5s linear forwards 0.5s;
  }

  .progress[data-percentage="66"] .progress-right .progress-bar {
    animation: loading-50 0.5s linear forwards;
  }
  .progress[data-percentage="66"] .progress-left .progress-bar {
    animation: loading-16 0.5s linear forwards 0.5s;
  }

  .progress[data-percentage="67"] .progress-right .progress-bar {
    animation: loading-50 0.5s linear forwards;
  }
  .progress[data-percentage="67"] .progress-left .progress-bar {
    animation: loading-17 0.5s linear forwards 0.5s;
  }

  .progress[data-percentage="68"] .progress-right .progress-bar {
    animation: loading-50 0.5s linear forwards;
  }
  .progress[data-percentage="68"] .progress-left .progress-bar {
    animation: loading-18 0.5s linear forwards 0.5s;
  }

  .progress[data-percentage="69"] .progress-right .progress-bar {
    animation: loading-50 0.5s linear forwards;
  }
  .progress[data-percentage="69"] .progress-left .progress-bar {
    animation: loading-19 0.5s linear forwards 0.5s;
  }

  .progress[data-percentage="70"] .progress-right .progress-bar {
    animation: loading-50 0.5s linear forwards;
  }
  .progress[data-percentage="70"] .progress-left .progress-bar {
    animation: loading-20 0.5s linear forwards 0.5s;
  }

  .progress[data-percentage="71"] .progress-right .progress-bar {
    animation: loading-50 0.5s linear forwards;
  }
  .progress[data-percentage="71"] .progress-left .progress-bar {
    animation: loading-21 0.5s linear forwards 0.5s;
  }

  .progress[data-percentage="72"] .progress-right .progress-bar {
    animation: loading-50 0.5s linear forwards;
  }
  .progress[data-percentage="72"] .progress-left .progress-bar {
    animation: loading-22 0.5s linear forwards 0.5s;
  }

  .progress[data-percentage="73"] .progress-right .progress-bar {
    animation: loading-50 0.5s linear forwards;
  }
  .progress[data-percentage="73"] .progress-left .progress-bar {
    animation: loading-23 0.5s linear forwards 0.5s;
  }

  .progress[data-percentage="74"] .progress-right .progress-bar {
    animation: loading-50 0.5s linear forwards;
  }
  .progress[data-percentage="74"] .progress-left .progress-bar {
    animation: loading-24 0.5s linear forwards 0.5s;
  }

  .progress[data-percentage="75"] .progress-right .progress-bar {
    animation: loading-50 0.5s linear forwards;
  }
  .progress[data-percentage="75"] .progress-left .progress-bar {
    animation: loading-25 0.5s linear forwards 0.5s;
  }

  .progress[data-percentage="76"] .progress-right .progress-bar {
    animation: loading-50 0.5s linear forwards;
  }
  .progress[data-percentage="76"] .progress-left .progress-bar {
    animation: loading-26 0.5s linear forwards 0.5s;
  }

  .progress[data-percentage="77"] .progress-right .progress-bar {
    animation: loading-50 0.5s linear forwards;
  }
  .progress[data-percentage="77"] .progress-left .progress-bar {
    animation: loading-27 0.5s linear forwards 0.5s;
  }

  .progress[data-percentage="78"] .progress-right .progress-bar {
    animation: loading-50 0.5s linear forwards;
  }
  .progress[data-percentage="78"] .progress-left .progress-bar {
    animation: loading-28 0.5s linear forwards 0.5s;
  }

  .progress[data-percentage="79"] .progress-right .progress-bar {
    animation: loading-50 0.5s linear forwards;
  }
  .progress[data-percentage="79"] .progress-left .progress-bar {
    animation: loading-29 0.5s linear forwards 0.5s;
  }

  .progress[data-percentage="80"] .progress-right .progress-bar {
    animation: loading-50 0.5s linear forwards;
  }
  .progress[data-percentage="80"] .progress-left .progress-bar {
    animation: loading-30 0.5s linear forwards 0.5s;
  }

  .progress[data-percentage="81"] .progress-right .progress-bar {
    animation: loading-50 0.5s linear forwards;
  }
  .progress[data-percentage="81"] .progress-left .progress-bar {
    animation: loading-31 0.5s linear forwards 0.5s;
  }

  .progress[data-percentage="82"] .progress-right .progress-bar {
    animation: loading-50 0.5s linear forwards;
  }
  .progress[data-percentage="82"] .progress-left .progress-bar {
    animation: loading-32 0.5s linear forwards 0.5s;
  }

  .progress[data-percentage="83"] .progress-right .progress-bar {
    animation: loading-50 0.5s linear forwards;
  }
  .progress[data-percentage="83"] .progress-left .progress-bar {
    animation: loading-33 0.5s linear forwards 0.5s;
  }

  .progress[data-percentage="84"] .progress-right .progress-bar {
    animation: loading-50 0.5s linear forwards;
  }
  .progress[data-percentage="84"] .progress-left .progress-bar {
    animation: loading-34 0.5s linear forwards 0.5s;
  }

  .progress[data-percentage="85"] .progress-right .progress-bar {
    animation: loading-50 0.5s linear forwards;
  }
  .progress[data-percentage="85"] .progress-left .progress-bar {
    animation: loading-35 0.5s linear forwards 0.5s;
  }

  .progress[data-percentage="86"] .progress-right .progress-bar {
    animation: loading-50 0.5s linear forwards;
  }
  .progress[data-percentage="86"] .progress-left .progress-bar {
    animation: loading-36 0.5s linear forwards 0.5s;
  }

  .progress[data-percentage="87"] .progress-right .progress-bar {
    animation: loading-50 0.5s linear forwards;
  }
  .progress[data-percentage="87"] .progress-left .progress-bar {
    animation: loading-37 0.5s linear forwards 0.5s;
  }

  .progress[data-percentage="88"] .progress-right .progress-bar {
    animation: loading-50 0.5s linear forwards;
  }
  .progress[data-percentage="88"] .progress-left .progress-bar {
    animation: loading-38 0.5s linear forwards 0.5s;
  }

  .progress[data-percentage="89"] .progress-right .progress-bar {
    animation: loading-50 0.5s linear forwards;
  }
  .progress[data-percentage="89"] .progress-left .progress-bar {
    animation: loading-39 0.5s linear forwards 0.5s;
  }

  .progress[data-percentage="90"] .progress-right .progress-bar {
    animation: loading-50 0.5s linear forwards;
  }
  .progress[data-percentage="90"] .progress-left .progress-bar {
    animation: loading-40 0.5s linear forwards 0.5s;
  }

  .progress[data-percentage="91"] .progress-right .progress-bar {
    animation: loading-50 0.5s linear forwards;
  }
  .progress[data-percentage="91"] .progress-left .progress-bar {
    animation: loading-41 0.5s linear forwards 0.5s;
  }

  .progress[data-percentage="92"] .progress-right .progress-bar {
    animation: loading-50 0.5s linear forwards;
  }
  .progress[data-percentage="92"] .progress-left .progress-bar {
    animation: loading-42 0.5s linear forwards 0.5s;
  }

  .progress[data-percentage="93"] .progress-right .progress-bar {
    animation: loading-50 0.5s linear forwards;
  }
  .progress[data-percentage="93"] .progress-left .progress-bar {
    animation: loading-43 0.5s linear forwards 0.5s;
  }

  .progress[data-percentage="94"] .progress-right .progress-bar {
    animation: loading-50 0.5s linear forwards;
  }
  .progress[data-percentage="94"] .progress-left .progress-bar {
    animation: loading-44 0.5s linear forwards 0.5s;
  }

  .progress[data-percentage="95"] .progress-right .progress-bar {
    animation: loading-50 0.5s linear forwards;
  }
  .progress[data-percentage="95"] .progress-left .progress-bar {
    animation: loading-45 0.5s linear forwards 0.5s;
  }

  .progress[data-percentage="96"] .progress-right .progress-bar {
    animation: loading-50 0.5s linear forwards;
  }
  .progress[data-percentage="96"] .progress-left .progress-bar {
    animation: loading-46 0.5s linear forwards 0.5s;
  }

  .progress[data-percentage="97"] .progress-right .progress-bar {
    animation: loading-50 0.5s linear forwards;
  }
  .progress[data-percentage="97"] .progress-left .progress-bar {
    animation: loading-47 0.5s linear forwards 0.5s;
  }

  .progress[data-percentage="98"] .progress-right .progress-bar {
    animation: loading-50 0.5s linear forwards;
  }
  .progress[data-percentage="98"] .progress-left .progress-bar {
    animation: loading-48 0.5s linear forwards 0.5s;
  }

  .progress[data-percentage="99"] .progress-right .progress-bar {
    animation: loading-50 0.5s linear forwards;
  }
  .progress[data-percentage="99"] .progress-left .progress-bar {
    animation: loading-49 0.5s linear forwards 0.5s;
  }

  .progress[data-percentage="100"] .progress-right .progress-bar {
    animation: loading-50 0.5s linear forwards;
  }
  .progress[data-percentage="100"] .progress-left .progress-bar {
    animation: loading-50 0.5s linear forwards 0.5s;
  }

  @keyframes loading-1 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(3.6);
      transform: rotate(3.6deg);
    }
  }
  @keyframes loading-2 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(7.2);
      transform: rotate(7.2deg);
    }
  }
  @keyframes loading-3 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(10.8);
      transform: rotate(10.8deg);
    }
  }
  @keyframes loading-4 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(14.4);
      transform: rotate(14.4deg);
    }
  }
  @keyframes loading-5 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(18);
      transform: rotate(18deg);
    }
  }
  @keyframes loading-6 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(21.6);
      transform: rotate(21.6deg);
    }
  }
  @keyframes loading-7 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(25.2);
      transform: rotate(25.2deg);
    }
  }
  @keyframes loading-8 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(28.8);
      transform: rotate(28.8deg);
    }
  }
  @keyframes loading-9 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(32.4);
      transform: rotate(32.4deg);
    }
  }
  @keyframes loading-10 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(36);
      transform: rotate(36deg);
    }
  }
  @keyframes loading-11 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(39.6);
      transform: rotate(39.6deg);
    }
  }
  @keyframes loading-12 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(43.2);
      transform: rotate(43.2deg);
    }
  }
  @keyframes loading-13 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(46.8);
      transform: rotate(46.8deg);
    }
  }
  @keyframes loading-14 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(50.4);
      transform: rotate(50.4deg);
    }
  }
  @keyframes loading-15 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(54);
      transform: rotate(54deg);
    }
  }
  @keyframes loading-16 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(57.6);
      transform: rotate(57.6deg);
    }
  }
  @keyframes loading-17 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(61.2);
      transform: rotate(61.2deg);
    }
  }
  @keyframes loading-18 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(64.8);
      transform: rotate(64.8deg);
    }
  }
  @keyframes loading-19 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(68.4);
      transform: rotate(68.4deg);
    }
  }
  @keyframes loading-20 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(72);
      transform: rotate(72deg);
    }
  }
  @keyframes loading-21 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(75.6);
      transform: rotate(75.6deg);
    }
  }
  @keyframes loading-22 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(79.2);
      transform: rotate(79.2deg);
    }
  }
  @keyframes loading-23 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(82.8);
      transform: rotate(82.8deg);
    }
  }
  @keyframes loading-24 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(86.4);
      transform: rotate(86.4deg);
    }
  }
  @keyframes loading-25 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(90);
      transform: rotate(90deg);
    }
  }
  @keyframes loading-26 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(93.6);
      transform: rotate(93.6deg);
    }
  }
  @keyframes loading-27 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(97.2);
      transform: rotate(97.2deg);
    }
  }
  @keyframes loading-28 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(100.8);
      transform: rotate(100.8deg);
    }
  }
  @keyframes loading-29 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(104.4);
      transform: rotate(104.4deg);
    }
  }
  @keyframes loading-30 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(108);
      transform: rotate(108deg);
    }
  }
  @keyframes loading-31 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(111.6);
      transform: rotate(111.6deg);
    }
  }
  @keyframes loading-32 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(115.2);
      transform: rotate(115.2deg);
    }
  }
  @keyframes loading-33 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(118.8);
      transform: rotate(118.8deg);
    }
  }
  @keyframes loading-34 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(122.4);
      transform: rotate(122.4deg);
    }
  }
  @keyframes loading-35 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(126);
      transform: rotate(126deg);
    }
  }
  @keyframes loading-36 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(129.6);
      transform: rotate(129.6deg);
    }
  }
  @keyframes loading-37 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(133.2);
      transform: rotate(133.2deg);
    }
  }
  @keyframes loading-38 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(136.8);
      transform: rotate(136.8deg);
    }
  }
  @keyframes loading-39 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(140.4);
      transform: rotate(140.4deg);
    }
  }
  @keyframes loading-40 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(144);
      transform: rotate(144deg);
    }
  }
  @keyframes loading-41 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(147.6);
      transform: rotate(147.6deg);
    }
  }
  @keyframes loading-42 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(151.2);
      transform: rotate(151.2deg);
    }
  }
  @keyframes loading-43 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(154.8);
      transform: rotate(154.8deg);
    }
  }
  @keyframes loading-44 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(158.4);
      transform: rotate(158.4deg);
    }
  }
  @keyframes loading-45 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(162);
      transform: rotate(162deg);
    }
  }
  @keyframes loading-46 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(165.6);
      transform: rotate(165.6deg);
    }
  }
  @keyframes loading-47 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(169.2);
      transform: rotate(169.2deg);
    }
  }
  @keyframes loading-48 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(172.8);
      transform: rotate(172.8deg);
    }
  }
  @keyframes loading-49 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(176.4);
      transform: rotate(176.4deg);
    }
  }
  @keyframes loading-50 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(180);
      transform: rotate(180deg);
    }
  }
}
.intake {
  background: #f5f5f5;
  width: 80px;
  margin: 0 auto;
  height: 85px;
  border: 3px solid #ccc;
  display: inline-block;
}
.intake:hover {
  box-shadow: 4px 6px 2px #c7c7c7;
  background: #ffffff;
}
.intake-txt {
  font-size: 12px;
}
.intake .fa-check-circle {
  color: green;
  position: absolute;
  right: 30px;
}
.intake .fa-minus-circle {
  color: orange;
  position: absolute;
  right: 30px;
}
.intake .fa-times-circle {
  color: red;
  position: absolute;
  right: 30px;
}
.progres-status-indicator {
  position: relative;
  width: 100%;
  padding: 20px 10px;
  border-radius: 4px;
  box-sizing: border-box;
  background: #fff;
  // box-shadow: 0 2px 14px rgb(218 218 218 / 50%);
}
.progres-status-indicator .progress-bar-success {
  background: green;
}
/*question*/
.privew {
  margin-bottom: 20px;
}
.questionsBox {
  display: block;
  border: solid 1px #e3e3e3;
  padding: 10px 20px 0px;
  box-shadow: inset 0 0 30px rgba(000, 000, 000, 0.1),
    inset 0 0 4px rgba(255, 255, 255, 1);
  border-radius: 3px;
  margin: 0 10px;
  background: #fff;
}
.questions {
  background: #7b8188;
  color: #fff;
  font-size: 22px;
  padding: 8px 30px;
  font-weight: 300;
  margin: 0 -30px 10px;
  position: relative;
}
.questions:before,
.questions:after {
  background: url(../../../assets/images/icon-question.png) no-repeat left 0;
  display: block;
  position: absolute;
  top: 100%;
  width: 9px;
  height: 7px;
  content: ".";
  left: 0;
  text-align: left;
  font-size: 0;
}
.questions:after {
  left: auto;
  right: 0;
  background-position: -10px 0;
}
.answerList {
  margin-bottom: 15px;
  list-style: none;
  padding: 0;
}
.answerList li:first-child {
  border-top-width: 0;
}
.answerList li {
  padding: 3px 0;
}
.answerList li input {
  vertical-align: middle;
  height: 15px;
  width: 15px;
  margin-right: 5px;
}
.answerList label {
  display: block;
  padding: 12px;
  border-radius: 6px;
  border: solid 1px #dde7e8;
  font-weight: 400;
  font-size: 13px;
  cursor: pointer;
  font-family: Arial, sans-serif;
}
.questionsRow {
  background: #dee3e6;
  margin: 0 -20px;
  padding: 10px 20px;
  border-radius: 0 0 3px 3px;
  display: flex;
}
.greyButton {
  background-color: #f2f2f2;
  color: #888888;
  display: inline-block;
  border: solid 3px #cccccc;
  vertical-align: middle;
  text-shadow: 0 1px 0 #ffffff;
  line-height: 27px;
  min-width: 160px;
  text-align: center;
  padding: 5px 20px;
  text-decoration: none;
  border-radius: 0px;
  text-transform: capitalize;
  margin-right: 5px;
}
.greyButton:hover {
  background: $primary-clr;
  color: #ffffff;
  text-shadow: none;
  text-decoration: none;
  border-color: $primary-clr;
}
.questionsRow span {
  display: inline-block;
  line-height: 30px;
  height: 32px;
  border: solid 1px #aeb9c0;
  padding: 0 10px;
  background: #fff;
  color: #007fbe;
}
.answerList li:hover label {
  border-color: #d1d1d1;
  background: #f2fafb;
}
.complete-icon {
  color: #1c9035;
}
.extra-info {
  font-size: 14px;
  font-weight: 500;
  color: #173a64;
}
.rate-numb {
  padding: 3px 12px;
  margin-right: 29px;
  display: inline-block;
  background: #7b8188;
  border: 1px solid #ccc;
  border-radius: 50%;
  color: #fff !important;
  text-decoration: none !important;
  cursor: pointer;
}
.rate-numb.active {
  background: #173a64;
  color: #fff;
  text-decoration: none !important;
}
a.rate-numb:hover {
  background: #173a64;
  color: #fff;
}
.option-text {
  border: 1px solid #ccc;
  padding: 5px;
  margin-top: -10px;
  background: #eefefe;
}
.option-text textarea {
  width: 100%;
}
.status-consent {
  font-size: 9px;
  color: #713eec;
  font-weight: 600;
}
.status-consent span {
  font-size: 11px;
}
.screening {
  .form-group {
    margin-bottom: 25px;
  }
  .screen-note {
    background: $CitrineWhite;
    color: $Toast;
    padding: 20px;
    margin-bottom: 15px;
  }
  .yesno-label {
    color: #000;
  }
  .textarea-placehold::-webkit-input-placeholder {
    font-style: italic;
  }
  .textarea-placehold:-moz-placeholder {
    font-style: italic;
  }
  .textarea-placehold::-moz-placeholder {
    font-style: italic;
  }
  .textarea-placehold:-ms-input-placeholder {
    font-style: italic;
  }
  // .switch {
  //     position: relative;
  //     display: table;
  //     width: 43px;
  //     height: 25px;
  //     margin: 0 auto;
  //   }
  .switch {
    position: relative;
    display: table;
    width: 65px;
    height: 30px;
    margin: 0 auto;
  }

  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $DoveGray;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  .slider:before {
    position: absolute;
    content: "";
    height: 17px;
    width: 17px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  input:checked + .slider {
    background-color: $ChateauGreen;
  }

  input:focus + .slider {
    box-shadow: 0 0 1px #2196f3;
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(18px);
    -ms-transform: translateX(18px);
    transform: translateX(18px);
  }

  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }

  .slider.round:before {
    border-radius: 50%;
  }
}
.update-btn-consent {
  margin-left: 115px;
}
.verified-section {
  background: #efefef;
  padding: 10px;
}
.dynamic-field {
  color: #5058cb;
}
.create-consent-page .ng-invalid .ql-blank {
  border: 1px solid red;
}
.pagination-custom .question-label {
  padding-right: 12px;
  font-weight: 500;
  font-size: 14px;
  margin-top: 4px;
  display: inline-block;
}
.pagination-custom {
  display: inline-block;
  height: 40px;
  width: 180px;
  overflow-x: hidden;
  overflow-y: hidden;
  vertical-align: middle;
  .pagination .page-link:focus {
    box-shadow: none;
  }
  .pagination > .page-item > .page-link,
  .pagination > .page-item > span {
    border: 0;
    border-radius: 30px !important;
    transition: all 0.3s;
    padding: 0 11px !important;
    margin: 0 3px !important;
    min-width: 30px;
    height: 30px;
    line-height: 30px;
    font-weight: 400;
    font-size: 12px;
    text-transform: uppercase;
    background: 0 0;
    text-align: center;
    background-color: #7b7b7b;
    border-color: #7b7b7b;
    color: #fff;
    box-shadow: 0 4px 5px 0 rgba(156, 39, 176, 0.14),
      0 1px 10px 0 rgba(156, 39, 176, 0.12),
      0 2px 4px -1px rgba(156, 39, 176, 0.2);
  }
  .pagination > .page-item > .page-link_new {
    border: 0;
    border-radius: 30px !important;
    transition: all 0.3s;
    padding: 0 11px !important;
    margin: 0 3px !important;
    min-width: 30px;
    height: 30px;
    line-height: 30px;
    font-weight: 400;
    font-size: 12px;
    text-transform: uppercase;
    background: 0 0;
    text-align: center;
    color: #999;
  }

  .pagination > .page-item.active > a,
  .pagination > .page-item.active > a:focus,
  .pagination > .page-item.active > a:hover,
  .pagination > .page-item.active > span,
  .pagination > .page-item.active > span:focus,
  .pagination > .page-item.active > span:hover {
    background-color: #9c27b0;
    border-color: #9c27b0;
    color: #fff;
    box-shadow: 0 4px 5px 0 rgba(156, 39, 176, 0.14),
      0 1px 10px 0 rgba(156, 39, 176, 0.12),
      0 2px 4px -1px rgba(156, 39, 176, 0.2);
  }

  .pagination.pagination-info > .page-item.active > a,
  .pagination.pagination-info > .page-item.active > a:focus,
  .pagination.pagination-info > .page-item.active > a:hover,
  .pagination.pagination-info > .page-item.active > span,
  .pagination.pagination-info > .page-item.active > span:focus,
  .pagination.pagination-info > .page-item.active > span:hover {
    background-color: $primary-clr;
    border-color: $primary-clr;
    box-shadow: 0 4px 5px 0 rgba(0, 188, 212, 0.14),
      0 1px 10px 0 rgba(0, 188, 212, 0.12),
      0 2px 4px -1px rgba(0, 188, 212, 0.2);
  }

  .pagination > .page-item.completed > a,
  .pagination > .page-item.completed > a:focus,
  .pagination > .page-item.completed > a:hover,
  .pagination > .page-item.completed > span,
  .pagination > .page-item.completed > span:focus,
  .pagination > .page-item.completed > span:hover {
    background-color: #4caf50;
    border-color: #4caf50;
    box-shadow: 0 4px 5px 0 rgba(76, 175, 80, 0.14),
      0 1px 10px 0 rgba(76, 175, 80, 0.12),
      0 2px 4px -1px rgba(76, 175, 80, 0.2);
    color: #fff;
  }

  .pagination.pagination-warning > .page-item.active > a,
  .pagination.pagination-warning > .page-item.active > a:focus,
  .pagination.pagination-warning > .page-item.active > a:hover,
  .pagination.pagination-warning > .page-item.active > span,
  .pagination.pagination-warning > .page-item.active > span:focus,
  .pagination.pagination-warning > .page-item.active > span:hover {
    background-color: #ff9800;
    border-color: #ff9800;
    box-shadow: 0 4px 5px 0 rgba(255, 152, 0, 0.14),
      0 1px 10px 0 rgba(255, 152, 0, 0.12),
      0 2px 4px -1px rgba(255, 152, 0, 0.2);
  }

  .pagination.pagination-danger > .page-item.active > a,
  .pagination.pagination-danger > .page-item.active > a:focus,
  .pagination.pagination-danger > .page-item.active > a:hover,
  .pagination.pagination-danger > .page-item.active > span,
  .pagination.pagination-danger > .page-item.active > span:focus,
  .pagination.pagination-danger > .page-item.active > span:hover {
    background-color: #f44336;
    border-color: #f44336;
    box-shadow: 0 4px 5px 0 rgba(244, 67, 54, 0.14),
      0 1px 10px 0 rgba(244, 67, 54, 0.12),
      0 2px 4px -1px rgba(244, 67, 54, 0.2);
  }
}
.crba.minus:before {
  content: "\2212";
  position: absolute;
  height: 30px;
  width: 30px;
  background-color: #ffcc00;
  border-radius: 50%;
  bottom: 0;
  left: -9px;
  border: 5px solid #fff;
  color: #ffff;
}
.crba.minus:after {
  content: "−";
  position: absolute;
  color: #ffffff;
  bottom: 3px;
  left: 2vpx;
  font-size: 15px;
}
.survey-clr {
  color: #82970f !important;
  font-weight: 600 !important;
}
.checklist-clr {
  font-weight: 600 !important;
  color: #710b8a !important;
}
.nutrition-clr {
  font-weight: 600 !important;
  color: #1fc8b1 !important;
}
.switch {
  position: relative;
  display: block;
  vertical-align: top;
  width: 100px;
  height: 30px;
  padding: 3px;
  margin: 0 10px 10px 0;
  background: linear-gradient(to bottom, #eeeeee, #ffffff 25px);
  background-image: -webkit-linear-gradient(top, #eeeeee, #ffffff 25px);
  border-radius: 18px;
  box-shadow: inset 0 -1px white, inset 0 1px 1px rgba(0, 0, 0, 0.05);
  cursor: pointer;
  box-sizing: content-box;
}
.switch-input {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  box-sizing: content-box;
}
.switch-label {
  position: relative;
  display: block;
  height: inherit;
  font-size: 10px;
  text-transform: uppercase;
  background: rgb(102, 102, 102);
  border-radius: inherit;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.12),
    inset 0 0 2px rgba(0, 0, 0, 0.15);
  box-sizing: content-box;
}
.switch-label:before,
.switch-label:after {
  position: absolute;
  top: 50%;
  margin-top: -0.5em;
  line-height: 1;
  -webkit-transition: inherit;
  -moz-transition: inherit;
  -o-transition: inherit;
  transition: inherit;
  box-sizing: content-box;
}
.switch-label:before {
  content: attr(data-off);
  right: 11px;
  color: #fff;
  text-shadow: 0 1px rgba(255, 255, 255, 0.5);
}
.switch-label:after {
  content: attr(data-on);
  left: 11px;
  color: #ffffff;
  text-shadow: 0 1px rgba(0, 0, 0, 0.2);
  opacity: 0;
}
.switch-input:checked ~ .switch-label {
  background: rgb(58, 181, 75);
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.15),
    inset 0 0 3px rgba(0, 0, 0, 0.2);
}
.switch-input:checked ~ .switch-label:before {
  opacity: 0;
}
.switch-input:checked ~ .switch-label:after {
  opacity: 1;
}
.switch-handle {
  position: absolute;
  top: 4px;
  left: 4px;
  width: 28px;
  height: 28px;
  background: linear-gradient(to bottom, #ffffff 40%, #f0f0f0);
  background-image: -webkit-linear-gradient(top, #ffffff 40%, #f0f0f0);
  border-radius: 100%;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.2);
}
.switch-handle:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -6px 0 0 -6px;
  width: 12px;
  height: 12px;
  background: linear-gradient(to bottom, #eeeeee, #ffffff);
  background-image: -webkit-linear-gradient(top, #eeeeee, #ffffff);
  border-radius: 6px;
  box-shadow: inset 0 1px rgba(0, 0, 0, 0.02);
}
.switch-input:checked ~ .switch-handle {
  left: 38px;
  box-shadow: -1px 1px 5px rgba(0, 0, 0, 0.2);
}

/* Transition
========================== */
.switch-label,
.switch-handle {
  transition: All 0.3s ease;
  -webkit-transition: All 0.3s ease;
  -moz-transition: All 0.3s ease;
  -o-transition: All 0.3s ease;
}
// .modern-txtarea {
//   border-top: none;
//   border-left: none;
//   border-right: none;
// }
// .modern-txtarea::placeholder {
//   font-size: 14px;
//   color:#000;
// }
.qustion-pagination {
  margin-left: auto;
  width: 400px;
}
.qustion-pagination .page-arrow {
  display: inline-block;
  padding: 10px;
}
.patient-forms {
  float: left;
  width: 100%;
}
.patient-forms a {
  margin-right: 10px;
  background: #efefef;
  padding: 6px 10px;
  margin-bottom: 13px;
  display: inline-block;
  border-radius: 50%;
  border: 1px solid #555;
  cursor: pointer;
}
.patient-forms img {
  width: 23px;
}
.patient-forms a:hover {
  box-shadow: 4px 6px 2px #c7c7c7;
  background: #ffffff;
  border-color: #173a64;
}
/*Generte intake*/
.steps .step {
  display: block;
  width: 100%;
  margin-bottom: 8px;
  text-align: center;
}

.steps .step .step-icon-wrap {
  display: block;
  position: relative;
  width: 100%;
  height: 40px;
  text-align: center;
  cursor: pointer;
}

.steps .step .step-icon-wrap::before,
.steps .step .step-icon-wrap::after {
  display: block;
  position: absolute;
  top: 50%;
  width: 50%;
  height: 3px;
  margin-top: -1px;
  background-color: #e1e7ec;
  content: "";
  z-index: 1;
}

.steps .step .step-icon-wrap::before {
  left: 0;
}

.steps .step .step-icon-wrap::after {
  right: 0;
}

.steps .step .step-icon {
  display: inline-block;
  position: relative;
  width: 40px;
  height: 40px;
  border: 1px solid #e1e7ec;
  border-radius: 50%;
  background-color: #f5f5f5;
  color: #374250;
  font-size: 38px;
  line-height: 81px;
  z-index: 5;
}

.steps .step .step-title {
  margin-top: 6px;
  margin-bottom: 0;
  color: #606975;
  font-size: 13px;
  font-weight: 500;
}
.step.completed .step-title {
  color: #000;
  font-weight: 600;
}

.steps .step:first-child .step-icon-wrap::before {
  display: none;
}

.steps .step:last-child .step-icon-wrap::after {
  display: none;
}

// .steps .step.completed .step-icon-wrap::before,
// .steps .step.completed .step-icon-wrap::after {
//   background-color: $primary-clr;
// }
.step-icon i {
  font-size: 20px;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  position: absolute;
  top: 9px;
  left: 10px;
}
.steps .step.completed .step-icon {
  border-color: #399adf;
  background-color: #399adf;
  color: #fff;
}

@media (max-width: 576px) {
  .flex-sm-nowrap .step .step-icon-wrap::before,
  .flex-sm-nowrap .step .step-icon-wrap::after {
    display: none;
  }
}

@media (max-width: 768px) {
  .flex-md-nowrap .step .step-icon-wrap::before,
  .flex-md-nowrap .step .step-icon-wrap::after {
    display: none;
  }
}

@media (max-width: 991px) {
  .flex-lg-nowrap .step .step-icon-wrap::before,
  .flex-lg-nowrap .step .step-icon-wrap::after {
    display: none;
  }
}

@media (max-width: 1200px) {
  .flex-xl-nowrap .step .step-icon-wrap::before,
  .flex-xl-nowrap .step .step-icon-wrap::after {
    display: none;
  }
}
.table-label {
  // color: #909090 !important;
  padding-left: 10px !important;
  padding-right: 0 !important;
  padding-top: 10px;
  margin: 0;
  font-size: 14px;
  color: #494747;
  font-weight: 500;
  // font-size: 12px;
}
.users-view-username {
  padding-left: 10px !important;
  padding-top: 5px;
  padding-bottom: 10px;
  margin: 0;
  font-size: 14px;
  color: #173a64;
  font-weight: 500;
  padding: 10px;
}
.generate-intake h6 {
  padding: 10px;
  border-radius: 0;
  border-bottom: 1px solid #ccc;
}
.contact-type {
  float: right;
  color: #173a64;
  font-weight: 600;
  padding: 4px;
  position: relative;
  margin-top: -42px;
  right: 13px;
  border: 1px solid;
  font-size: 10px;
  border-radius: 5px;
}
.checklist-viewpage {
  font-size: 0.9375rem;
}
.checklist-viewpage .table thead th {
  border-bottom: 1px solid #dee2e6 !important;
}
.generate-intake td {
  font-size: 14px;
  // padding-left: 20px;
}
// .checklist-viewpage i{
//   color: #089649;
// }
.custom-view-text {
  padding-left: 0;
  border-bottom: 1px dotted #b1b1b1;
  border-radius: 2px;
  font-style: italic;
  margin-bottom: 10px;
  width: 100%;
  font-size: 14px;
}
.blue-label {
  font-size: 14px;
  color: #173a64 !important;
  font-weight: 500;
}

.no-date-indicate {
  border-bottom: 1px solid orange;
  border-radius: 0;
  color: #edb10a;
  padding: 5px;
  text-align: right;
}
.ans-section {
  border: 1px solid #c9c9c9;
  padding: 8px;
  background: #f5f5f5;
  height: 100%;
}
.ans-text-section {
  border: 1px solid #c9c9c9;
  padding: 10px;
  background: #f5f5f5;
  height: 100%;
}
.option-tick img {
  width: 20px;
  margin-right: 10px;
}
.ans-section-head {
  font-weight: 600;
  color: #000;
}
.option-tick {
  font-weight: 500;
  margin-top: 8px;
}
.answer-text {
  border: 1px solid #555;
  padding: 10px;
  font-size: 14px;
  font-weight: 500;
  color: #555;
  border-radius: 0px;
  margin-top: 8px;
  background: #fff;
  margin-bottom: 0;
  min-height: 50px !important;
}
.back-btn-new {
  float: right;
  padding: 5px 10px;
  font-size: 15px;
  border: 1px solid #374250;
  font-weight: 500;
  color: #555;
  &.back-btn-margin {
    margin-right: 15px;
  }
}

.export-pdf-btn {
  float: right;
  padding: 0;
  margin-right: 15px;
  font-size: 22px;
  border: 2px solid;
  padding: 4px 8px;
  font-size: 13px;
  border-radius: 0;
}
.generate-btn {
  float: right;
  padding: 5px 10px;
  font-size: 15px;
  border: 1px solid #173a64;
  font-weight: 500;
  color: #173a64;
  margin-right: 5px;
  &.no-float {
    float: none;
  }
}
.expression {
  text-align: center;
  margin-bottom: 10px;
  font-weight: 500;
}

.verifiedlable {
  float: right;
  color: green;
  padding: 5px;
  font-size: 13px;
  border: 1px solid green;
}
.unverified {
  float: right;
  color: orange;
  padding: 5px;
  font-size: 13px;
  border: 1px solid orange;
}
.gen-height {
  height: 350px;
  overflow-y: auto;
  overflow-x: hidden;
}
.chma-height {
  height: 440px;
  overflow-y: auto;
  overflow-x: hidden;
}
.btn-next-save {
  background-color: #235795 !important;
  border: 2px solid #235795 !important;
}
.meta-label {
  font-size: 11px;
  display: block;
  margin-bottom: 0px;
  color: #1c7d81;
  font-weight: 600;
}
.meta-value {
  font-weight: 500;
  font-size: 14px;
  width: 100%;
}
.meta-info-details {
  border-bottom: 2px solid #21b19d;
  padding-bottom: 10px;
}
label.meta-top-label {
  font-weight: 600;
  margin-top: 10px;
  color: #21b18f;
}
a.show-hide-btn {
  font-size: 12px;
  margin-right: 10px;
  margin-top: 6px;
  text-decoration: underline;
  color: #21b18f;
}
.btn.arrow-btn {
  padding: 5px;
  background: #fff;
  box-shadow: none;
}
.no-intake-page h2 {
  color: #173a64;
  font-size: 18px;
}
.intake-head-sub-sub2 {
  font-size: 13px;
  color: #212529;
  font-weight: 500;

  margin-top: 10px;
  line-height: 24px;
}
.gt-list .invi-check {
  position: absolute;
  left: 31px;
  top: 4px;
}
.gt-list .val-list {
  font-size: 12px;
  color: #173a64;
  font-weight: 500;
}
.slot-label {
  color: #173a64;
  font-weight: 500;
  font-size: 15px;
}
.slot-img {
  color: green;
}
.slot-count {
  font-weight: 500;
  color: #fff;
  background: #173a64;
  padding: 3px 7px;
  border-radius: 50%;
  font-size: 12px;
}
.intake-calendar .cal-event-title {
  color: #000;
}
// .intake-calendar .cal-week-view .cal-day-column {
//   -webkit-box-flex: 1;
//   -ms-flex-positive: 1;
//   flex-grow: 1;
//   flex: 0 0 5em;
// }
// .intake-calendar .cal-week-view .cal-day-headers .cal-header {
//   -webkit-box-flex: 1;
//   -ms-flex: 1;
//   flex: 0 0 5em;
//   text-align: center;
//   padding: 5px;
// }
// .intake-calendar .cal-week-view {
//   background-color: #fff;
//   border-top: solid 1px #e1e1e1;
//   overflow: auto;
// }
// .intake-calendar .cal-week-view .cal-day-headers{
//   border: none;
// }
// .intake-calendar .cal-week-view .cal-time-events{
//   border: none;
// }
.intake-calendar .cal-week-view .cal-time-events {
  max-height: 300px;
  overflow-y: auto;
}
.intake-calendar .cal-day-headers {
  margin-right: 14.5px;
}
.schdule-calendar .cal-week-view .cal-time-events {
  max-height: 390px;
  overflow-y: auto;
}
.schdule-calendar .cal-day-headers {
  margin-right: 14.5px;
}
.month-scheduler .cal-month-view .cal-days {
  max-height: 360px;
  overflow-y: auto;
}
.intake-calendar .cal-day-columns {
  height: 100%;
}

// .scrolled {
//   position: fixed;
//   top: 125px;
//   background: #fff;
//   z-index: 999;
// }
.user-diff span {
  margin-right: 10px;
  font-weight: 500;
  color: #555;
  vertical-align: top;
  font-size: 13px;
}
.meta-data-prof {
  font-weight: bold;
  font-size: 12px;
  color: #173a64;
  margin-left: 26px;
}
.user-diff {
  background: aliceblue;
  padding: 0 15px;
  line-height: 22px;
  height: 24px;
  margin-right: 10px;
  color: #fff;
}
.user1 i {
  color: #c34faf;
  font-size: 10px;
}
.user2 i {
  color: #abad2c;
  font-size: 10px;
}
.edit-icon-current {
  position: absolute;
  right: 29px;
  top: 19px;
  font-size: 20px;
}
.generate-confirm .modal-dialog {
  top: 35%;
}
.generate-confirm {
  background: rgba(0, 0, 0, 0.4);
}
