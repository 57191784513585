.inbox-section {

    .mess-head {
        color: $primary-clr;
        font-weight: 500;
        margin-top: 20px;
    }
    .card {
        border: none;
        border-radius: 10px;
    }
    .card-head {
        color: #173a64;
        font-weight: 600;
        font-size: 14px;
    }
    ul.inbox-nav {
        padding: 0;
        list-style: none;
    }
    .name-list-inbox {
        color: $ScarpaFlow;
        float:left;
        width: 175px;
    vertical-align: middle;
    font-weight: 500;
    text-transform: capitalize;
    font-size: 14px;
    }
    .regarding{
        color:$gray-600;
        font-weight: 400;
        font-size: 14px;
    }
    ul.inbox-nav li {
        padding: 10px 15px;
    }
    ul.inbox-nav li.active {
       border-right: 4px solid $primary-clr;
       
    }
    ul.inbox-nav li.select{
        background: $Zumthor;
    }
    ul.inbox-nav li:hover {
        border-right: 4px solid $primary-clr;
        background: $Zumthor;
     }
     .msg-content {
        color: $FrenchGray;
        margin-top: 10px;
    }
    .reply-section .fas {
        color: $CuriousBlue;
    }
}
.mail-assign {
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 20px 0;
    border-top: 1px solid $gray-600;
}
.left {
    float: left;
    font-weight: 600;
    color: #888;
    transition-duration: 0.3s;
}
.right {
    float: right;
    color: #999;
}
.message {
    margin-top: 0px;
}
.message.mail-assign--update{
    margin-top: 0px !important;
}
.arrow {
    display: inline-block;
    position: relative;
    height: 2px;
    width: 20px;
    background: rgba(0, 0, 0, 0.15);
    vertical-align: top;
    margin-top: 12px;
    margin: 12px 20px 0 15px;
    border: 0px solid rgba(0, 0, 0, 0.15);
    transition-duration: 0.3s;
}
.arrow:after {
    position: absolute;
    top: -4px;
    left: 100%;
    height: 0;
    width: 0;
    border: inherit;
    border-width: 7px;
    border-style: solid;
    content: '';
    border-right: 0;
    border-top-color: transparent;
    border-bottom-color: transparent;
    border-top-width: 5px;
    border-bottom-width: 5px;
}
.send-reply-btn {
    vertical-align: middle;
    align-self: center;
    margin-top: 15px;
}
.show-more {
    font-size: 9px;
    color: $primary-clr;
    border: 1px solid $primary-clr;
    padding: 3px;
    border-radius: 5px;
    font-weight: 700;
    float: right;
    margin-top: 10px;
}
.attachment--update.attachment-sec{
    margin-top: 10px !important;
}
.mesg mat-chip.mat-chip.mat-focus-indicator.mat-primary.mat-standard-chip.mat-chip-selected {
    background: $AquaSqueeze;
    border: 1px solid $Nevada;
    color:$Nevada;
    border-radius: 0;
    font-size: 10px;
    padding: 5px;
    height: 24px;
    min-height: 24px;
}
.user-name-list {
    overflow: auto;
    max-height: 570px;
}
.msg-details .msg-content {
    float: left;
    width: 100%;
    margin: 0;
}
.card-height-default{
    height: 100vh;
}


/*new design*/
.mail-modal{
    .mat-chip-input {
        outline: none !important;
        border: none !important;
    }
}
.mail-page{
    .custom-title-wrap {
        margin-top: 10px;
        padding-bottom: 1rem;
    }
    .custom-title-wrap .custom-title {
        color: #2f3c4b;
        font-size: 14px;
        font-weight: 600;
    }
     .mail-list-scroll {
        overflow-y: scroll;
        width: 100%;
        height: 60vh;
        overflow-x: hidden;
    }
    .mail-list-scroll-trans{
        overflow-y: scroll;
        width: 100%;
        max-height: 72vh;
        overflow-x: hidden;
    }
     .mail-navs .active .nav-link {
        color: #626be3;
        background: #f4f6f9;
    }
    .mail-navs .nav-link {
        position: relative;
        padding: .5rem 1.5rem;
        color: #6c757d;
    }
    .mail-navs .badge {
        position: absolute;
        top: 10px;
        right: 20px;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        line-height: normal;
    }
    .mail-list-scroll {
        overflow-y: scroll;
        width: 100%;
        height: 60vh;
    }
    .custom-title a{
        font-size: 9px;
        margin-right: -17px;
    }
    .mail-widget li {
        padding: 0.9rem;
        border-bottom: 1px solid #efefef;
    }
    .st-alphabet {
        position: relative;
        width: 40px;
        height: 40px;
        text-align: center;
        color: #fff;
        font-weight: 800;
        line-height: 40px;
    }
    .mail-widget .media img {
        width: 30px;
        height: 30px;
    }
    .main-time {
        position: absolute;
    width: 100%;
    text-align: right;
    font-size: 10px !important;
    top: -12px;
    right: -3px;
    }
    .mail-name{
        width:237px;
    }
    .list-widget span {
        font-size: 12px;
    }
    .msg-hint{
        font-size: 14px;
    }
    .msg-hint h5 {
        font-size: 14px;
        white-space: pre;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 238px;
    }
    .msg-hint .msg-des {
        width: 100%;
        white-space: pre-wrap;
    }
    .mail-widget li:hover {
        background: #f4f6f9;
    }
    .mail-widget li.active {
        background: #f4f6f9;
    }
    .cursor {
        cursor: pointer;
    }
    .custom-mail-select {
        border: none;
        font-size: 12px;
        margin-top: -7px;
        padding-right: 4px;
    }
    .st-alphabet-large {
        position: relative;
        width: 60px;
        height: 60px;
        text-align: center;
        color: #fff;
        font-weight: 800;
        line-height: 40px;
    }
    .card {
        border-radius: 0;
        height: 100%;
    }
    span.divide {
        display: inline-block;
        height: 18px;
        // border-right: 2px solid #b6afaf;
        padding-left: 8px;
        margin-right: 8px;
        vertical-align: middle;
    }
    .reply{
        font-size: 14px;
        color: $primary-clr;
    }
    .subject-msg {
        font-size: 12px;
    }
    .reply-details {
        margin-top: 15px;
        margin-bottom: 40px;
        border-left: 1px solid #000;
        padding-left: 20px;
        font-size: 14px;
    }
    .mail-widget li.new {
        border-left: 4px solid $primary-clr;
    }
        .mail-list-scroll {
            scrollbar-width: thin;
            scrollbar-color: #3da5f4 #ffffff;
          }
          
          /* Works on Chrome, Edge, and Safari */
          .mail-list-scroll::-webkit-scrollbar {
            width: 12px;
          }
          
          .mail-list-scroll::-webkit-scrollbar-track {
            background: #ffffff;
          }
          
          .mail-list-scroll::-webkit-scrollbar-thumb {
            background-color: #3da5f4;
            border-radius: 20px;
            border: 3px solid #ffffff;
          }

          .mail-list-scroll-trans {
            scrollbar-width: thin;
            scrollbar-color: #3da5f4 #ffffff;
          }
          
          /* Works on Chrome, Edge, and Safari */
          .mail-list-scroll-trans::-webkit-scrollbar {
            width: 12px;
          }
          
          .mail-list-scroll-trans::-webkit-scrollbar-track {
            background: #f1f1f1;
          }
          
          .mail-list-scroll-trans::-webkit-scrollbar-thumb {
            background-color: #3da5f4;
            border-radius: 20px;
            border: 3px solid #ffffff;
          }
    
}
.sorting-text {
    text-overflow: ellipsis;
    display: inline-block;
    overflow: hidden;
    white-space: pre;
    width: 146px;
    vertical-align: middle;
    margin-top: 5px;
    color: $gray-600;
}

.side-bar{
    -webkit-transition: -webkit-transform .3s ease-in-out,width .3s ease-in-out;
    -moz-transition: -moz-transform .3s ease-in-out,width .3s ease-in-out;
    -o-transition: -o-transform .3s ease-in-out,width .3s ease-in-out;
    transition: transform .3s ease-in-out,width .3s ease-in-out;
}
.custom-title {
    display: block;
}
.toggle-section{
    max-width: 45px !important;
    -webkit-transform: translate(0, 0) ;
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0) ;
    transform: translate(0, 0) ;
}
.toggle-section .large-menu {
    display: none;
}
.toggle-section .small-menu {
    display: block !important;
    
}
.small-menu ul{
    list-style-type: none;

}
.small-menu ul li{
    padding: 7px;
    text-align: center;
}

.small-menu li a {
    margin-bottom: 7px;
    position: relative;
}
.small-menu ul li.active {
    background: #f4f6f9;
}
.small-menu ul li.active a {
    color: #626be3;
}
.small-menu ul .inbox {
color: #6c757d;
}
.small-menu li a .badge.badge-danger {
    position: absolute;
    top: -4px;
    right: -7px;
    width: 15px;
    height: 15px;
    font-size: 8px;
}
.expand-body {
    max-width: 71% !important;
    flex: 0 0 71% !important;
}
.initial {
    border-radius: 50%;
    color: #fff;
    font-size: 14px !important;
    font-weight: 600;
    line-height: 28px;
    height: 28px;
    background-color:$primary-clr;
    padding: 4px 6px;
    text-transform: uppercase;
    top: -4px;
    position: relative;
}
.initial-large {
    border-radius: 50%;
    color: #fff;
    font-size: 16px !important;
    font-weight: 600;
    line-height: 46px;
    background-color: #173a64;
    text-transform: uppercase;
    display: inline-block;
    margin-top: 10px;
    width: 46px;
    height: 45px;
}
.minimize-mail-compose {
    color: $white !important;
    vertical-align: middle;
    display: inline-block;
    margin-top: 5px;
    margin-right: 5px;
}
.sorting-mail {
    width: 60px;
    margin: 0;
    margin-top: -10px;
}
.sorting-mail .mat-form-field-underline {
  height: 0 !important;
}
.sorting-mail .mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label {
    display: none;
}
.sorting-mail .mat-form-field-wrapper {
    margin-top: -41px;
}
.minimized {
    position: relative;
    overflow: visible;
}
.mail-modal.minimized .modal-header {
    width: 300px;
    margin: unset;
    right: 0;
    bottom: 0;
    position: absolute;
}
.mail-modal.minimized .modal-body {
  display: none;
}
.minimize-mail-compose{
    cursor: pointer;
}
.gmail-txt:focus {
    border: none;
    box-shadow: inset 0 -1px 0 0 rgb(100 121 143 / 12%);
}
.toggle-btn a {
    float: left;
    width: 24px;
    border-radius: 4px;
    color: $primary-clr;
    margin-right: 12px !important;
    text-align: center;
    font-size: 14px !important;
    cursor: pointer;
}
.heading-body-mail {
    color: $primary-clr;
    font-weight: 600;
    font-size: 17px;
    margin-top: 10px;
    float: left;
    width: 100%;
    margin-bottom: 10px;
    padding-left: 10px;
}
.three-dot {
    color: #173a64;
    font-size: 15px;
    padding: 0px 11px;
    position: relative;
    top: -5px;
}
.three-dot-cover {
    background: #efefef;
    width: 40px;
    vertical-align: middle;
    height: 18px;
    border-radius: 2px;
    text-align: center;
    display: inline-block;
    text-decoration: none !important;
    color: $primary-clr;
}
.sort-width {
    min-width: 75px !important;
    width: 75px;
}