.font-15 {
    font-size: 15px;
}
.schedule-info {
    padding-left:0;
    list-style-type: none;
    font-size: 14px;
}
.schedule-info .schedule-list {
    border: 1px solid #efefef;
    background: #fff;
    margin-bottom: 5px;
    border-radius: 5px;
    padding: 6px;
}
.schedule-info .conflict {
    border: 1px solid #FF0000;
}
.help-texted {
    font-size: 13px;
    font-weight: 500;
    background: #a9c5ef;
    padding: 5px;
    border-radius: 0px;
    color: #000;
    margin-top: 20px;
    margin-bottom: 20px;
}
// .help-texted {
//     font-size: 13px;
//     font-weight: 500;
//     background: #345a94;
//     padding: 5px;
//     border-radius: 3px;
//     color: #fff;
//     margin-top: 20px;
//     margin-bottom: 20px;
// }
.info-user {
    font-size: 14px;
    margin-bottom: 15px;
    border-top: 1px  solid #6a6a6a;
    padding: 10px;
}
.info-user p {
    margin-bottom: 3px;
    font-size: 13px;
}

.schedule-info .conflict i {
    color: #e23939;
}
.schedule-info .conflict {
    position: relative;
}
.schedule-info i {
    color: #777373;
}
.conflict-msg {
    position: relative;
    font-size: 11px;
    top: -10px;
    color: #e44242;
    font-weight: 600;
    background: #ffff;
    left: 0;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-left-radius: 5px;
    padding: 1px;
    border-bottom-right-radius: 5px;
    width: 100%;
    border: 1px solid #ff0000;
    text-align: center;
}
.text-conflict {
    font-weight: 700;
}
.remain-text{
        padding-left: 4px;
        font-weight: 600;
}