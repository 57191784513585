@media only screen and (max-width: 1350px) {
    .grid-main-section .col {
        width: 250px;
        }
        .menu-value a {
            color: #fff;
            font-size: 10px;
            margin-right: 10px;
            text-transform: uppercase;
            font-weight: 400;
            padding-bottom: 10px;
            vertical-align: middle;
            outline: none !important;
            padding-top: 10px;
            text-overflow: ellipsis;
            overflow: hidden;
        }
        .first-head {
            color: #173a64;
            font-size: 13px;
        }
        .breadcrumb-arrow .breadcrumb-custom a {
            display: inline-block;
            padding: 0 8px 0 22px;
            margin: 0 10px 10px 0;
            height: 40px;
            line-height: 40px;
            position: relative;
            font-size: 10px;
            text-decoration: none !important;
        }
}
@media only screen and (max-width: 1165px) {
    .loc-changer mat-select{
        width: 60px;
    }
    .loc-changer .mat-form-field-infix {
        width: 60px;
    }
    .menu-value a {
        color: #fff;
        font-size: 12px;
        margin-right: 25px;
        text-transform: uppercase;
        font-weight: 400;
        padding-bottom: 12px;
        vertical-align: middle;
        border-bottom: 4px solid #173a64;
        outline: none !important;
        text-overflow: ellipsis;
            overflow: hidden;
            height: 31px;
            width: 65px;
    }
}
@media only screen and (max-width: 1120px) {
    .grid-main-section .col {
        width: 250px;
        }
    .menu-value a {
        color: #fff;
        font-size: 12px;
        margin-right: 25px;
        text-transform: uppercase;
        font-weight: 400;
        padding-bottom: 12px;
        vertical-align: middle;
        border-bottom: 4px solid #173a64;
        outline: none !important;
        text-overflow: ellipsis;
            overflow: hidden;
            height: 31px;
            width: 62px;
    }
    .navbar-expand-lg .ml-auto {
      
    }
}

@media only screen and (max-width: 1024px) {
   
.menu-value a {
    color: #fff;
    font-size: 12px;
    margin-right: 10px;
   
}
.primary-btn-outlined {
    font-size: 10px;
    color: #173a64 !important;
    line-height: 1.2 !important;
    text-transform: uppercase;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 6px !important;
    height: 40px;
    border: 2px solid #173a64 !important;
    border-radius: 0 !important;
}
.nav-pills-custom .nav-link .label-settings {
    display: none;
}
.nav-pills-custom .nav-link {
    text-align: center;
}
.day-changer {
    width: 100%;
}
.day-changer-container {
    display: block !important;
}
.crba-blocks-container {
    display: block !important;
}
.crba-blocks {
    flex: 0 0 100% !important;
    max-width: 100% !important;
    margin-bottom: 15px;
}
.patient-dashboard .full-height{
    height: 100% !important;
}
.progress-notes-page textarea
{
    width: 95px;
}
}
@media only screen and (max-width: 767px) {
    .aggr-table table {
        min-width: 1500px;
        table-layout: fixed;
      }
    .treat-list-pad {
        margin: 0;
    }
    .menu-value a {
        color: #fff;
        font-size: 12px;
        margin-right: 10px;
    }
    .primary-btn-outlined {
        font-size: 10px;
        color: #173a64 !important;
        line-height: 1.2 !important;
        text-transform: uppercase;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 6px !important;
        height: 40px;
        border: 2px solid #173a64 !important;
        border-radius: 0 !important;
    }
    .nav-pills-custom .nav-link .label-settings {
        display: none;
    }
    .nav-pills-custom .nav-link {
        text-align: center;
    }
    .day-changer {
        width: 100%;
    }
    .day-changer-container {
        display: block !important;
    }
    .group-notes
    {
        padding: 0px !important;
        .list-group-item
        {
            padding: 3px !important;
        }
    }
    .progress-notes-page .heading {
        font-size: 10px !important;
    }
    .progress-notes-page textarea
    {
        width: 95px;
    }
    .progress-notes-page .group-notes .listitem1 {
        width: 30% !important;
    }
    .daily-scheduler-page .scheduler-list-item {
        width: 100% !important;
    }
}