/* You can add global styles to this file, and also import other style files */
@import '~primeng/resources/primeng.min.css';
html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.font-lead{
  font-size: 12px;
}
.dashboard-page {
  .br6 {
    border-radius: 30px !important;
  }
  .fs16 {
    font-size: 16px;
  }
  .fs12 {
    font-size: 12px;
  }
  .task-col {
    margin-top: 40px;
    font-size: 12px;
  }
  .btn-width {
    text-transform: capitalize;
    max-width: 200px;
  }
  .mat-tab-body-content {
    overflow-x: hidden;
  }
  .dashboard-truncate {
    max-width: 200px;
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    &.appointment-truncate {
      max-width: 250px;
    }
  }
  .clock {
    margin-top: 2px;
  }
  .dashboard-list {
    width: 30%;
    text-align: center;
    color: #000;
    font-weight: 600;
    padding: 10px;
    &:active {
      background: #2380d6;
    }
  }
  .dot-navy-blue {
    background: #2e5aac;
    height: 30px;
    width: 30px;
    color: white;
    border-radius: 50%;
    display: inline-block;
    padding: 5px 3px;
    font-size: 12px;
  }
  .task-detail-bg {
    background-color: white;
    padding: 5px 0px 0px 5px;
  }
  .ml1 {
    margin-left: 2px;
  }
  .btn-transparent {
    border: 0px;
    font-weight: 400;
    font-size: 12px;
    background-color: transparent;
    color: #2e5aac;
  }
  .border-left-tasks {
    height: 80px;
    padding: 8px;

    border-radius: 0px !important;
    &.border-left-red {
      border-left: 3px solid #f86061;
    }
    &.border-left-orange {
      border-left: 3px solid #fab633;
    }
    &.border-left-blue {
      border-left: 3px solid #8de2f9;
    }
  }

  .smalldot {
    height: 10px;
    width: 10px;
    color: white;
    border-radius: 50%;
    display: inline-block;
    &.dot-red {
      background-color: #f86061;
    }
    &.dot-orange {
      background-color: #fab633;
    }
    &.dot-blue {
      background-color: #8de2f9;
    }
  }
  .mat-tab-label .mat-tab-label-content {
    margin-left: 30px;
  }

  .dot-pink {
    background: #ca3be7;
    height: 30px;
    width: 30px;
    color: white;
    border-radius: 50%;
    display: inline-block;
    padding: 5px 3px;
    font-size: 12px;
  }

  .dashboard-list-alert {
    background: #2380d6;
    width: 20%;
    text-align: center;
    color: #000;
    font-weight: 600;
    padding: 10px;
  }
  .dashboard-task-list {
    width: 20%;
    text-align: center;
    color: #000;
    font-weight: 600;
  }
  .mat-tab-label {
    background-color: transparent;
    color: #000;
    font-weight: 600;
    padding: 0px;
    //  border-right: 3px solid white;
  }
  .mat-tab-label-active {
    background: transparent;
    color: black;
    margin-right: 0px;
  }
  .w50 {
    width: 50%;
  }
  a {
    color: #000;
  }
  a:hover {
    text-decoration: none;
  }
  .mat-tab-labels {
    border-bottom: 1px solid #ccc;
  }
  .mat-tab-group.mat-primary .mat-ink-bar,
  .mat-tab-nav-bar.mat-primary .mat-ink-bar {
    background: #2e5aac !important;
    margin-left: 65px !important;
    width: 20px !important;
  }
  .alert-class {
    background-color: red !important;
  }
  .triangle-up {
    width: 0;
    height: 0px;
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    border-bottom: 22px solid #fd617d;
    position: relative;
    margin-bottom: 8px;
    margin-left: 1px;
  }
  .triangle-span {
    position: absolute;
    transform: translate(-50%, -50%);
    top: 14px;
  }
  .circle {
    height: 25px;
    width: 28px;
    background-color: #a4a0a0;
    border-radius: 50%;
    position: relative;
    margin-left: 0px;
    margin-bottom: 6px;
  }
  .circle-span {
    position: absolute;
    transform: translate(-50%, -50%);
    top: 14px;
  }
  // .list-item-group1,
  // .list-item-group2 {
  //   width: 30%;
  // }
  // .list-item-group3 {
  //   width: 40%;
  //   text-align: end;
  // }
  .benefit-btn {
    padding: 10px 90px 10px 55px;
    background-color: #a4a0a0;
    border: none;
    border-radius: 8px;
    &.view-btn {
      padding: 10px 50px 10px 55px;
    }
  }
  .list-group-item {
    border: 0px;
    background-color: white;
    padding: 0.3rem;
    p {
      margin-bottom: 0px;
    }
  }
  .bg-gray {
    background-color: white;
    font-size: 12px;
    border-bottom: 1px solid #ccc;
    padding: 8px;
  }
  .list-heading {
    font-weight: 600;
  }
}
//@import "./scss-styles/fonts";
.addSession-style {
  cursor: pointer;
  float: right;
}
.cmha-multiselect-dropdown {
  width: 50%;
  border: 1px solid black;
}
.therapy-severity {
  .mat-form-field {
    display: inline-flex;
  }
}
.arrow-span {
  color: white;
  height: 22px;
  width: 22px;
  background-color: #173a64;
  border-radius: 50%;
  display: inline-block;
  padding: 0px 6px;
}
.daily-scheduler-page {
  .card-header {
    background-color: #d8d8d8;
  }
  .client-details-scheduler {
    background-color: white;
    // height: calc(100vh - 100px);
    // overflow-y: auto;
  }
  .card-body,
  .therapy-details-div {
    padding: 10px;
    border: 1px solid #d8d8d8;
    border-radius: 5px;
  }
  .client-details-lists {
    width: 100%;
    height: calc(100vh - 250px);
    overflow-y: auto;
  }
  .border-right {
    border-right: 2px solid white !important;
  }
  .scheduler-head-padding {
    background-color: #d8d8d8;
  }
  .assign-clients {
    background: aliceblue;
    border-radius: 5px;
    height: calc(100vh - 600px);
    border: 1px dashed #3f51b5;
    margin-bottom: 20px;
  }
  .example-list {
    display: inline-flex;
    width: 100% !important;
    height: 80% !important;
  }
  .daily-scheduler-page,
  .daily-scheduler-page-header {
    padding: 22px 50px;
    background-color: #d8d8d8;
    font-size: 14px;
    border-bottom: 4px solid white;
  }
  .daily-scheduler-page-body {
    background-color: white;
  }
  .head-daily-section {
    font-size: 18px;
    margin-top: 20px;

    font-weight: 700;
    &.form-head-section {
      font-size: 16px;
      font-weight: 500;
      text-transform: capitalize;
    }
  }
  .scheduler-list-item {
    width: 20%;
  }
  .list-group-item {
    position: relative;
    display: block;
    padding: 0rem 0rem 0rem 1rem;
    background-color: transparent;
    border: none;
    font-size: 14px;

    .list-item-date {
      font-weight: 700;
      font-size: 12px;
      padding-left: 5px;
    }
  }
  .list-group-heading {
    padding-left: 17px;
  }
  .head-span {
    text-transform: capitalize;
  }
  .content-section {
    background-color: white;
    border-radius: 4px;
    margin: 10px;
    .scheduler-heading {
      font-size: 14px;
      font-weight: 600;
      text-transform: capitalize;
      padding: 10px 0px 0px 10px;
      &.scheduler-head-padding {
        padding: 15px 0px 15px 20px;
      }
    }
    .profile-icon {
      width: 40px;
      height: 40px;
      border-radius: 100%;
    }
    .border-right {
      border-right: 1px solid #ced4da;
    }
    .border-bottom {
      border-bottom: 1px solid #ced4da;
    }
    .mat-form-field {
      padding: 0px 4px;
    }
    .delete-img {
      float: right;
      width: 12px;
      margin: 3px 10px;
    }
    .dots-six-vertical {
      height: 20px;
      width: 20px;
    }
    .dots-content {
      padding: 0 10px;
    }
    .closeicon {
      margin: 4px 12px;
    }
  }
}
.progress-notes-page {
  &.attendance-page {
    .border-bottom-gray {
      border-bottom: 1px solid #ccc;
    }
    .table-height {
      height: 50vh;
      overflow-y: auto;
    }
    .w200 {
      width: 200px;
    }
    .text-color-blue {
      color: #3f51b5;
    }
    .list-group-item {
      font-size: 14px;
    }
    .mat-ink-bar {
      background: #3f51b5 !important;
      width: 25px !important;
      margin-left: 40px;
    }
    .mat-tab-label-active {
      color: black;
      background-color: #fff;
    }
    .mat-tab-body-content {
      background-color: #fff;
      padding: 4px 0px;
    }

    .mat-tab-list {
      border-bottom: 1px solid #ccc;
      background-color: #fff;
    }
  }
  .mat-tab-label-active {
    color: black;
    background-color: #a1cbf2;
  }
  .mat-ink-bar {
    background: white !important;
  }
  .fw700 {
    font-weight: 700;
  }
  td {
    font-size: 12px;
  }
  .mat-tab-label-content {
    text-transform: capitalize;
  }
  .mat-tab-body-content {
    height: 100%;
    overflow: auto;
    background-color: #f7f7f7;
    padding: 4px 12px;
  }
  .notes-text{
    color: red;
  }
  .notes-table {
    width: 100%;
    background-color: white;
    .tr-row {
      border-bottom: 1px solid #f7f7f7;
      th {
        padding: 10px 0px;
        font-size: 14px;
      }

      .th-cols {
        width: 20%;
        font-size: 14px;
      }
      .th-cols-width {
        width: 15%;
      }
    }
    &.attendance-table {
      .tr-row {
        background-color: #ccc;
      }
      .th-cols {
        width: auto;
        font-size: 14px;
      }
    }
  }
  .bg-gray {
    background-color: #f7f7f7;
  }
  .border-bottom-gray {
    border-bottom: 1px solid #f7f7f7;
  }
  .border-right-gray {
    border-right: 1px solid #f7f7f7;
  }
  .list-group-item {
    font-size: 12px;
    border: 0;
  }
  .group-notes {
    .listitem1 {
      width: 20%;
    }
    .listitem2 {
      width: 15%;
    }
    .listitem3 {
      width: 40%;
    }
    .listitem4 {
      width: 30%;
    }
    .listitem5 {
      width: 60%;
    }
  }
  textarea {
    resize: none;
    border: 0;
  }
  .heading {
    font-size: 14px;
    font-weight: 700;
  }
  .custom-checkbox .custom-control-label::before {
    border-radius: 100%;
  }
  .custom-control-input:checked ~ .custom-control-label::before {
    color: #fff;
    border-color: #0ba821;
    background-color: #0ba821;
  }
}
.color-red {
  color: red;
}

.mat-pseudo-checkbox-checked {
  background: #007bff;
}
.fw700 {
  font-weight: 700;
}
.fw600 {
  font-weight: 600;
}
.fw-500 {
  font-weight: 500;
}
.fs14 {
  font-size: 14px;
}
.fs12 {
  font-size: 12px;
}
.w85 {
  width: 85%;
}
.group-therapy {
  .modal-content {
    border: 4px solid rgba(0, 0, 0, 0.2);
  }
  .modal-closing a {
    background: transparent;
    border: 1px solid transparent;
    right: 5px;
    top: 5px;
    font-size: 16px;
  }
  .mat-radio-label {
    margin-bottom: 0px;
  }
}
.client-type-popup {
  .mat-radio-outer-circle {
    height: 15px;
    left: 0;
    top: 0;
    width: 15px;
  }
  .mat-radio-inner-circle {
    height: 17px;
    left: -1px;
    top: -1px;
    width: 17px;
  }
  .mat-radio-button .mat-radio-ripple {
    display: none;
  }
}
.btn-link {
  font-weight: 400;
  color: #000;
  text-decoration: none;
}
.btn-link:hover {
  text-decoration: none;
  color: #000;
}
.btn:focus {
  outline: 0;
  box-shadow: none;
}
.leaddetailedit {
  .mat-form-field-appearance-legacy .mat-form-field-label {
    top: -0.31875em !important;
  }
}
.table-timer {
  width: 100%;
}

.border-1 {
  border: 1px solid #b1b1b1 !important;
  border-radius: 10px;
}
.back_btn_link {
  text-decoration: underline;
  color: #000;
}
.min-vw-45 {
  min-width: 45vh;
}
.min-vw-20 {
  min-width: 10vh;
}
.progressnote-activity-commnet {
  height: 10em;
  width: 98%;
}
.dot-dark-blue {
  background: #173a64;
  color: #f6ca21;
  height: 30px;
  width: 30px;
  text-align: center;
  border-radius: 50%;
  display: inline-block;
  padding: 5px 8px;
  font-size: 20px;
}
.fs15px {
  font-size: 15px;
}
.color-gray {
  color: #878686;
}
.w200px {
  width: 200px !important;
}
.list-item-cmha {
  .menu-icon {
    .active-img {
      display: inline-block;
    }

    .inactive-img {
      display: none;
    }

    img {
      width: 18px;
      height: 19px;
    }
  }
  &:hover,
  &.active {
    .active-img {
      display: none;
    }

    .inactive-img {
      display: inline-block;
    }
  }
}
.history-img {
  width: 35px;
  height: 42px;
}
.color-plus-circle {
  color: #0056b3;
}
.color-edit-icon {
  color: #f8bf26;
}
.color-clock-icon {
  color: #9370DB;
}
.color-history-icon
{
color: #237a1f;
}
.color-upload-icon {
color : #Ffce06;
}
.cmha-black-icon{
  height: 15px;
  width: 13px;
}
.grid-main-section .dropdown-item.active, .dropdown-item:active {
  color: black;
}
.lead-kanban .dropdown-menu{
  min-width: 15rem;
}
.fs12 {
  font-size: 12px !important;
 }
 .wsnowrap{
  white-space: nowrap;
 }

//  .cdk-overlay-pane
//  {
//   padding-top: 41px; 
//  }

 .mat-autocomplete-panel{
  margin-top : -30px !important;
}
.count-text{
  color: #ed6666;
  margin-bottom: 10px
}
.myPanelClass .cdk-overlay-container .cdk-overlay-pane  {
  padding-top: 50%  !important;
}
.formwidth .mat-form-field-appearance-fill .mat-form-field-flex {
  padding: 0px !important
}
.formwidth .mat-form-field-wrapper {
  padding-bottom: 1em !important;
}
.formwidth .mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: transparent;
}
.modal.bulk-add.emoji-popup1.schedule-popup .modal-dialog {
  max-width: 550px;
}
.mat-checkbox-checked.mat-accent .mat-checkbox-ripple .mat-ripple-element {
  opacity: 0.03 !important;
  background-color: #173a64!important;
}

.mat-checkbox-checked.mat-accent .mat-checkbox-background,.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background {
  background-color: #173a64;
}