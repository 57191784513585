.billing-patient {
  // height: 100vh;
}
.pat-id {
  font-weight: 600;
  color: #173a64;
}
.profile-header .btn-default {
  border: 1px solid #ccc;
  background: #cfcfcf;
  color: #000;
}
.profile-header .btn-default:hover {

  color: #555;
}
.social .profile-header {
  color: #555;
  padding: 10px;
  background: #fff;
}
.large-search-custom {
  background: #e1dfdf;
  width: 100%;
  display: block;
  padding: 14px 10px;
}
.search-result {
  text-align: center;
  width: 100%;
  margin-top: 40px;
  font-size: 20px;
  font-weight: 400;
}
.notify-title .mat-sort-header-content{
  margin-left: 1rem!important;

}
.notify-pointer{
  cursor: pointer;
}
.notify-pointer i {
  font-size: 18px;
}
.dot-circle {
  height: 10px;
  width: 10px;
  background-color: #287d3c;
  border-radius: 50%;
  display: inline-block;
  margin-top: 1%;
  margin-right: 2%;
}
.table-billing table{
  table-layout: fixed;
}
.theraphy-mul .form-control {
  font-size: 13px;
  padding-left: 4px;
}

.bill-label label {
  font-weight: 500;
  color: #000;
  font-size: 14px;
}
// .theraphy-mul .inputElement {
//   margin-right: 80px;
// }
.theraphy-mul .loop-section {
  background: #efefef;
  padding: 15px;
  border-radius: 5px;
}
.theraphy-mul .loop-section .add, .theraphy-mul .loop-section .remove {
  position: absolute;
  top: 40px;
  right: 23px;
}
.theraphy-mul .row{
  margin-left: 0px !important;
  margin-right: 0px !important;
}
.theraphy-mul .loop-section .add {
  right: 55px;
}
.restraint-info h2::after {
  content: "";
  position: relative;
  display: block;
  width: 30px;
  height: 3px;
  background: #173a64;
  margin-top: 5px;
  left: 1px;
}
.restraint-info h2{
  color: #173a64;
  font-size: 15px;
}
.incident-list .badge {
  padding: 6px 8px;
  font-weight: 600;
  font-size: 11px;
  font-weight: 500;
  font-family: 'Poppins', sans-serif;
}

.incident-list .search {
  background-color: #f7f7f7;
}
#myUL {
  list-style-type: none;
  padding: 0;
  margin: 0;
  max-height: 200px;
  overflow: auto;
  width: 100%;
  margin-top: -19px;
  display: inline-block;
}
#myUL li a {
  border: 1px solid #ddd;
  margin-top: -1px;
  background-color: #f6f6f6;
  padding: 8px;
  text-decoration: none;
  font-size: 15px;
  color: black;
  display: inline-block;
    white-space: nowrap;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    vertical-align: center;
}
#myUL li a:hover:not(.header) {
  background-color: #eee;
}

.ng-select.invalid .ng-select-container .ng-value-container .ng-placeholder.ng-placeholder {
  color: #f44336;
}
.incident-report .ng-select {
  margin-top: -6px;
}
.table-custom .mat-header-row {
  height: 38px;
  min-height: 38px;
}
.table-custom .mat-header-cell {
  //padding: 1px;
}
.contact-info-popup .modal-dialog {
  margin-top: 135px !important;
}
.text-transform-unset .mat-checkbox-label {
  text-transform: inherit;
}
.incident-list .status-filter {
  width: 100px !important;
  font-size: 12px;
  margin-right: 5px;
}
.transaction-modules .mat-column-TransCode {
  word-wrap: break-word !important;
  white-space: unset !important;
  flex: 0 0 14% !important;
  width: 14% !important;
  overflow-wrap: break-word;
  word-wrap: break-word;

  word-break: break-word;

  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
}
.transaction-modules .mat-column-TtypeDescription{
  word-wrap: break-word !important;
  white-space: unset !important;
  flex: 0 0 14% !important;
  width: 14% !important;
  overflow-wrap: break-word;
  word-wrap: break-word;

  word-break: break-word;

  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
}
.transaction-modules .mat-column-TypeofService{
  word-wrap: break-word !important;
  white-space: unset !important;
  flex: 0 0 13% !important;
  width: 13% !important;
  overflow-wrap: break-word;
  word-wrap: break-word;

  word-break: break-word;

  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
}
.transaction-modules .mat-column-CptCode{
  word-wrap: break-word !important;
  white-space: unset !important;
  flex: 0 0 10% !important;
  width: 10% !important;
  overflow-wrap: break-word;
  word-wrap: break-word;

  word-break: break-word;

  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
}
.transaction-modules .mat-column-RevenuCode{
  word-wrap: break-word !important;
  white-space: unset !important;
  flex: 0 0 11% !important;
  width: 11% !important;
  overflow-wrap: break-word;
  word-wrap: break-word;

  word-break: break-word;

  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
}
.transaction-modules .mat-column-Hcpcscode{
  word-wrap: break-word !important;
  white-space: unset !important;
  flex: 0 0 11% !important;
  width: 11% !important;
  overflow-wrap: break-word;
  word-wrap: break-word;

  word-break: break-word;

  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
}
.transaction-modules .mat-column-Units{
  word-wrap: break-word !important;
  white-space: unset !important;
  flex: 0 0 8% !important;
  width: 8% !important;
  overflow-wrap: break-word;
  word-wrap: break-word;

  word-break: break-word;

  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
}
.insurance-header{
  background-color: #bbbbbb;
  width:45%;
}
.transaction-modules .mat-column-Amount{
  word-wrap: break-word !important;
  white-space: unset !important;
  flex: 0 0 8% !important;
  width: 8% !important;
  overflow-wrap: break-word;
  word-wrap: break-word;

  word-break: break-word;

  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
}
.transaction-modules .mat-column-sno{
  word-wrap: break-word !important;
  white-space: unset !important;
  flex: 0 0 7% !important;
  width: 7% !important;
  overflow-wrap: break-word;
  word-wrap: break-word;

  word-break: break-word;

  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
}
.transaction-modules .mat-column-actions{
  word-wrap: break-word !important;
  white-space: unset !important;
  flex: 0 0 10% !important;
  width: 10% !important;
  overflow-wrap: break-word;
  word-wrap: break-word;

  word-break: break-word;

  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
}
.incident-table .mat-column-sno{
  word-wrap: break-word !important;
  white-space: unset !important;
  flex: 0 0 3% !important;
  width: 3% !important;
  overflow-wrap: break-word;
  word-wrap: break-word;

  word-break: break-word;

  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
}
.incident-table .mat-column-PatientName {
  word-wrap: break-word !important;
  white-space: unset !important;
  flex: 0 0 11% !important;
  width: 11% !important;
  overflow-wrap: break-word;
  word-wrap: break-word;

  word-break: break-word;

  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
}
.incident-table .mat-column-Reportedby {
  word-wrap: break-word !important;
  white-space: unset !important;
  flex: 0 0 10% !important;
  width: 10% !important;
  overflow-wrap: break-word;
  word-wrap: break-word;

  word-break: break-word;

  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
}
.incident-table .mat-column-Therapy-Program {
  word-wrap: break-word !important;
  white-space: unset !important;
  flex: 0 0 21% !important;
  width: 21% !important;
  overflow-wrap: break-word;
  word-wrap: break-word;

  word-break: break-word;

  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
}
.incident-table .mat-column-Clinician {
  word-wrap: break-word !important;
  white-space: unset !important;
  flex: 0 0 12% !important;
  width: 12% !important;
  overflow-wrap: break-word;
  word-wrap: break-word;

  word-break: break-word;

  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
}
.incident-table .mat-column-IncidentType {
  word-wrap: break-word !important;
  white-space: unset !important;
  flex: 0 0 12% !important;
  width: 12% !important;
  overflow-wrap: break-word;
  word-wrap: break-word;

  word-break: break-word;

  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
}
.incident-table .mat-column-IncidentDate {
  word-wrap: break-word !important;
  white-space: unset !important;
  flex: 0 0 8% !important;
  width: 8% !important;
  overflow-wrap: break-word;
  word-wrap: break-word;

  word-break: break-word;

  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
}
.incident-table .mat-column-Status {
  word-wrap: break-word !important;
  white-space: unset !important;
  flex: 0 0 8% !important;
  width: 8% !important;
  overflow-wrap: break-word;
  word-wrap: break-word;

  word-break: break-word;

  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
}
.transaction-modules .mat-column-Tdescription {
  word-wrap: break-word !important;
  white-space: unset !important;
  flex: 0 0 15% !important;
  width: 15% !important;
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;
  -webkit-hyphens: auto;
  hyphens: auto;
}

.top-credit-head {
  background: #efefef;
  padding: 15px;
  font-weight: 600;
  color: #173a64;
}
.incident-list label {
  font-size: 12px;
  font-weight: 600;
}
.transcode {
  background: #fff;
  font-size: 13px;
  max-height: 250px;
  overflow-y: auto;
}
.list-label-pay {
  font-size: 12px;
  font-weight: 600;
  color: #000;
}
.bill-pat-name {
  font-size: 11px;
}
.bill-list h6 {
  font-size: 13px;
}
.bill-list h6 a{
  font-size: 13px;
  text-decoration-line: underline;
}
.bill-list .card{
  border:1px solid rgb(233 233 233 / 13%);
}
.bill-list .card:hover {
  box-shadow: 0 0 7px #8d8383;
}
.loop-rest {
  background: aliceblue;
  padding: 13px 10px;
  border-radius: 5px;
  margin-bottom: 10px;
  border: 1px solid #b7cfe5;
}
// .behave-incident .loop-rest .custom-textarea .mat-form-field-wrapper{
// padding-bottom: 0;
// }
.sub-head-bill {
  font-weight: 500;
  color: #173a64;
  background: #eefe;
  padding: 5px;
}
.bill-address-tab{
  background: #efefef;
  padding: 8px;
}
.bill-address-tab .nav-tabs{
border: none;
}
.bill-address-tab li a.nav-link.active {
  border: none;
  border-bottom: 2px solid #173a64;
  color: #173a64;
  font-weight: 600;
}
.bill-address-tab li a.nav-link {
  color: #555;
}
.check-font-large .mat-checkbox-label{
font-size: 16px;
}
.declaimar {
  background: #fff9c4;
  padding: 7px 16px;
  font-size: 15px;
  border-radius: 5px;
  border: 1px solid #d6d928;
}
.filter-btn-border {
  border: 2px solid #adb5bd;
  padding: 6px;
  font-size: 12px;
  font-weight: 500;
  color: #adb5bd;
}
.eclaim-section ul li {
  font-size: 14px;
  padding: 0px;
}
.eclaim-section ul {
  list-style-type: none;
  padding: 0;
}
// .filter-right .form-control {
//   font-size: 11px;
// }
.pl-tbody {
  tr {
    background-color: #ffffff;
    border:1px solid rgba(233, 233, 233, 0.13);
    border-bottom: 7px solid #f7f7f7;
  }
  &.scroll {
    // max-height: 500px;
    // overflow-x: auto;
  }
}
.pl-tbody td {
  font-size: 13px;
  font-weight: 500;
  padding: 0;
}
.pl-table{
    table-layout: fixed;
}
.table-scroll-height {
  max-height: 420px;
}
.menubar-new{
  width: 280px;
  z-index: 1000;
  float: left;
  min-width: 10rem;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0,0,0,.15);
  border-radius: 0.25rem;
  
}
.menubar-new .form-control {
  font-size: 14px;
}
label.d-label {
  font-size: 14px;
  padding-left: 10px;
}
.filter-block{
  display: block !important;
}
.menubar-new  .mat-form-field-wrapper {
  padding-bottom: 0 !important;
  background: #fff;
  padding-left: 5px;
  padding-right: 5px;
  box-shadow: 0 0 7px #c3c3c3;
  padding-top: 4px;
}
.menubar-new  .mat-form-field-underline {
  display: none;
}
.filter-field {
  max-height: 280px;
  overflow-x: hidden;
  overflow-y: auto;
}
.transmit-pop {
  width: 280px !important;
  right: 100px;
  left: auto;
  height: 280px !important;
}
.bg-transmit {
  background: #0073a1;
  color: #fff;
  padding: 3px;
}
.bg-transmit-w {
  background: #ffcc00;
  color: #fff;
  padding: 3px;
}
.bg-transmit-error {
  background: #a11c23;
  color: #fff;
  padding: 3px 5px;
}
.dropdown-item:active{
  background: none !important;
}
.question-card .dropdown-item:active {
  background: none !important;
  color: #16181b;
}
.dropdown-menu-small .dropdown-item:active{
  background: #e9ecef !important;
  color: #000;
}
span.total-text {
  font-weight: 500;
  font-size: 13px;
  color: #173a64;
}
.icon-control {
  margin-top: 6px;
  float: right;
  font-size: 80%;
}
.filter-right .card-header {
  background: #efefef;
  padding: 8px 13px;
}
.filter-right .card-header a {
  color: #555;
  text-decoration: none;
}
.head-sub-filter {
  margin-bottom: 11px;
  font-size: 15px;
  background: #efefef;
  padding: 5px;
  font-weight: 500;
  color: #173a64;
}
span.check-like-mat {
  color: #173a64;
  text-transform: capitalize;
  font-size: 12px;
  font-weight: 500;
  white-space: break-spaces;
  line-height: 15px;
  vertical-align: top;
}
.medication-dosage label {
  font-weight: 500;
  color: #000;
  font-size: 14px;
}
.medication-dosage .inputElement {
  margin-right: 50px;
}
.medication-dosage .loop-section .add,.medication-dosage .loop-section .remove {
  position: absolute;
  top: 9px;
}
.medication-dosage .loop-section .add {
  right: 36px;
}
.medication-dosage .loop-section .remove{
  right: 4px;
}
.check-fields-large-label .check-custom .mat-checkbox-label {
  text-transform: unset;
}
.sub-head-psy {
  font-weight: 500;
  font-size: 15px;
  margin-bottom: 10px;
  color: #227e7e;
}
.top-details-psych {
  background: aliceblue;
  margin-bottom: 10px;
  padding: 10px;
}
.top-details-psych  p {
  font-size: 13px;
  font-weight: 500;
  color: #3e3e3e;
}
.top-details-psych label {
  font-weight: 500;
  font-size: 14px;
  margin: 0;
  color: #173a64;
}
.invoice-details .well {
  min-height: 20px;
  padding: 19px;
  margin-bottom: 20px;
  background-color: #f5f5f5;
  border: 1px solid #e3e3e3;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.05);
  box-shadow: inset 0 1px 1px rgba(0,0,0,.05);
  font-size: 14px;
}
.invoice-from {
  font-size: 14px;
}
.invoice-details .well .label-danger {
  background-color: #d9534f;
}
.invoice-details .well .label-success {
  background-color: #047c22;
}
.invoice-details .well .label {
  display: inline;
  padding: .2em .6em .3em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: .25em;
  margin-left: 5px;
}
.invoice-to {
  font-size: 14px;
}
.invoice-items {
  font-size: 14px;
}
.invoice-to span {
  font-weight: 500;
  color: $default;
}
.align-text-top {
  vertical-align: text-top;
  font-size: 1.2rem;
}
.fw-bold-700{
  font-weight: 700;
}
.bg-green-small {
  background-color: #d4f8f2;
  color: #06e67a;
  padding: 3px 0;
  display: inline;
  border-radius: 25px;
  font-size: 11px;
}
.box-right {
  padding: 15px;
  background-color: white;
  border-radius: 15px;
  box-shadow: 1px 1px 5px 2px #bebebe;
  margin-top: 12px;
}
.p-blue {
  font-size: 14px;
  color: #1976d2;
  margin: 0;
}
.p-org {
  font-size: 14px;
  color: #fbc02d;
  margin: 0;
    font-weight: 600;
}
.patient-detail li {
  margin-bottom: 5px;
  font-weight: 600;
}
.summary-list .card-header {
  background: #a6d3ec;
  font-weight: 500;
}
.date-bill span {
  font-weight: 600;
  padding-left: 5px;
}

.date-bill {
  font-size: 14px;
  font-weight: 400;
  background: #efefef;
  padding: 4px 12px;
  border-radius: 5px;
  margin-bottom: 10px;
}
.bill-labeld {
  text-transform: uppercase;
  font-size: 11px;
  color: #9a9a9a;
  font-weight: 600;
}
.bill-valued {
  color: #173a64;
  font-weight: 600;
  font-size: 18px;
}
.bill-valued-sub {
  color: #555;
  font-weight: 500;
}
.bull-value li {
  display: inline-block;
  margin-right: 10px;
  font-size: 13px;
  color: #585858;
  list-style-type: none;
}
.bull-value ul {
  padding: 0;
}
.weekly-loop {
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  background: aliceblue;
}
.error-message{
  color: #f44336;
  font-size: 12px;
  font-weight: 400;
}
.print-popup .modal-dialog {
  width: 560px;
  top: 114px;
}
.print-popup {
  background-color: rgb(27 27 27 / 60%) !important;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}
.new-bill-list.bill-list {
  overflow-y: auto;
  max-height: 525px;
  overflow-x: hidden;
}
/*invoice*/
.invoice-details, .invoice-payment-details > li span {
  float: right;
  text-align: right;
  color: #666;
}
.fw-12{
  font-size: 12px;
}
.fw-13{
  font-size: 13px;
}
.fw-14{
  font-size: 14px;
}
.payment-icon {
  position: absolute;
  right: 15px;
  top: -11px;
}
.invoice-section .table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6;
  font-size: 12px;
  background: #9B9B9D;
  color: #fff;
  font-weight: 400;
}
.invoice-section .table tbody td {
  font-size: 14px;
}
app-changepassword .popup-design--replicate {
  position: absolute;
  left: -373px;
  top: 12px;
  width: 345px;
  background-color: #fff;
  border-radius: 10px;
  padding: 10px;
  border: 1px solid #f5365c;
}
.out_packet {
  font-size: 26px;
  margin-right: 30px;
  color: #173a64;
  margin-top: 8px;
  display: block;
}
// .out-of-pack .modal-dialog {
//   top: 16%;
//   width: 800px;
//   max-width: 800px;
// }
.out-of-pack .modal-dialog {
  top: 20%;
  width: 1300px;
  max-width: 1300px;
}
.outofpacket thead {
  border-bottom: 1px solid #ccc;
}

.top-border-out{
  border-bottom: 1px solid #ccc;
  
}
.outofpacket h6.modal-title {
  color: #173a64;
}
.outofpacket th {
  padding: 6px;
  text-align: left;
}
.outofpacket td {
  padding: 6px;
  text-align: left;
}
.outofpacket {
  font-size: 13px;
}
// .out-of-pack .table-responsive{
//   max-height: 382px;
// }
.outofpacket .bill-valued-sub {
  color: #555;
  font-weight: 500;
  font-size: 13px;
}
.outofpacket .bill-labeld {
  text-transform: uppercase;
  font-size: 13px;
  color: rgba(0,0,0,.87);
  font-weight: 600;
}
