.risks-tabs .mat-tab-label {
    background: #fff;
    color: #737373;
    font-weight: 500;
    font-size: 0.8125rem;
    text-transform: capitalize !important;
}
.risks-tabs .mat-tab-label-active {
    background: #fff;
    color: #007bff;
    margin-right: 5px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    opacity: 1 !important;
    font-weight: 500;
    font-size: 0.8125rem;
    text-transform: capitalize !important;
}
.risks-tabs .mat-tab-list {
    background: #fff;
}
.risks-tabs .mat-ink-bar {
    height: 4px !important;
    background: #007bff !important;
}
.Theme-textarea textarea {
    font-style: italic;
    font-size: 15px;
}
.safe-head {
    background: #efefef;
    padding: 5px;
    border-radius: 3px;
    font-size: 14px;
    font-weight: 500;
}
.safe-t-label-bold {
    font-weight: 600;
    font-size: 14px;
}
.safe-t-label {
    font-size: 14px;
    padding-left: 15px;
    font-weight: 500;
}
.total-bg {
    background: #cdcdcd;
    padding: 5px;
}
blockquote {
    position: relative;
    margin: 30px 0;
    padding: 25px 15px;
    background: #f7f7f7;
    border-radius: 3px;
    border: 2px solid white;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
    text-indent: 1.6em;
    text-align: center;
}
blockquote::after {
    content: "❝";
    position: absolute;
    z-index: 1;
    left: 50%;
    top: -2px;
    transform: translate(-50%, -50%);
    width: 1.3em;
    height: 1.3em;
    background: white;
    box-shadow: 0 4px 5px -1px hsla(0 0% 0% / 20%);
    border-radius: 999px;
    display: grid;
    place-content: center;
    padding-top: .5em;
    color: #555;
    font-size: 36px;
    font-style: normal;
    text-indent: 0;
}
.quote-from {
    text-align: right;
    font-size: 13px;
    margin-top: 10px;
    font-weight: 500;
}
.text-dark .mat-checkbox-label{
    color: #000;
}
.modr  {
    background: #ffc000;
    padding: 10px;
}
.lr  {
    background: #ff0;
    padding: 10px;
}
.hgr  {
    background: #ff5050;
    padding: 10px;
}
span.name-highlight {
    font-weight: 500;
    color: #173a64;
}
.lower-risk-box {
    background: #ff0;
    float: left;
    width: 100%;
    height: 40px;
}
.higher-risk-box {
    background: #ff5050;
    float: left;
    width: 100%;
    height: 40px;
}
.moderate-risk-box {
    background: #ffc000;
    float: left;
    width: 100%;
    height: 40px;
}
.safe-border {
    border-bottom: 1px solid #ccc;
    padding: 8px;
}
.larger-checkbox-center input {
    width: 15px;
    height: 15px;
}
.larger-checkbox-center {
    text-align: center;
}
.number-selct {
    padding: 4px 9px;
    background: #173a64;
    border-radius: 50%;
    display: inline-block;
    line-height: 16px;
    margin-bottom: 7px;
    text-decoration: none !important;
    width: 26px;
    color: #fff !important;
    font-size: 13px;
    text-align: center;
    float: left;
}
.value-number-selct span {
    padding: 1px 9px;
    background: #4a4a4a;
    border-radius: 3px;
    border: 1px solid #4a4a4a;
    font-size: 14px;
    font-weight: 600;
    color: #fff;
}
.number-selct:hover {
    box-shadow: 0 0 7px #363636;
}
.hint-ques-label {
    background: #ffff73;
    padding: 10px;
    font-size: 11px;
    font-weight: 600;
    margin-top: 10px;
    margin-bottom: 10px;
}
.low,.high,.moderate {
    font-size: 13px;
    margin-right: 10px;
}
.low .fas{
color:#ff0;
}
.high .fas{
    color:#ff5050;
}
.moderate .fas{
    color: #ffc000;
}
.ques-safe {
    padding-left: 15px;
    font-size: 14px;
    font-weight: bold;
    text-decoration: underline;
    font-style: italic;
    margin-top: 5px;
}
.large-radio-btn input {
    width: 20px;
    height: 20px;
    top:-6px;
}
.info-question-answer {
    font-size: 14px;
    font-weight: 400;
    padding: 10px;
    color: #007bff;
}
.risk-custom-check .mat-checkbox-frame {
    background: #fff;
}
.twolinescheck .mat-checkbox-inner-container {
    margin-top: 0;
}
.safe-t-error {
    font-size: 14px;
    padding-left: 15px;
}
.asses-popup .modal-dialog {
    top: 21%;
}
.file_manager .file .hover {
    position: absolute;
    right: 10px;
    top: 10px;
    display: none;
    transition: all 0.2s ease-in-out;
}
.file_manager .file {
    position: relative;
    border-radius: 3px;
    overflow: hidden;
    height: auto;
}
.file_manager .file a{
    text-decoration: none;
}
.file_manager .file .file-name {
    padding: 10px;
    border-top: 1px solid #f7f7f7;
}
.file_manager .file .file-name small {
    display: block;
}
.file_manager .file a:hover .hover{
    display: none;
}
.file_manager .file .file-name small .date {
    float: right;
}
.file_manager .card {
    background: #fff;
    transition: .5s;
    border: 0;
    margin-bottom: 30px;
    border-radius: .55rem;
    position: relative;
    width: 100%;
    box-shadow: 0 1px 2px 0 rgb(0 0 0 / 10%);
}
.custom-toggle-btn .mat-button-toggle-checked {
    background-color: #173a64;
    color: #fff !important;
}
.custom-toggle-btn .mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
    padding: 0px 30px;
    line-height: 34px;
    width: 110px;
}
.toggle-top-position {
    position: absolute;
    top: -47px;
    right: 2px;
}